@import "../../../../theme/variables.scss";

.container {
  padding: 32px 32px 32px 24px;
  & > :first-child {
    margin-left: -24px;
  }
  .titleSection {
    padding-bottom: 14px;
    border-bottom: 1px solid $NEUTRAL-40;
    margin-bottom: 29px;
    margin-top: 6px;
    > p {
      color: $NEUTRAL-100;
      font-size: 32px;
      font-weight: 600;
      line-height: 40px; /* 125% */
    }
  }
  .profileSection {
    margin-bottom: 22px;
    & > :first-child {
      color: $NEUTRAL-80;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
    & > :last-child {
      color: $NEUTRAL-100;
      font-size: 20px;
      font-weight: 400;
      line-height: 26px; /* 130% */
    }
  }
  .fileSection {
    margin-bottom: 16px;
    .fileLabel {
      margin-bottom: 8px;
      > p {
        color: $NEUTRAL-80;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
    .fileWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .fileCard {
        border-radius: 12px;
        border: 1px solid $NEUTRAL-40;
        padding: 8px;
        display: flex;
        justify-content: space-between;
        .cardLeft {
          display: flex;
          align-items: center;
          gap: 16px;
          & > :first-child {
            width: 54px;
            height: 54px;
            display: grid;
            place-items: center;
            > img[type="excel"] {
              width: 34px;
              height: 32px;
            }
            > img[type="pdf"] {
              width: 26px;
              height: 32px;
            }
          }
          .fileName {
            display: flex;
            flex-direction: column;
            gap: 6px;
            & > :first-child {
              color: $NEUTRAL-90;
              font-size: 16px;
              font-weight: 400;
              line-height: 20px; /* 125% */
            }
            & > :last-child {
              color: $NEUTRAL-70;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%; /* 19.6px */
            }
          }
        }
        .cardRight {
          padding: 12px;
          color: $NEW-PRIMARY2-BASE;
          display: grid;
          place-items: center;
          & > :first-child {
            cursor: pointer;
          }
        }
      }
      .noFile {
        display: flex;
        flex-direction: column;
        gap: 11px;
        align-items: center;
        > img {
          width: 119px;
          height: 119px;
        }
        > p {
          color: $NEUTRAL-50;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px; /* 114.286% */
        }
      }
    }
  }
  .feedbackSection {
    margin-bottom: 16px;
  }
  .actionSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .score {
      display: flex;
      align-items: center;
      gap: 4px;
      & > :first-child {
        color: $NEUTRAL-80;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px; /* 114.286% */
      }
      & > :last-child {
        width: 72px;
      }
    }
  }
}
