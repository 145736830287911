@import "../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  // overflow: clip;

  .box {
    border: 1px solid $NEUTRAL-40;
    border-radius: 8px;
    overflow: clip;


    .valuesWrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 4px;
      gap: 4px;
    }

    >input {
      border: none;
      padding: 12px;
      font-family: $BASE-FONT-FAMILY;
      background-color: $NEUTRAL-10;
      width: 100%;
      outline: none;
      transition: all 0.3s;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      &:focus {
        outline: none;
      }

      &::placeholder {
        font-family: $BASE-FONT-FAMILY;
        // font-size: 13px;
        // font-style: normal;
        // font-weight: 400;
        // line-height: 20px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color: $NEUTRAL-50;
        line-height: 20px;
      }
    }

  }


  >p {
    font-family: $BASE-FONT-FAMILY;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: $NEUTRAL-60;
  }
}