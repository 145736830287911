@import "../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  text-align: left;
  @include gap(32, 24, 24px);

  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-bottom: 1px solid $NEUTRAL-40;
    @include padding-bottom(32, 24, 24px);

    > h3 {
      font-family: $BASE-FONT-FAMILY;
      @include font-size(24, 20, 20px);
      font-style: normal;
      font-weight: 600;
    }

    > span {
      font-family: $BASE-FONT-FAMILY;
      @include font-size(16, 14, 14px);
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      color: $NEUTRAL-70;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    .section {
      @include padding-block(32, 24, 24px);
      display: flex;
      flex-direction: column;
      gap: 20px;

      &:not(:last-child) {
        border-bottom: 1px solid $NEUTRAL-40;
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }

      > h4 {
        font-family: $BASE-FONT-FAMILY;
        @include font-size(16, 14, 14px);
        font-style: normal;
        font-weight: 600;
        line-height: 125%;
      }

      .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
      }

      .aspectCompetency {
        display: flex;
        flex-direction: column;
        > p {
          color: var(--Neutral-80, #616161);
          font-size: 14px;
          font-weight: 500;
          line-height: 16px; /* 114.286% */
          margin-bottom: 8px;
        }
        .competencyWrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;
          .competency {
            > :first-child {
              color: #000;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px; /* 150% */
              margin-bottom: 6px;
              margin-left: 8px;
            }
            > :last-child {
              color: #323232;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
              margin-left: 24px;
            }
          }
        }
      }

      .thumbnail {
        display: flex;
        flex-direction: column;
        @include gap(20, 16, 16px);
      }

      .generalInfo {
        display: flex;
        flex-direction: column;
        @include gap(28, 24, 24px);
      }

      .sessions,
      .schedules {
        display: flex;
        flex-direction: column;
        gap: 8px;
        // @include gap(28, 24, 24px);
      }

      .price {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .priceRow {
          display: flex;
          align-items: center;
          gap: 16px;

          > span {
            flex-grow: 1;
            font-family: $BASE-FONT-FAMILY;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: $NEUTRAL-60;
          }

          > p {
            font-family: $BASE-FONT-FAMILY;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;

            &.total {
              font-size: 24px;
            }
          }
        }

        .divider {
          height: 1px;
          background-color: $NEUTRAL-100;
        }
      }
    }
  }
}
