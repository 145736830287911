@import "../../../../theme/variables.scss";
.main{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.wrapperVoucher {
  font-family: $BASE-FONT-FAMILY;
  padding: 24px;
  .wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    .doodleCard {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      h2 {
        color: $NEUTRAL-10;
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        .span {
          font-weight: 600;
        }
      }
      p {
        color: $NEUTRAL-10;
        font-size: 14px;
        line-height: 20px;
        .span {
          font-style: italic;
        }
      }
    }
    .searchBar {
      width: 280px;
    }
    .wrapperTable {
      .tableRadius {
        border-radius: 12px;
        border: 1px solid $NEUTRAL-30;
        .wrapperExpand {
          display: flex;
          align-items: center;
          gap: 1rem;
          justify-content: space-between;

          .listTableMobile {
            display: flex;
            flex-direction: column;
            row-gap: 0.3rem;
            .totalVoucherM,
            .priceM {
              display: flex;
              gap: 1rem;
              align-items: center;
              h2 {
                font-size: 14px;
                color: $NEUTRAL-80;
                font-weight: normal;
                width: 100px;
              }
              p {
                font-weight: 400;
                color: $NEUTRAL-100;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
.buttonGenerate {
  background: $COLOR-GRADIENT-2;
  color: $NEUTRAL-10;
  padding: 8px 1rem;
  width: max-content;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background: $NEW-PRIMARY2-BASE;
  }
}
.isErorrModal {
  width: 414px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 24px;
  border-radius: 8px;
  background-color: $NEUTRAL-10;
  h2 {
    color: $NEUTRAL-90;
    font-size: 16px;
    font-weight: 600;
  }
  > div {
    display: flex;
    width: 100%;
    justify-content: center;
    img {
      width: 144px;
      height: 140px;
    }
  }
  p {
    color: $NEUTRAL-100;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
  }
  .buttonModal {
    display: flex;
    gap: 12px;
    width: 100%;
    div:nth-child(1) {
      cursor: pointer;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      border: 2px solid transparent;
      border-radius: 8px;
      background: linear-gradient($NEUTRAL-10, $NEUTRAL-10) padding-box,
        linear-gradient(to right, #9360a8 35.02%, #46b7e9 125.87%) border-box;
      &:hover {
        background: linear-gradient($NEUTRAL-10, $NEUTRAL-10) padding-box,
          linear-gradient(to right, #9360a8 35.02%, #9360a8 125.87%) border-box;
        span {
          background: $NEW-PRIMARY2-BASE;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      span {
        font-family: $BASE-FONT-FAMILY;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        background: $COLOR-GRADIENT-2;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    div:nth-child(2) {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      padding: 8px 16px;
      border-radius: 8px;
      background: linear-gradient(137deg, #9360a8 35.02%, #46b7e9 125.87%);
      span {
        color: $NEUTRAL-10;
        font-weight: 600;
      }
      &:hover {
        background: $NEW-PRIMARY2-BASE;
      }
    }
  }
}
@media (max-width: 768px) {
  .buttonGenerate {
    padding: 6px 12px;
  }
  .isErorrModal {
    width: 300px;
    h2 {
      font-size: 16px;
    }
    > div {
      img {
        width: 108px;
        height: 104px;
      }
    }
    p {
      font-size: 12px;
    }
    .buttonModal {
      div:nth-child(1),
      div:nth-child(2) {
        padding: 6px 12px !important;
      }
    }
  }
}
