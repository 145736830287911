.container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: flex-start;

  .labelText {
    color: #616161;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
  }

  .required {
    color: red;
  }

  .inputText {
    padding: 12px;
    font-size: 1rem;
    display: flex;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    width: 100%;
    min-height: 50px;
    resize: vertical;

    &::placeholder {
      color: #c2c2c2;
      font-size: 1rem;
      line-height: 20px;
    }

    &:focus {
      outline: 1px solid #9360a8;
      border-color: #9360a8;
    }
  }
}