@import "../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid $NEUTRAL-40;
  border-radius: 8px;

  .header {
    display: flex;
    align-items: flex-start;
    gap: 7px;
    .headerTextWrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      > :first-child {
        display: flex;
        gap: 7px;

        > :first-child {
          color: $NEW-PRIMARY2-BASE;
        }
      }
      .title {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        > span {
          font-family: $BASE-FONT-FAMILY;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 133.333%;
        }

        .titleText {
          font-family: $BASE-FONT-FAMILY;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }

        .extra {
          color: #cd2020;
        }
      }
      .address {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-block: 4px;
        > :last-child {
          color: var(--Neutral-100, #232323);
          cursor: pointer;
          /* Text-S/Medium */
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 133.333% */
          text-decoration-line: underline;
          text-decoration-style: solid;
          text-decoration-skip-ink: auto;
          text-decoration-thickness: auto;
          text-underline-offset: auto;
          text-underline-position: from-font;
        }
      }
    }

    > *:first-child {
      flex: 1;
    }
  }

  .content {
    padding-top: 12px;
    padding-left: 23px;

    > div {
      font-family: $BASE-FONT-FAMILY;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 142.857%;
    }
  }
}
