@import "../../../theme/variables.scss";

.quizCard {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px;
  border: 1px solid $NEUTRAL-40;
  border-radius: 8px;

  .quizLogo {
    display: grid;
    place-items: center;
    height: 40px;
    aspect-ratio: 1;
    background-color: $NEW-PRIMARY2-BASE;
    border-radius: 4px;
    color: $NEUTRAL-10;
  }

  >span {
    font-family: $BASE-FONT-FAMILY;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    flex-grow: 1;
  }
}