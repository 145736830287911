@import "../../../theme/variables.scss";
.containerCard {
  height: 327px;
  width: 100%;
  background: $COLOR-GRADIENT-2;
  position: relative;
  overflow: hidden;
  box-shadow: 0 -10px 10px #c1c1c1cd;
  .leftClay {
    position: absolute;
    width: 794.416px;
    height: 794.416px;
    transform: rotate(30deg);
    left: -250px;
    filter: blur(4px);
  }
  .rightClay {
    position: absolute;
    width: 794.416px;
    height: 794.416px;
    transform: rotate(30deg);
    right: -400px;
    filter: blur(4px);
    top: -350px;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
    width: 100%;
    height: 100%;
    position: relative;
    h1 {
      color: $NEUTRAL-10;
      font-size: 40px;
      font-weight: 600;
      line-height: 50px;
      text-align: center;
    }
    .partnerPrakerja {
      display: flex;
      gap: 1rem;
      > div {
        padding: 12px 16px;
        background-color: $NEUTRAL-10;
        border-radius: 8px;
        display: flex;
        align-items: center;
      }
    }
  }
}
@media (max-width: 768px) {
  .containerCard {
    .leftClay,
    .rightClay {
      width: 500px;
      height: 500px;
    }
    .rightClay {
      right: -200px;
      top: -300px;
    }
    .wrapper {
      padding-inline: 1rem;
      h1 {
        font-size: 20px !important;
        line-height: 1.5rem;
        text-align: center;
      }
      .partnerPrakerja {
        gap: 12px;
        padding-inline: 1rem;
        > div {
          padding: 8px !important;
          justify-content: center;
          img {
            width: 80%;
          }
        }
      }
    }
  }
}
.separator {
  padding-bottom: 70px;
}
