@import "../../../theme/variables.scss";

.container {
  display: flex;
  // height: calc(100vh - 78px);
  // overflow: clip;
  height: 100%;

  .side {
    background-color: $NEUTRAL-10;
    width: 280px;
    box-shadow: 0px 6px 24px 0px rgba(157, 160, 186, 0.25);
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    .wrapper{
      position: fixed;
      top: 78px;
      max-height: 100vh;
      overflow: auto;
    }

    .section {
      padding: 24px 32px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      > span {
        font-family: $BASE-FONT-FAMILY;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 114.286%;
        color: $NEUTRAL-80;
      }

      .menus {
        display: flex;
        flex-direction: column;
        gap: 8px;

        > button {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 12px;
          padding: 10px 12px;
          border-radius: 8px;
          color: $NEUTRAL-70;
          transition: all 0.3s ease;

          > span {
            font-family: $BASE-FONT-FAMILY;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 114.286%;
            white-space: nowrap;
          }

          &.active {
            background-color: $NEUTRAL-20;
            color: $NEW-PRIMARY2-BASE;
          }

          &.out {
            color: $DANGER-50;
          }
          &.disabled {
            color: $NEUTRAL-50;
          }
        }
      }
    }

    .separator {
      height: 1px;
      background-color: $NEUTRAL-40;
    }
  }
  .hideDesktop {
    display: none;
  }

  .content {
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .sectionLanding {
    display: flex;
    flex-direction: column;
    padding-inline: 16px;
    .navbarLink {
      > li {
        border-bottom: 1px solid #e0e0e0;
        padding-block: 16px;
        display: block;
        color: #000;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .showButton {
      position: fixed;
      top: 0;
      z-index: 3;
      display: grid;
      place-items: center;
      top: 27px;
      left: 20px;
    }

    .hideDesktop {
      display: block;
    }

    .side {
      position: fixed;
      top: 0;
      left: -1000px;
      height: 100vh;
      z-index: 3;
      transition: all 0.3s;

      &.show {
        left: 0;
      }

      .hideButton {
        display: flex;
        justify-content: space-between;
        padding-inline: 24px;
        height: 78px;

        .logo {
          display: flex;
          align-items: center;
          @include gap(12, 9, 9px);
          cursor: pointer;

          > img {
            @include fluid-image-size(40, 30, 30px);
            object-fit: contain;
          }

          > span {
            font-family: $BASE-FONT-FAMILY;
            @include font-size(24, 18, 18px);
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: $NEW-PRIMARY1-BASE;
          }
        }

        > button {
          display: grid;
          place-items: center;
        }
      }
    }
  }
}
