@import "../../../theme/variables.scss";

.editorWrapper {
  max-height: 100%;
  height: 100%;
  background-color: $NEUTRAL_10;
  z-index: 0;
  width: 100%;

  .toolbar {
    // background-color: $NEUTRAL_20;
    border-radius: 12px 12px 0 0;
    z-index: 2;
    border: 1px solid #ededed;
    // width: 100%;
    margin-bottom: 0px;

    &.sticky {
      position: sticky;
      top: 0;

    }
  }

  .toolbarIcon {
    // width: 200px;
    border: none;
  }

  .toolbarIcon[aria-selected="true"] {
    box-shadow: none;
    background: $NEUTRAL-30;
  }

  .editor {
    padding: 16px;
    background-color: $NEUTRAL_10;
    // min-height: 540px;
    width: 100%;
    border: 1px solid $NEUTRAL-40;
    border-radius: 0 0 12px 12px;
    border-top: 0;
  }
}