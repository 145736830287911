@import "../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  >h5 {
    font-family: $BASE-FONT-FAMILY;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 114.286%;
    color: $NEUTRAL-80;
  }

  p {
    font-family: $BASE-FONT-FAMILY;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  >div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}