.container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 8px;
  .option {
    flex-grow: 1;
  }
  textArea {
    padding: 4px 12px 4px 6px;
    resize: none;
    width: 100%;
    height: auto;
    align-self: center;
    font-size: 14px;
    color: #404040;
    border: none;
    overflow-y: hidden;
    text-align: justify;
    line-height: 16px;
    outline-color: transparent;
    &:focus {
      outline-color: transparent;
      height: auto;
      border-bottom: 2px solid #9360a8;
    }
    &::placeholder {
      color: #c2c2c2;
    }
  }
}
