
.container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.layout {
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.doodle {
  width: 100%;
  position: relative;
  .topCard {
    // padding: 24px 24px 16px 24px;
    &::after {
      content: "";
      background-image: url("../../../../assets/Images/clayCardDoodle.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 227px;
      height: 227px;
      flex-shrink: 0;
      position: absolute;
      right: 0;
      top: 80px;
    }
    .leftSection {
      color: white;
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      position: relative;
      .title {
        color: var(--Neutral-10, #fff);
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
      }
      .desc {
        color: var(--Neutral-10, #fff);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .rightSection {
      img {
        position: absolute;
        right: 0;
        top: -16px;
      }
    }
  }
}
.tabsHeader {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  .wrapperCard {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    .card {
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      height: auto;
      width: 307px;
      height: auto;
      border: 1px solid #ededed;
      .images {
        width: 306px;
        height: 250px;
        object-fit: cover;
        flex-shrink: 0;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }
      .describeClass {
        padding: 0;
        display: flex;
        width: 100%;
        height: auto;
        .percentage {
          display: flex;
          flex-direction: column;
          width: 91px;
          height: 150px;
          border-radius: 0px 0px 0px 8px;
          background: var(--New-Primary-2-Primary-Base, #9360a8);
          align-items: center;
          justify-content: center;
          padding: 8px;
          row-gap: 8px;
          .indicator {
            width: 53px;
            height: 53px;
            flex-shrink: 0;
            position: relative;
            &::before {
              content: attr(percentage) "%";
              width: 33px;
              height: 33px;
              top: 50%;
              left: 53%;
              transform: translate(-50%, -50%);
              color: white;
              position: absolute;
              font-size: 12px;
              display: flex;
              align-items: center;
              font-weight: 600;
            }
          }
          .indicator[isLocked="true"] {
            &::before {
              left: 65%;
            }
          }
          div:nth-child(2) {
            display: flex;
            flex-direction: column;
            align-items: center;
            // background-color: salmon;
            justify-content: center;
            text-align: center;
            p {
              color: #fff;
              font-size: 10px;
              font-weight: 400;
              line-height: 12px;
              width: 75px;
            }
          }
        }

        .classDescription {
          padding: 8px 12px 12px 12px;
          display: flex;
          row-gap: 1rem;
          flex-direction: column;
          background-color: rgb(255, 255, 255);
          border-radius: 0px 0px 8px 0px;
          background: var(--Neutral-10, #fff);
          width: 100%;
          .text {
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            .typeClass {
              display: flex;
              gap: 4px;
              align-items: center;
              div {
                display: flex;
                border-radius: 4px;
                padding: 4px 8px;
                align-items: center;
                justify-content: center;
                background: linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%);
                span {
                  color: var(--Neutral-10, #fff);
                  font-size: 10px;
                  font-weight: 400;
                  line-height: 12px;
                }
              }
            }
            .textEllipsis {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; /* Jumlah baris yang ingin Anda tampilkan */
              overflow: hidden;
              color: #000;
              font-size: 14px;
              font-weight: 500;
              line-height: 16px;
            }
            > p {
              color: var(--Neutral-60, #9e9e9e);
              font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px; /* 116.667% */
            }
          }
          .buttonCard {
            cursor: pointer;
            display: flex;
            padding: 8px 16px;
            border-radius: 8px;
            align-items: center;
            justify-content: center;
            color: white;
            background: var(
              --New-Primary-Gradient-Color-Gradient-2,
              linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%)
            );
            transition: 0.7s;
            span {
              color: #fff;
              font-size: 14px;
              font-weight: 500;
              line-height: 16px;
            }
            &:hover {
              background: var(
                --New-Primary-Gradient-Color-Gradient-2,
                linear-gradient(#9360a8, #9360a8)
              );
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .doodle {
    .topCard {
      &::after {
        display: none;
      }
      .leftSection {
        .title {
          color: var(--Neutral-10, #fff);
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
        .desc {
          color: var(--Neutral-10, #fff);
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
      }
      .rightSection {
        display: none;
      }
    }
  }
}
// MODAL
.modalContainer {
  section {
    width: auto;
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 26px 24px;
    .headerModal {
      display: flex;
      justify-content: space-between;
      h1 {
        color: var(--Neutral-90, #404040);
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }
      .closeIcon {
        cursor: pointer;
      }
    }
    .main {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      width: 366px;
      p {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        span {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }
      }
      .cardRating {
        padding: 12px;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        border-radius: 12px;
        background: #fff;
        box-shadow: 0px 6px 24px 0px rgba(157, 179, 222, 0.25);
        .rating {
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          h1 {
            color: var(--light-paragraph, #333);
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 125% */
          }
          .star {
            display: flex;
            justify-content: space-between;
            .iconStars {
              cursor: pointer;
            }
          }
        }
        .expression {
          display: flex;
          justify-content: space-between;
          color: #bababa;
          text-align: center;
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          span {
            width: 45px;
          }
        }
        .feedback {
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          h1 {
            color: var(--light-paragraph, #333);
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 125% */
          }
          textarea {
            height: 116px;
            resize: none;
            border-color: #e0e0e0;
            border-radius: 8px;
            padding: 12px;
            &:focus {
              outline-color: #9360a8;
            }
            &::placeholder {
              color: #c2c2c2;
            }
          }
        }
      }

      .review {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        .buttonModal {
          display: flex;
          gap: 12px;
          width: 100%;
          div:nth-child(1) {
            cursor: pointer;
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 16px;
            border: 2px solid transparent;
            border-radius: 8px;
            background: linear-gradient(white, white) padding-box,
              linear-gradient(to right, #9360a8 35.02%, #46b7e9 125.87%)
                border-box;
            span {
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
              background: var(
                --New-Primary-Gradient-Color-Gradient-2,
                linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%)
              );
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
          div:nth-child(2) {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            padding: 8px 16px;
            border-radius: 8px;
            background: var(
              --New-Primary-Gradient-Color-1,
              linear-gradient(137deg, #9360a8 35.02%, #46b7e9 125.87%)
            );
            span {
              color: white;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .modalContainer {
    section {
      padding: 1rem;
      main {
        width: 260px !important;
      }
    }
  }
}
// MODAL FEEDBACK
.feedbackModal {
  padding: 24px;
  border-radius: 12px;
  background-color: white;
  width: 374px;
  display: flex;
  row-gap: 1rem;
  flex-direction: column;
  .wrapperImage {
    display: flex;
    justify-content: center;
    img {
      width: 178px;
      height: 178px;
      flex-shrink: 0;
    }
  }
  .desc {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    h1 {
      color: var(--Neutral-90, #404040);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 125% */
    }
    p {
      color: var(--light-paragraph, #333);
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px; /* 141.667% */
      letter-spacing: 0.24px;
    }
  }
  .buttonModal {
    display: flex;
    gap: 12px;
    width: 100%;
    div:nth-child(1) {
      cursor: pointer;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      border: 2px solid transparent;
      border-radius: 8px;
      background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #9360a8 35.02%, #46b7e9 125.87%) border-box;
      span {
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        background: var(
          --New-Primary-Gradient-Color-Gradient-2,
          linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    div:nth-child(2) {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      padding: 8px 16px;
      border-radius: 8px;
      background: var(
        --New-Primary-Gradient-Color-1,
        linear-gradient(137deg, #9360a8 35.02%, #46b7e9 125.87%)
      );
      span {
        color: white;
        font-weight: 600;
      }
    }
  }
}
