.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #ffff;
  border-radius: 12px;
  padding: 24px;
  > :first-child {
    color: var(--Neutral-90, #404040);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
  }
  .modalAction {
    width: 100%;
    > button {
      width: 100%;
    }
  }
  .modalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    > :first-child {
      width: 106px;

    }
    > :last-child {
      color: var(--Neutral-90, #404040);
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      white-space: pre-line;
    }
  }
}
