@import "../../../theme/variables.scss";

.container {
  display: flex;
  align-items: center;
  gap: 4px;


  button {
    display: grid;
    place-items: center;
  }

  input {
    flex-grow: 1;

    appearance: none;
    -webkit-appearance: none;
    height: 4px;
    background: $NEUTRAL-40;
    border-radius: 5px;
    background-image: linear-gradient($NEW-PRIMARY2-BASE, $NEW-PRIMARY2-BASE);
    background-repeat: no-repeat;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background: $NEW-PRIMARY2-BASE;
      cursor: pointer;
      box-shadow: 0 0 2px 0 #555;
      transition: background 0.3s ease-in-out; 

      &:hover {
        box-shadow: #d46a6a50 0px 0px 0px 8px;
      }

      &:active {
        box-shadow: #d46a6a50 0px 0px 0px 11px;
        transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    }

    &::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }
  }
}