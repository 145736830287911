@import "../../../theme/variables.scss";

.container {
  // background: url('../../../assets/Images/doodle-bg.png'), no-repeat;
  background: $COLOR-GRADIENT-2 no-repeat;
  width: 100%;
  border-radius: 8px;
  @include padding(24, 16, 16px);
  position: relative;
  overflow: clip;
  width: 100%;
  background-position: center top;


  .bg {
    position: absolute;
    top: -170px;
    left: -140px;
    z-index: -1;
  }

}