@keyframes drible {
  0%,
  100% {
    transform: translateY(1%);
  }

  50% {
    transform: translateY(6%);
  }
}
.wrapper {
  width: 100%;
  .relative {
    .playButtonClick {
      width: 100px !important;
      height: 120px !important;
      position: absolute !important;
      top: 260px;
      right: 335px;
      cursor: pointer;
      animation: drible 1s infinite alternate-reverse;
      &:hover {
        animation: none;
      }
    }
    img {
      position: absolute;
      width: 519px;
      height: 573px;
      object-fit: contain;
      right: 0;
      top: 135px;
    }
  }
}
@media (max-width: 1060px) {
  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    .relative {
      .playButtonClick {
        right: 250px;
        top: 360px;
      }
      img {
        position: absolute !important;
        width: 405px;
        height: 425px;
        top: 280px;
      }
    }
  }
}

@media (max-width: 900px) {
  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    padding-top: 20px;
    .relative {
      .playButtonClick {
        right: 250px;
        top: 380px;
      }
      img {
        position: absolute !important;
        width: 405px !important;
        height: 425px !important;
        top: 300px;
      }
    }
  }
}
@media (max-width: 880px) {
  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    padding-top: 20px;
    .relative {
      .playButtonClick {
        right: 170px;
        top: 380px !important;
      }
      img {
        position: absolute !important;
        width: 305px !important;
        height: 325px !important;
        top: 330px !important;
      }
    }
  }
}
@media (max-width: 768px) {
  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    padding-top: 20px;
    .relative {
      .playButtonClick {
        right: 145px;
        top: 160px !important;
        width: 80px !important;
        height: 100px !important;
      }
      img {
        position: relative !important;
        width: 205px !important;
        height: 230px !important;
        top: 0 !important;
        right: 20px;
      }
    }
  }
}

@media (max-width: 460px) {
  .wrapper {
    .relative {
      .playButtonClick {
        right: 175px;
      }
    }
  }
}
@media (max-width: 460px) {
  .wrapper {
    .relative {
      img {
        right: 50px;
      }
    }
  }
}
