@import "../../../../theme/variables.scss";

.container {
  margin-top: 32px;
  display: flex;
  gap: 68px;
  .leftSection {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 330px;
    > img {
      position: absolute;
      height: 330px;
      bottom: 5px;
    }
  }
  .rightSection {
    margin-top: 41px;
    .head {
      & > :first-child {
        color: $NEW-PRIMARY2-BASE;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
      }
      & > :last-child {
        color: $NEUTRAL-90;
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
      }
    }
    .bio {
      margin-top: 16px;
      white-space: pre-line;
      > p {
        color: $NEUTRAL-90;

        font-size: 14px;

        font-weight: 400;
        line-height: 24px; /* 171.429% */
      }
    }
  }
}
