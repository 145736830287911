@import "../../../theme/variables.scss";



.container {
  width: 100vw;
  height: 100vh;
  overflow: clip;
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;

  .header {
    @include padding(32, 16, 16px);
    position: fixed;
    width: 100%;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.80);
    > button {
      display: flex;
      align-items: center;
      gap: 6px;
      color: $NEUTRAL-10;

      > span {
        font-family: $BASE-FONT-FAMILY;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 125% */
      }
    }
  }

  .content {
    height: 100px;
    overflow-y: auto;
    overflow-x: clip;
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-top: 100px;
    padding-bottom: 20px;
    .question {
      width: 596px;
      max-width: 90vw;
    }
  }
}
