@import "../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  text-align: left;
  @include gap(32, 24, 24px);

  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-bottom: 1px solid $NEUTRAL-40;
    @include padding-bottom(32, 24, 24px);

    >h3 {
      font-family: $BASE-FONT-FAMILY;
      @include font-size(24, 20, 20px);
      font-style: normal;
      font-weight: 600;
    }

    >span {
      font-family: $BASE-FONT-FAMILY;
      @include font-size(16, 14, 14px);
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      color: $NEUTRAL-70;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
  }
}