@import "../../../../theme/variables.scss";
.main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.layout {
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  flex: 1;
}

.countdownContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 4px 8px;
  border-radius: 32px;
  background: var(--Success-Success-10, #f0fdd2);
  margin-bottom: 8px;
  > p {
    color: var(--Success-Success-90, #275f06);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
}

.doodle {
  width: 100%;
  position: relative;
  .topCard {
    &::after {
      content: "";
      background-image: url("../../../../assets/Images/clayCardDoodle.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 227px;
      height: 227px;
      flex-shrink: 0;
      position: absolute;
      right: 0;
      top: 80px;
    }
    .leftSection {
      color: $NEUTRAL-10;
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      position: relative;
      .title {
        color: $NEUTRAL-10;
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
      }
      .desc {
        color: $NEUTRAL-10;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .rightSection {
      img {
        position: absolute;
        right: 0;
        top: -16px;
      }
    }
  }
}
.tabsHeader {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding-bottom: 10px;
  .tabsWrapper {
    overflow: auto;
  }
  .wrapperCard {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    .card {
      .invoice {
        padding: 8px 12px 12px 12px;
        p {
          color: $NEUTRAL-70;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
      }
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      height: auto;
      min-width: 307px;
      height: auto;
      border: 1px solid $NEUTRAL-30;
      flex: 1;
      // width:  calc(100% / 3);
      max-width: calc(100% / 3);
      .images {
        width: 100%;
        height: 250px;
        object-fit: cover;
        flex-shrink: 0;
      }
      .describeClass {
        padding: 0;
        display: flex;
        width: 100%;
        height: auto;
        .percentage {
          display: flex;
          flex-direction: column;
          width: 91px;
          height: 150px;
          border-radius: 0px 0px 0px 8px;
          background: $NEW-PRIMARY2-BASE;
          align-items: center;
          justify-content: center;
          padding: 8px;
          row-gap: 8px;
          .indicator {
            width: 53px;
            height: 53px;
            flex-shrink: 0;
            position: relative;
            &::before {
              content: attr(percentage) "%";
              width: 33px;
              height: 33px;
              top: 50%;
              left: 53%;
              transform: translate(-50%, -50%);
              color: $NEUTRAL-10;
              position: absolute;
              font-size: 12px;
              display: flex;
              align-items: center;
              font-weight: 600;
            }
          }
          .indicator[isLocked="true"] {
            &::before {
              left: 65%;
            }
          }
          div:nth-child(2) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            p {
              color: $NEUTRAL-10;
              font-size: 10px;
              font-weight: 400;
              line-height: 12px;
              width: 75px;
            }
          }
        }

        .classDescription {
          padding: 8px 12px 12px 12px;
          display: flex;
          row-gap: 1rem;
          flex-direction: column;
          border-radius: 0px 0px 8px 0px;
          background: $NEUTRAL-10;
          width: 100%;
          .text {
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            .typeClass {
              display: flex;
              gap: 4px;
              align-items: center;
              div:nth-child(1) {
                display: flex;
                border-radius: 4px;
                padding: 4px 8px;
                background-color: $NEW-PRIMARY1-BASE;
                align-items: center;
                justify-content: center;
                span {
                  color: $NEUTRAL-10;
                  font-size: 10px;
                  font-weight: 400;
                  line-height: 12px;
                }
              }
              div:nth-child(2) {
                display: flex;
                border-radius: 4px;
                padding: 4px 8px;
                background-color: $SUCCESS-30;
                align-items: center;
                justify-content: center;
                span {
                  color: var(--Neutral-100, #232323);

                  font-size: 10px;

                  font-weight: 400;
                  line-height: 12px;
                }
              }
            }
            .textEllipsis {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              box-orient: vertical;
              -webkit-line-clamp: 2; /* Jumlah baris yang ingin Anda tampilkan */
              line-clamp: 2;
              overflow: hidden;
              color: #000;
              font-size: 14px;
              font-weight: 500;
              line-height: 16px;
            }
            .price {
              color: $NEUTRAL-60;
              font-size: 12px;
              font-weight: 400;
              line-height: 14px;
            }
            .paidDate {
              color: $NEUTRAL-90;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
            }
          }
          .buttonCard {
            cursor: pointer;
            display: flex;
            padding: 8px 16px;
            border-radius: 8px;
            align-items: center;
            justify-content: center;
            color: $NEUTRAL-10;
            background-color: $SUCCESS-60;
            span {
              color: $NEUTRAL-10;
              font-size: 14px;
              font-weight: 500;
              line-height: 16px;
            }
          }
          .buttonCard[isPending="true"] {
            cursor: progress;
          }
          .buttonCard[tabs="2"] {
            background-color: #e09f3e;
          }
          .buttonCard[tabs="3"] {
            background-color: $DANGER-60;
          }
        }
      }
      .cardAction {
        padding-inline: 12px;
        padding-bottom: 12px;
        width: 100%;
        display: flex;
        flex: 1;
        align-items: flex-end;
        > button {
          height: max-content;
          width: 100%;
        }
      }
    }
  }
}
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  & > :first-child {
    width: 200px;
    height: 200px;
  }
  & > :last-child {
    color: var(--New-Primary-2-Primary-Base, #9360a8);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 100% */
  }
}
// MODAL
.containerPayment {
  padding: 32px;
  background-color: $NEUTRAL-10;
  border-radius: 8px;
  position: relative;

  .relative {
    .invoice {
      display: flex;
      gap: 8px;
      align-items: center;
      padding-bottom: 32px;
      h1 {
        color: $NEW-PRIMARY2-BASE;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
      .date {
        color: #232323;
        width: 159px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
      .time {
        color: $DANGER-60;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        width: 80px;
      }
      .iconClose {
        cursor: pointer;
      }
    }
    .detailPayment {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      h1 {
        color: #232323;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
      }
      .titleClass {
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          width: 240px;
          color: $NEUTRAL-90;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
        p {
          font-size: 20px;
          font-weight: 400;
        }
      }
      .discount {
        display: flex;
        justify-content: space-between;
        p {
          color: $NEUTRAL-90;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          span {
            color: $SUCCESS-60;
          }
        }
        p:nth-child(2) {
          color: $DANGER-60;
          font-size: 20px;
          font-weight: 400;
          line-height: 26px;
        }
      }
      .total {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        div {
          display: flex;
          gap: 43px;
          h1 {
            color: $NEUTRAL-90;
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
          }
        }
      }
    }
    .lineSeperator {
      width: 100%;
      margin-block: 24px;
      border-bottom: 1px dashed transparent;
      &::after {
        content: "";
        width: 100%;
        position: absolute;
        border-bottom: 1px dashed $NEUTRAL-40;
        left: 0;
      }
    }
    .paymentMethode {
      div {
        color: $NEUTRAL-70;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        span {
          color: #232323;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }
      .typeMethod {
        display: flex;
        gap: 8px;
        align-items: center;
        width: 100%;
        border-radius: 8px;
        border: 1px solid #039;
        padding: 8px 12px;
        margin-top: 8px;
        img {
          width: 62px;
          height: 20px;
          flex-shrink: 0;
        }
        div {
          display: flex;
          justify-content: space-between;
          width: 100%;
          span {
            color: #003399;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
        }
      }
    }
    .upload {
      margin-top: 27px;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      h1 {
        color: #232323;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
      .fileUpload {
        img {
          width: 48px;
          height: 48px;
          flex-shrink: 0;
        }
        display: flex;
        width: 100%;
        padding: 56px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        flex-shrink: 0;
        border-radius: 10px;
        border: 1px dashed $NEUTRAL-50;
        h1 {
          color: $NEW-PRIMARY2-BASE;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
        .button {
          cursor: pointer;
          display: flex;
          height: 42px;
          padding: 8px 16px;
          justify-content: center;
          align-items: center;
          gap: 6px;
          flex-shrink: 0;
          border: 2px solid transparent;
          border-radius: 8px;
          background: linear-gradient($NEUTRAL-10, $NEUTRAL-10) padding-box,
            linear-gradient(to right, #795ec6, #8cc5de) border-box;
          label {
            cursor: pointer;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            background: $COLOR-GRADIENT-2;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      .fileUpload[isChoose="true"] {
        padding: 0;
        img[alt="payment-images"] {
          width: 100%;
          height: 194px;
          object-fit: cover;
          border-radius: 12px;
        }
      }
    }
    .buttonCard {
      margin-top: 12px;
      cursor: pointer;
      display: flex;
      padding: 8px 16px;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      color: $NEUTRAL-10;
      background: $COLOR-GRADIENT-2;
      transition: 0.7s;
      span {
        color: $NEUTRAL-10;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
      }
      &:hover {
        background: linear-gradient(#9360a8, #9360a8);
      }
    }
  }
}
@media (max-width: 768px) {
  .wrapperCard {
    justify-content: center;
  }
  .containerPayment {
    padding: 1.5rem 1rem;
    .relative {
      .invoice {
        display: grid;
        grid-template-columns: auto auto auto;
        row-gap: 0;
        padding-bottom: 12px;
        justify-content: space-between;
        h1 {
          font-size: 14px;
          order: 1;
        }
        .date {
          order: 4;
          font-size: 12px;
        }
        span {
          order: 5;
          display: none;
        }
        .time {
          order: 2;
        }
        .iconClose {
          order: 3;
        }
      }
      .detailPayment {
        row-gap: 12px;

        h1 {
          font-size: 1rem;
        }
        .titleClass {
          div {
            font-size: 14px;
          }
          p {
            font-size: 14px;
          }
        }
        .discount {
          p:nth-child(1) {
            font-size: 14px;
          }
          p:nth-child(2) {
            font-size: 1rem;
          }
        }
        .total {
          div {
            h1 {
              font-size: 1rem;
            }
          }
        }
      }
      .lineSeperator {
        margin-block: 0.5rem;
      }
      .paymentMethode {
        div:nth-child(1) {
          font-size: 14px;
          span {
            font-size: 14px;
          }
        }
        .typeMethod {
          padding: 4px 8px;
          div {
            display: flex;
            flex-direction: column;
            text-align: start;
            span {
              font-size: 14px;
            }
          }
        }
      }
      .upload {
        h1 {
          font-size: 14px;
        }
        .fileUpload {
          text-align: center;
          padding: 1rem;
        }
      }
    }
  }
}
