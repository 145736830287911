@import "../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;

  >* {
    padding: 16px;
  }

  .rotate {
    transform: rotate(180deg);
  }

  .header {
    display: flex;
    align-items: center;
    gap: 16px;

    .left {
      flex-grow: 1;

      >span {
        font-family: $BASE-FONT-FAMILY;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
      }

      >h3 {
        font-family: $BASE-FONT-FAMILY;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%;
        color: $NEUTRAL-90
      }
    }
  }

  .content {
    padding-top: 4px;

    .subject {
      display: flex;
      align-items: center;
      gap: 12px;
      padding-block: 20px;
      border-bottom: 1px solid $NEUTRAL-40;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }

      .logo {
        width: 52px;
        aspect-ratio: 1;
        flex-shrink: 0;
        border-radius: 8px;
        display: grid;
        place-items: center;
        color: $NEUTRAL-10;
      }

      .description {
        display: flex;
        flex-direction: column;
        gap: 4px;

        >span {
          font-family: $BASE-FONT-FAMILY;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 114.286%;
          color: $NEUTRAL-80;
        }

        h4 {
          font-family: $BASE-FONT-FAMILY;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
        }
      }
    }
  }
}