@import "../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  text-align: left;
  @include gap(32, 24, 24px);

  .certificateContainer {
    height: 100%;
    width: 100%;
    .headWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      > :first-child {
        font-family: $BASE-FONT-FAMILY;
        @include font-size(16, 14, 14px);
        font-style: normal;
        font-weight: 600;
        line-height: 125%;
      }
      > :last-child {
        display: flex;
        align-items: center;
        gap: 5px;
        > :first-child {
          color: var(--Neutral-50, #c2c2c2);
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    .certificateImageContainer {
      position: relative;
      width: 100%;
      > img {

        width: 100%;
        object-fit: cover;
        object-position: left top;
      }
      .buttonWrapper {
        position: absolute;
        bottom: 0;
        width: 100%;
        border-radius: 0px 0px 6px 6px;
        background: rgba(66, 66, 66, 0.5);
        backdrop-filter: blur(5px);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        >button{
          width: 100%;
        }
      }
    }

    .certificateWrapper {
      > :first-child {
        color: $NEUTRAL-80;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 8px;
      }
    }
    .inputContainer {
      display: flex;
      padding: 17px 0px 18px 0px;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 12px;
      border: 1px dashed var(--New-Primary-Gradient-Color-Gradient-2, #795ec6);
      background: #fff;
      flex-direction: column;
      gap: 24px;
      > :first-child {
        color: var(--Neutral-100, #232323);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
      > img {
        width: 128px;
        height: 118px;
      }
    }
    > :last-child {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    > h3 {
      font-family: $BASE-FONT-FAMILY;
      @include font-size(24, 20, 20px);
      font-style: normal;
      font-weight: 600;
    }

    > span {
      font-family: $BASE-FONT-FAMILY;
      @include font-size(16, 14, 14px);
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      color: $NEUTRAL-70;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    .each {
      display: flex;
      flex-direction: column;
      @include padding-block(32, 24, 24px);
      @include gap(20, 16, 16px);
      border-top: 1px solid $NEUTRAL-40;

      &:last-child {
        border-bottom: 1px solid $NEUTRAL-40;
      }

      .eachHeader {
        display: flex;
        flex-direction: column;
        gap: 8px;

        > h4 {
          font-family: $BASE-FONT-FAMILY;
          @include font-size(16, 14, 14px);
          font-style: normal;
          font-weight: 600;
          line-height: 125%;
        }

        > span {
          font-family: $BASE-FONT-FAMILY;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: $NEUTRAL-70;
        }
      }

      .grid {
        display: grid;
        grid-auto-flow: column;
        gap: 16px;
      }

      .thumbnailsWrapper,
      .infoWrapper,
      .competencyWrapper,
      .targetWrapper,
      .configurationWrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .specificPurposeWrapper {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }

        .competencyUnitsWrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .cUnit {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 12px;
          }
        }

        .targetParticipantsWrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
      }
    }
  }
}

// @media screen and(max-width: 768px) {
//   .container {

//   }
// }
