@import "../../../theme/variables.scss";

.errorScheduleModalContainer {
  width: 100%;
  > :first-child {
    color: var(--light-paragraph, #333);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    margin-bottom: 4px;
  }
  .errorScheduleModalList {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: flex-start;
    align-items: flex-start;
    .errorModalList {
      color: var(--light-paragraph, #333);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      .batch {
        font-weight: 700;
      }
      .date {
        color: var(--Neutral-50, #c2c2c2);
      }
    }
  }
}

.container {
  @include padding-bottom(16, 12, 12px);
  @include padding-inline(32, 24, 24px);
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;

  .stepperWrapper {
    padding-top: 32px;
    overflow: auto;
    overflow-y: clip;
    max-width: 100%;
    // position: sticky;
    // top: 0;
    background-color: $NEUTRAL-10;
  }

  .contentWrapper {
    display: grid;
    grid-template-columns: 1fr 596px 1fr;

    > *:first-child {
      > * {
        position: sticky;
        top: 32px;
      }
    }

    .content {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 32px;

      .contentActions {
        display: flex;
        align-items: center;
        gap: 16px;
        justify-content: space-between;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding-bottom: 60px;

    .contentWrapper {
      grid-template-columns: 1fr;
      gap: 24px;

      > * {
        > button {
          padding-left: 0;
        }
      }
    }
  }
}
