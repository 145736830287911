@import "../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 78px);

  .breadcrumbsWrapper {
    display: flex;
    align-items: center;
    margin-left: 24px;
    margin-top: 20px;
    gap: 12px;
    .backIcon {
      display: flex;
      cursor: pointer;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
        0px 10px 32px -4px rgba(24, 39, 75, 0.1);
    }
    .breadcrumbs {
      display: flex;
      align-items: center;
      gap: 4px;
      > :first-child {
        color: $NEUTRAL-60;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
      > :last-child {
        color: $NEUTRAL-100;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
  }

  .wrapper {
    @include padding-block(24, 12, 12px);
    @include padding-inline(24, 16, 16px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .header {
    display: flex;
    align-items: center;

    .title {
      flex-grow: 1;
      color: $NEUTRAL-10;
      display: flex;
      flex-direction: column;
      gap: 8px;

      h3 {
        font-family: $BASE-FONT-FAMILY;
        @include font-size(24, 16, 16px);
        font-style: normal;
        font-weight: 400;
        line-height: 30px;

        > span {
          font-weight: 600;
        }
      }

      > span {
        font-family: $BASE-FONT-FAMILY;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 142.857%;
      }
    }

    .img {
      width: 93px;
      object-fit: contain;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    @include gap(24, 32, 32px);

    .each {
      display: flex;
      flex-direction: column;
      gap: 20px;

      > span {
        font-family: $BASE-FONT-FAMILY;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }

      .grid {
        display: grid;
        grid-auto-flow: column;
        gap: 20px;
      }

      .informationSection {
        display: flex;
        align-items: center;
        gap: 20px;

        .infoFields {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 14px;
        }
      }

      .bankAccountSection {
        display: flex;
        flex-direction: column;
        gap: 14px;
        @include padding-bottom(42, 24, 24px);
      }

      .submit {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.successModal {
  max-width: 497px;
  min-width: 300px;
  width: 70vw;
  padding: 24px;
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  background: #fff;
  .modalBody {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    > :first-child {
      color: $NEUTRAL-90;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px; /* 130% */
    }
    > :last-child {
      color: #333;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
    > img {
      width: 106.893px;
      height: 105.739px;
      flex-shrink: 0;
    }
  }
  .modalAction {
    > button {
      width: 100%;
    }
  }
}
.confirmModal {
  max-width: 497px;
  min-width: 300px;
  width: 70vw;
  padding: 24px;
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  background: #fff;
  .modalBody {
    display: flex;
    flex-direction: column;
    gap: 12px;
    > :first-child {
      color: $NEUTRAL-90;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px; /* 130% */
    }
    > :last-child {
      color: #333;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
    > img {
      width: 106.893px;
      height: 105.739px;
      flex-shrink: 0;
    }
  }
  .modalAction {
    display: flex;
    gap: 12px;
    > button {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .wrapper {
      .content {
        .grid {
          grid-auto-flow: row;
          grid-template-columns: 1fr;
          gap: 16px;
        }

        .informationSection {
          flex-direction: column;
          gap: 16px;

          .infoFields {
            height: auto;
            width: 100%;
            gap: 16px;
          }
        }
      }
    }
  }
}
