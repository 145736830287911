.container {
  max-width: 720px;
  max-height: 85vh;
  border-radius: 8px;
  background: var(--Neutral-10, #fff);
  display: flex;
  padding: 24px;
  flex-direction: column;
  //   overflow: auto;
  gap: 24px;
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > :first-child {
      > :first-child {
        color: #000;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px; /* 130% */
      }
      > :last-child {
        color: var(--Neutral-60, #9e9e9e);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
    > :last-child {
      cursor: pointer;
    }
  }
  .modalBody {
    width: 100%;
    overflow: auto;
    .contentWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .content {
        display: flex;
        padding: 16px;
        align-items: center;
        gap: 16px;
        border-radius: 10px;
        border: 1px solid var(--Neutral-40, #e0e0e0);
        background: var(--Neutral-10, #fff);
        text-align: justify;
        .leftSection {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 4px;
          flex: 1 0 0;
          > :first-child {
            color: var(--Neutral-100, #0a0a0a);
            font-size: 16px;
            font-weight: 600;
            line-height: 24px; /* 150% */
          }
          .contentBody {
            color: var(--Neutral-60, #9e9e9e);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            align-self: stretch;
          }
          .contentBody[is-show="true"] {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          > button {
            padding: 0;
          }
        }
      }
    }
  }
  .modalAction {
    display: flex;
    justify-content: flex-end;
  }
}
