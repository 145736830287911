@import "../../../theme/variables.scss";
.wrapperImageGalleryPrakerja {
  background: linear-gradient(
    180deg,
    #c9c9e9 -8.32%,
    rgba(201, 201, 233, 0.51) 18.42%,
    rgba(201, 201, 233, 0.31) 63.98%,
    rgba(201, 201, 233, 0) 100%
  );
  width: 100%;
  height: 800px;
  padding-top: 91px;
  padding-inline: 32px;
  position: relative;
  display: flex;
  justify-content: space-between;
  .clay {
    width: 794.416px;
    height: 794.416px;
    flex-shrink: 0;
    position: absolute;
    z-index: -1;
    left: -230px;
    transform: rotate(30deg);
    opacity: 0.4;
    filter: blur(4px);
    animation: fadeOutTop linear 3s infinite;
  }
  .sectionLeft {
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    width: 497px;
    .headerText {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      h1 {
        color: $NEUTRAL-100;
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
      }
      span {
        font-size: 40px;
        font-weight: 600;
        line-height: 50px;
        background: var(
          --New-Primary-Gradient-Color-Gradient-2,
          linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .generateVoucher {
      width: 386px;
      h1 {
        color: $NEUTRAL-100;
        font-size: 20px;
        font-weight: 600;
        line-height: normal;
      }
      p {
        color: $NEUTRAL-90;
        font-size: 14px;
        font-weight: 400;
        line-height: 160%;
      }
      .wrapperButton {
        padding-top: 12px;
        display: flex;
        gap: 15px;
        input[type="text"] {
          width: 272px;
          height: 44px;
          padding: 10px 11px;
          border-radius: 8px;
          border: 1px solid var(--Neutral-40, #e0e0e0);
          // background: var(--New-Primary-1-Primary-20, #c9c9e9);
          box-shadow: 0px 6px 24px 4px rgba(162, 166, 180, 0.25);
          backdrop-filter: blur(5.199999809265137px);
          font-size: 1rem;
          &::placeholder {
            color: $NEUTRAL-80;
            font-size: 16px;
            line-height: 20px;
          }
          &:focus {
            outline-color: $NEW-PRIMARY2-BASE;
          }
        }
        .button {
          width: 99px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          background: $NEUTRAL-10;
          box-shadow: 0 3px 1px $NEUTRAL-100;
          transition: 0.3s;
          cursor: pointer;
          span {
            color: $NEW-PRIMARY2-BASE;
            font-weight: 600;
          }
          &:active {
            box-shadow: none;
            transform: translateY(3px);
          }
        }
      }
      .errorMessage {
        color: $DANGER_MAIN;
        font-size: 14px;
        text-align: left;
      }
    }
    .skillsXprakerja {
      display: flex;
      gap: 38px;
      align-items: center;
      > div {
        display: flex;
        align-items: center;
        gap: 8px;
        span {
          color: $NEUTRAL-90;
          font-size: 24px;
        }
      }
      span {
        font-size: 24px;
      }
      .imagesLogo {
        width: 40px;
        height: 50px;
        z-index: 99;
      }
      .imagesLogoPrakerja {
        width: 109x;
        height: 37px;
      }
    }
  }
  .sectionRight {
    .borderWrapper {
      width: 681.105px;
      height: 678px;
      flex-shrink: 0;
      background-color: #c5e0f4;
      border-bottom-left-radius: 30%;
      position: absolute;
      top: -20px;
      // padding-left: 61px;
      right: 0;
      .heroImage {
        width: 698px;
        height: 100%;
        flex-shrink: 0;
        z-index: 1;
        position: absolute;
        // top: -100px;
      }
    }
  }
}
@media (max-width: 768px) {
  .wrapperImageGalleryPrakerja {
    .sectionRight {
      display: none;
    }
  }
}
@keyframes fadeOutTop {
  0%,
  100% {
    transform: translateY(1px) rotate(30deg);
  }
  50% {
    transform: translateY(-10px) rotate(30deg);
  }
}
