@import "../../../theme/variables.scss";

.container {
  @include padding-block(16, 12, 12px);
  @include padding-inline(32, 16, 16px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .button {
    position: absolute;
    top: 16px;
    left: 32px;
    >* {
      padding: 0;

    }
  }

  .main {
    display: flex;
    flex-direction: column;
    // gap: 24px;
    @include gap(51, 24, 24px);
    max-width: 1100px;
    margin: auto;
    width: 100%;

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      // gap: 12px;
      @include gap(12, 8, 8px);

      >h3 {
        font-family: $BASE-FONT-FAMILY;
        // font-size: 40px;
        @include font-size(40, 24, 24px);
        font-style: normal;
        font-weight: 500;
        @include line-height(44, 24, 24px);
        color: $NEUTRAL-90;
      }

      >span {
        font-family: $BASE-FONT-FAMILY;
        @include font-size(16, 14, 14px);
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: $NEUTRAL-60;
      }
    }

    .content {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 20px;
      // height: 210px;
    }

    >button {
      align-self: center;
    }
  }
}

.cardWrapper {
  border-radius: 8px;
  transition: margin 0.5s;
  margin-top: 0;
  box-shadow: $BOX-SHADOW;

  .card {
    padding: 24px 16px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    position: relative;
    cursor: pointer;
    background-color: $NEUTRAL-10;

    .radioWrapper {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    >img {
      width: 70px;
      aspect-ratio: 1;
      object-fit: contain;
    }

    .desc {
      display: flex;
      flex-direction: column;
      gap: 4px;
      color: $NEUTRAL-90;
      text-align: center;
    }
  }
}

.cardWrapper[is-disabled="false"] {
  border-radius: 8px;
  transition: margin 0.5s;
  margin-top: 0;
  box-shadow: $BOX-SHADOW;

  &.selected,
  &:hover {
    padding: 3px;
    background: $GRADIENT;

    &:hover {
      margin-top: -32px;
    }
  }

  .card {
    padding: 24px 16px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    position: relative;
    cursor: pointer;
    background-color: $NEUTRAL-10;

    .radioWrapper {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    >img {
      width: 70px;
      aspect-ratio: 1;
      object-fit: contain;
    }

    .desc {
      display: flex;
      flex-direction: column;
      gap: 4px;
      color: $NEUTRAL-90;
      text-align: center;
    }
  }
}

.cardWrapper[is-disabled="true"] {
  border-radius: 8px;
  transition: margin 0.5s;
  margin-top: 0;
  box-shadow: $BOX-SHADOW;

  .card {
    padding: 24px 16px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    position: relative;
    cursor: pointer;
    background-color: $NEUTRAL-20;
    border: 1px solid $NEUTRAL-40;

    .radioWrapper {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    >img {
      width: 70px;
      aspect-ratio: 1;
      object-fit: contain;
    }

    .desc {
      display: flex;
      flex-direction: column;
      gap: 4px;
      color: $NEUTRAL-60;
      text-align: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    justify-content: flex-start;
    overflow: auto;

    .cardWrapper {


      &:hover {
        margin-top: 0;
      }

    }

    .button {
      position: static;
      width: 100%;
    }

    .main {
      .content {
        height: auto;
        grid-template-columns: 1fr;


        >* {
          // max-width: 360px;
          display: flex;
          align-items: center;
          justify-content: center;

          >* {
            max-width: 360px;;
          }
        }
      }

    }


  }
}


@media screen and (max-width: 480px) {
  .container {
    align-items: flex-start;
    padding-bottom: 60px;

  

   

    .main {
      margin: 0;

      .header {
        >* {
          width: 100%;
          text-align: left;
        }
      }

      // .content {

      // }

      >button {
        align-self: auto;
      }

    }
  }
}