@import "../../../../theme/variables.scss";

.container {
  padding: 22px 32px 24px 27px;
  .tpmTag {
    & > :first-child {
      padding: 2px 8px;
      border-radius: 17px;
      display: inline-block;
      > p {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
      }
    }
  }
  .tpmTag[status="true"] {
    & > :first-child {
      color: $NEUTRAL-10;
      background: #60aa15;
    }
  }
  .tpmTag[status="false"] {
    & > :first-child {
      color: #d42701;
      background: #d42701;
    }
  }
  .tpmTag[status="none"] {
    & > :first-child {
      color: $NEUTRAL-50;
      background: $NEUTRAL-50;
    }
  }
  .skillTestTag {
    border-radius: 17px;
    display: flex;
    width: 112px;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    > p {
      color: var(--Neutral-90, #404040);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
    }
  }
  .skillTestTag[status="notFound"] {
    background: var(--Neutral-30, #ededed);
  }
  .skillTestTag[status="assessed"] {
    background: var(--Success-Success-20, #ddf9a4);
  }
  .skillTestTag[status="notAssessed"] {
    background: var(--Danger-Danger-20, #fac8a3);
  }
  .breadcrumbsWrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 24px;
    .backIcon {
      box-shadow: 0px 10px 32px -4px rgba(24, 39, 75, 0.1),
        0px 6px 14px -6px rgba(24, 39, 75, 0.12);
      background: #fff;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .breadcrumb {
      display: flex;
      gap: 4px;
      align-items: center;
      > p {
        color: $NEUTRAL-60;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
      & > :last-child {
        color: #2e3192;
      }
    }
  }
  .tabHeader {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
  }
  .mainTable {
    border-radius: 12px;
    background: #fff;
    box-shadow: -3px 4px 4px -2px rgba(24, 39, 75, 0.13),
      0px 2px 24px -2px rgba(24, 39, 75, 0.12);
    .tableTabWrapper {
      display: flex;
      border-bottom: 1px solid #e0e0e0;
      overflow: auto;
    }
    .searchbarWrapper {
      margin: 16px;
    }
    .tableSection {
      .legendWrapper {
        display: flex;
        padding-inline: 16px;
        margin-bottom: 8px;
        align-items: center;
        gap: 12px;
        .legend {
          display: flex;
          align-items: center;
          gap: 4px;
          .icon {
            width: 20px;
            height: 12px;
            border-radius: 13px;
          }
          & > :last-child {
            color: $NEUTRAL-100;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
          }
        }
      }
    }
  }
  .expandComponentContainer {
    gap: 16px;
    display: grid;
    grid-template-columns: 16px 103px 1fr;

    .leftSection {
      > p {
        padding: 4px;
        white-space: nowrap;
        height: 24px;
        color: $NEUTRAL-70;
        font-size: 10px;
        font-weight: 400;
        line-height: 14px; /* 140% */
      }
    }
    .rightSection {
      .tpmTagExpand {
        padding: 4px;
        & > :first-child {
          padding: 2px 8px;
          border-radius: 17px;
          display: inline-block;
          width: 100%;
          text-align: center;
          > p {
            font-size: 12px;
            font-weight: 500;
            line-height: 16px; /* 133.333% */
          }
        }
      }
      .tpmTagExpand[status="true"] {
        & > :first-child {
          color: $NEUTRAL-10;
          background: #60aa15;
        }
      }
      .tpmTagExpand[status="false"] {
        & > :first-child {
          color: #d42701;
          background: #d42701;
        }
      }
      .tpmTagExpand[status="none"] {
        & > :first-child {
          color: $NEUTRAL-50;
          background: $NEUTRAL-50;
        }
      }
      .text {
        height: 24px;
        color: $NEUTRAL-100;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding-bottom: 60px;
  }
}
