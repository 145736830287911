@import "../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;

  .remark {
    font-family: $BASE-FONT-FAMILY;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: $NEUTRAL-60;
  }
}

.title {
  font-family: $BASE-FONT-FAMILY;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: $NEUTRAL-80;
  padding-bottom: 8px;
  display: flex;
  align-items: center;

  .redStar {
    color: $DANGER_MAIN;
    font-weight: 400;
    font-size: 11px;
    flex-grow: 1;
    // padding-left: 4px;
  }

}