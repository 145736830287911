@import "../../../../theme/variables.scss";

.container {
  padding: 16px 32px 32px 24px;
  .breadcrumbsWrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 24px;
    .backIcon {
      box-shadow: 0px 10px 32px -4px rgba(24, 39, 75, 0.1),
        0px 6px 14px -6px rgba(24, 39, 75, 0.12);
      background: #fff;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .breadcrumb {
      display: flex;
      gap: 4px;
      align-items: center;
      > p {
        color: $NEUTRAL-60;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
      & > :last-child {
        color: $NEUTRAL-100;
      }
    }
  }
 
  .tabHeaderWrapper{
    display: flex;
    gap: 12px;
    margin-bottom: 20px;
    overflow: auto;
  }
}
