@import "../../../theme/variables.scss";

.container {
  display: flex;
  align-items: center;
  gap: 16px;

  >span {
    flex-grow: 1;
    font-family: $BASE-FONT-FAMILY;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
  }
}