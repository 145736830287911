@import "../../../theme/variables.scss";

.body {
  width: 100vw;
  height: 100vh;
  background: var(
    --New-Primary-Gradient-Color-Gradient-2,
    linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%)
  );
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 542px;
    gap: 24px;
    .logoWrapper {
      display: flex;
      gap: 12px;
      align-items: center;
      > img {
        width: 40px;
        height: 50px;
        flex-shrink: 0;
      }
      > p {
        color: $NEUTRAL-10;
        font-size: 24px;
        font-weight: 400;
      }
    }
    .bodyWrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      justify-content: center;
      text-align: center;
      align-items: center;
      color: #fff;
      > img {
        width: 103px;
        height: 103px;
        flex-shrink: 0;
      }
      .bodyDesc {
        display: flex;
        flex-direction: column;
        gap: 8px;
        > :first-child {
          font-size: 20px;
          font-weight: 600;
          line-height: 26px; /* 130% */
        }
        > :last-child {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
    }
  }
}
