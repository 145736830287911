@import "../../../theme/variables.scss";
.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  .titleWrapper {
    margin-bottom: 8px;
    padding-bottom: 12px;
    border-bottom: 2px solid $NEUTRAL-30;
    > p {
      color: $NEUTRAL-90;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
    }
  }
  .descWrapper {
    > p {
      color: $NEUTRAL-90;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      white-space: pre-wrap;
    }
  }
  .additionalWrapper {
    .content {
      border: 1px solid $NEUTRAL-40;
      display: flex;
      align-items: center;
      border-radius: 12px;
      padding: 8px;
      .title {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        gap: 6px;
        & > :first-child {
          color: $NEUTRAL-90;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px; /* 125% */
        }
        // & > :last-child {
        //   color: $NEUTRAL-70;
        //   font-size: 14px;
        //   font-weight: 400;
        //   line-height: 140%;
        // }
      }
      & > :last-child {
        margin-left: auto;
      }
    }
  }
  .mobile{
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .container {
    .desktop {
      display: none !important;
    }
    .mobile{
        display: block !important;
    }
  }
}
