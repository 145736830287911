.wrapperAlertWarning {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  background-color: #fdf4d8;
  padding: 16px 40px;
  line-height: 1rem;
  width: 100%;
}
.wrapperContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  text-align: flex-start;
}
@media (max-width: 430px) {
  .wrapperAlertWarning {
    gap: 12px;
    padding: 8px 16px;
    line-height: 12px;
  }
}
