@import "../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  text-align: left;
  @include gap(32, 24, 24px);

  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    > h3 {
      font-family: $BASE-FONT-FAMILY;
      @include font-size(24, 20, 20px);
      font-style: normal;
      font-weight: 600;
    }

    > span {
      font-family: $BASE-FONT-FAMILY;
      @include font-size(16, 14, 14px);
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      color: $NEUTRAL-70;
    }
  }
  .configWrapper {
    border-radius: 8px;
    background: var(--Neutral-20, #f5f5f5);
    padding: 12px;

    .config {
      display: flex;
      justify-content: space-between;
      transition: max-height 0.5s ease 0s;
      overflow: hidden;
      align-items: center;

      > p {
        color: $NEUTRAL-90;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
    > :first-child {
      > p {
        color: var(--New-Primary-2-Primary-Base, #9360a8);
        font-size: 16px;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }
    }
    .config[is-shown="false"] {
      max-height: 0px;
      border-color: transparent;
      padding-top: 0;
      margin-top: 0;
    }
    .config[is-shown="true"] {
      max-height: 100px;
      border-top: 1px solid #e0e0e0;
      padding-top: 0.6vmin;
      margin-top: 0.6vmin;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: none;
    @include padding-top(32, 24, 24px);
    max-height: 0;
    transition: max-height 2s ease 0s;
    overflow: hidden;

    .questions {
      display: flex;
      flex-direction: column;
      @include gap(20, 16, 16px);
    }
  }
  .content[is-shown="true"] {
    max-height: 10000vh;
    border-top: 1px solid $NEUTRAL-40;

  }
}
