@import "../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    flex-direction: column;
    > :first-child {
      width: 100px;
      height: 100px;
    }
  }
  .buttonSendTable {
    display: flex;
    justify-content: flex-end;
  }
  .controlSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .buttonWrapper {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    .searchBar {
      width: 280px;
    }
    .buttonClear[is-disabled="true"] {
      color: #9e9e9e;
      cursor: not-allowed;
    }
    .buttonClear {
      display: flex;
      padding: 8px;
      gap: 6px;
      align-items: center;
      cursor: pointer;
      color: var(--Danger-Danger-60, #b22a12);
      > p {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px; /* 125% */
      }
    }
  }
  .bodySection {
    border-radius: 12px;
    border: 1px solid var(--Neutral-40, #e0e0e0);
    background: #fff;
  }
}
