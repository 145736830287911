@import "../../../theme/variables.scss";
.wrapperModalVoucher {
  padding: 24px;
  border-radius: 12px;
  background: $NEUTRAL-10;
  width: 414px;
  height: max-content;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  h2 {
    color: $NEUTRAL-90;
    font-family: $BASE-FONT-FAMILY;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
  .wrapperInputField {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    label {
      color: $NEUTRAL-80;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      span {
        color: $DANGER-50;
      }
    }
    .addPercent {
      position: relative;
      &::after {
        content: "%";
        position: absolute;
        right: 0;
        color: $NEUTRAL-50;
        top: 50%;
        transform: translate(-80%, -50%);
      }
    }
  }
  .buttonModal {
    display: flex;
    gap: 12px;
    width: 100%;
    div:nth-child(1) {
      cursor: pointer;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      border: 2px solid transparent;
      border-radius: 8px;
      background: linear-gradient($NEUTRAL-10, $NEUTRAL-10) padding-box,
        linear-gradient(to right, #9360a8 35.02%, #46b7e9 125.87%) border-box;
      &:hover {
        background: linear-gradient($NEUTRAL-10, $NEUTRAL-10) padding-box,
          linear-gradient(to right, #9360a8 35.02%, #9360a8 125.87%) border-box;
        span {
          background: $NEW-PRIMARY2-BASE;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      span {
        font-family: $BASE-FONT-FAMILY;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        background: $COLOR-GRADIENT-2;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    div:nth-child(2) {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      padding: 8px 16px;
      border-radius: 8px;
      background: linear-gradient(137deg, #9360a8 35.02%, #46b7e9 125.87%);
      span {
        color: $NEUTRAL-10;
        font-weight: 600;
      }
      &:hover {
        background: $NEW-PRIMARY2-BASE;
      }
    }
  }
}
@media (max-width: 440px) {
  .wrapperModalVoucher {
    width: max-content;
  }
}
