@import "../../../theme/variables.scss";

.container {
  width: 100vw;
  height: 100vh;
  @include padding(32, 16, 16px);
  overflow: clip;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .header {

    >button {
      display: flex;
      align-items: center;
      gap: 6px;
      color: $NEUTRAL-10;

      >span {
        font-family: $BASE-FONT-FAMILY;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 125% */
      }
    }

  }

  .content {
    // height: 100px;
    overflow-y: auto;
    overflow-x: clip;
    // flex-grow: 1;
    position: relative;
    width: auto;
    align-self: center;

    .videoWrapper, .imageWrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .video, .image {
        object-fit: contain;
        // aspect-ratio: 2/1;
        background-color: $NEUTRAL-100;
        max-width: 1440px;

        &.image {
          max-height: 80vh;
          max-width: 90vw;
        }

        &.video {
          height: 80vh;
          width: 90vw;
  
        }
      }
    }
    .imagesWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;

      >div {
       width: 899px;
       max-width: 90vw;

        >img {
          width: 100%;
        }
      }
    }
  }
}