@import "../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 8px;
  .header {
    > p {
      color: #000;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }
  }
  .body {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 16px;
    background: $NEUTRAL-20;
    .cardWrapper {
      display: flex;
      align-items: center;
      gap: 24px;
      width: 100%;
      .cardBody {
        flex: 1;
        display: flex;
        gap: 16px;
        align-items: center;
        .image {
          > img {
            width: 77.002px;
            height: 79.627px;
            flex-shrink: 0;
            object-fit: contain;
          }
        }
        .desc {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 16px;
          .top {
            color: $NEUTRAL-90;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
          }
          .bottom {
            .progressSection {
              .progressWrapper {
                display: flex;
                gap: 8px;
                align-items: center;
                .progressComponent {
                  position: relative;
                  width: 100%;
                  .progressBase {
                    height: 8px;
                    border-radius: 100px;
                    background: $NEUTRAL-40;
                    width: 100%;
                  }
                  .progress {
                    position: absolute;
                    background: $GRADIENT;
                    height: 8px;
                    border-radius: 100px;
                    top: 0;
                  }
                }
                .progressText {
                  color: #000;
                  font-size: 12px;
                  font-weight: 600;
                  line-height: 14px; /* 116.667% */
                }
              }
              .progressDesc {
                display: flex;
                align-items: center;
                gap: 8px;
                margin-top: 8px;
                & > :last-child {
                  color: $NEUTRAL-70;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 14px; /* 116.667% */
                }
              }
            }
          }
        }
      }
      .cardAction {
      }
    }
  }
}

@media (max-width: 600px) {
  .container {
    .cardWrapper {
      flex-direction: column;
      .cardBody {
        width: 100%;
      }
      .cardAction {
        width: 100%;
        > button {
          width: 100%;
        }
      }
    }
  }
}
