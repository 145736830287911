@import "../../../theme/variables.scss";
@mixin button() {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: not-allowed;
}
@mixin textGradient() {
  background: var(
    --New-Primary-Gradient-Color-Gradient-2,
    linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}
.section {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 596px !important;
  .buttonTabs {
    display: flex;
    justify-content: space-between;
    color: $NEUTRAL-60;
    font-size: 1rem;
    font-weight: 600;
    align-items: center;
    .papperNumber {
      font-size: 0.8rem;
      font-weight: normal;
    }
    .backButton {
      @include button();
      span[prevButton="true"] {
        @include textGradient();
      }
      .arrowIconLeft {
        transform: rotate(90deg);
      }
    }
    .nextButton {
      @include button();
      span[nextButton="true"] {
        @include textGradient();
      }
      .arrowIconRight {
        transform: rotate(-90deg);
      }
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 12px;
    background: $NEUTRAL-10;
    box-shadow: 0px 6px 24px 0px rgba(141, 156, 178, 0.25);
    position: relative;
    row-gap: 24px;
    width: 100%;
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background-color: $NEUTRAL-10;
      z-index: -1;
      box-shadow: 0px 6px 24px 0px rgba(141, 156, 178, 0.25);
      border-radius: 12px;
    }
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 12px;
      top: 12px;
      background-color: $NEUTRAL-10;
      z-index: -1;
      box-shadow: 0px 6px 24px 0px rgba(141, 156, 178, 0.25);
      border-radius: 12px;
    }
    div[nth="1"] {
      line-height: 1rem;
      font-size: 0.8rem;
      color: $NEUTRAL-100;
    }
    div[nth="2"] {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      div {
        display: flex;
        gap: 8px;
        align-items: center;
        span {
          line-height: 1rem;
          font-size: 0.8rem;
        }
      }
    }
    .headerQuestion {
      color: var(--Neutral-90, #404040);
      font-size: 16px;
      font-weight: 600;
      line-height: 140%;
    }
    .descriptionQuestion {
      color: var(--light-paragraph, #333);
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
    .answerQuestion {
      width: 100%;
      textarea {
        padding: 12px;
        border-radius: 8px;
        border: 1px solid var(--Neutral-40, #e0e0e0);
        resize: none;
        width: 100%;
        height: 176px;
        &::placeholder {
          color: var(--Neutral-50, #c2c2c2);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
        &:focus {
          outline: 2px solid $NEW-PRIMARY2-BASE;
        }
      }
    }
  }
  .buttonSubmit {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    width: 100%;
    button {
      transition: 0.8s;
      border-radius: 8px;
      background: var(
        --New-Primary-Gradient-Color-Gradient-2,
        linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%)
      );
      padding: 8px 16px;
      color: $NEUTRAL-10;
      font-weight: 600;
      font-size: 1rem;
    }
    button[isDisabled="true"] {
      background: $NEUTRAL-40 !important;
      border: 2px solid $NEUTRAL-40 !important;
      color: $NEUTRAL-60 !important;
      cursor: not-allowed;
    }
  }
}
@media (max-width: 600px) {
  .section {
    padding-inline: 20px;
    width: 326px !important;
    .buttonSubmit {
      button {
        width: 100%;
      }
    }
  }
}
