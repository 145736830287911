@import "../../../theme/variables.scss";

.input {
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0px 6px 24px 4px rgba(162, 166, 180, 0.25);
  backdrop-filter: blur(5.199999809265137px);
  border: 1px solid $NEUTRAL-10;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  gap: 10px;

  > input {
    color: white;
    background: transparent;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    height: 100%;
    width: 100%;
  }
  > input:focus {
    background: transparent !important;
    border: none !important;
    outline: none !important;
  }
  > input::placeholder {
    color: white;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}
