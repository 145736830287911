@import "../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  text-align: left;
  @include gap(32, 24, 24px);

  .configWrapper {
    border-radius: 8px;
    background: var(--Neutral-20, #f5f5f5);
    padding: 12px;

    .config {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > p {
        color: var(--New-Primary-2-Primary-Base, #9360a8);
        font-size: 16px;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    > h3 {
      font-family: $BASE-FONT-FAMILY;
      @include font-size(24, 20, 20px);
      font-style: normal;
      font-weight: 600;
    }

    > span {
      font-family: $BASE-FONT-FAMILY;
      @include font-size(16, 14, 14px);
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      color: $NEUTRAL-70;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-height: 0;
    transition: max-height 0.5s ease 0s;
    overflow: hidden;
    @include padding-top(32, 24, 24px);
  }
  .content[is-shown="true"] {
    max-height: 100vh;
    border-top: 1px solid $NEUTRAL-40;
  }
}
