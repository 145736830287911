.container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  border-radius: 12px;
  border: 1px solid #e5e5e5;
  background: #fff;
  max-width: 600px;
  width: 600px;
  position: relative;
  .radioText {
    display: flex;
    gap: 32px;
    align-items: center;
  }
  .button {
    display: flex;
    justify-content: right;
    width: 100%;
    z-index: 99;
  }
  .isCorrectToast,
  .isWrongToast {
    bottom: 90px;
    padding: 8px 14px;
    color: white;
    border-radius: 8px;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .isWrongToast {
    background-color: #b22a12;
  }
  .isCorrectToast {
    background-color: #7ac61b;
  }
  .question {
    font-size: 1rem;
    color: #000;
    line-height: 24px;
  }
}
.container[isCorrect="true"] {
  &::before {
    position: absolute;
    content: "";
    background: url("../../../assets/Images/IsCorrectQuestion.gif") no-repeat;
    width: 100%;
    height: 90%;
    background-size: 100%;
  }
}
@media (max-width: 600px) {
  .container {
    gap: 12px !important;
    width: 320px;
    .button {
      display: flex;
      flex-shrink: 0;
    }
    .isWrongToast span,
    .isCorrectToast span {
      font-size: 14px !important;
    }
    .question {
      line-height: 20px;
    }
  }
}
