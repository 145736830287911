@import "../../.././theme/variables.scss";
.wrapperNotFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-inline: 1rem;
  > section {
    .button {
      padding-top: 1rem;
    }
    display: flex;
    flex-direction: column;
    .relativeElement {
      img {
        width: 235px;
        height: 235px;
        flex-shrink: 0;
      }
      position: relative;
      &::after {
        position: absolute;
        content: "";
        width: 120px;
        height: 20px;
        border-radius: 50%;
        bottom: 50px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: -1;
        background-color: rgba(164, 164, 164, 0.789);
        filter: blur(10px);
      }
      &::before {
        position: absolute;
        content: "";
        width: 284px;
        height: 65px;
        border-radius: 50%;
        background: linear-gradient(179deg, #f4eff6 0.81%, #e9e7ff 138.54%);
        bottom: 16px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: -1;
      }
    }
    display: flex;
    align-items: center;
    flex-direction: column;
    h1 {
      font-size: 1rem;
      color: $NEUTRAL-100;
      font-weight: 500;
    }
    p {
      color: $NEUTRAL-60;
      font-size: 12px;
    }
  }
}
@media (max-width: 768px) {
  .wrapperNotFound {
    h1 {
      font-size: 14px !important;
    }
  }
}
