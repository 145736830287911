@import "../../../theme/variables.scss";

.container {
  width: 100%;
  background-color: $NEUTRAL-10;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 0;
  overflow: auto;
  border-radius: 0 0 20px 20px;

  .loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    flex-direction: column;
    > :first-child {
      width: 100px;
      height: 100px;
    }
  }

  .expandButton {
    display: grid;
    place-items: center;


  &.rotate {
    transform: rotate(180deg);
  }
  }

  .tableWrapper {
    overflow: visible;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;

      thead {
        z-index: 1;

        tr {

          th {
            position: sticky;
            top: 0;
            padding: 12px 16px;
            border-bottom: 1px solid $NEUTRAL-40;
            font-family: $BASE-FONT-FAMILY;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 142.857%;
            white-space: nowrap;
            z-index: 1;
            background-color: $NEW-PRIMARY2-BASE;
            color: $NEUTRAL-10;
          }
        }
      }

      tbody {

        tr {

          td {
            padding: 12px 16px;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: $BASE-FONT-FAMILY;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            border-bottom: 1px solid $NEUTRAL-30;
            white-space: wrap;

            >span {
              overflow: hidden;
              display: -webkit-box;
              line-clamp: 1;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }

          &.expandedRow {
            background-color: $NEUTRAL-20;
          }

       
        }
      }
    }
  }

  .paginationWrapper {
    border-top: 1px solid $NEUTRAL-40;
    // border-radius: 0 0 20px 20px;
    overflow: visible;
  }
}