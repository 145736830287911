@import "../../../theme/variables.scss";

.field {
  background-color: $NEUTRAL_20;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px dashed $NEUTRAL_50;
  overflow: hidden;

  >* {
    width: 100%;
  }

  .noPreview {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      font-family: $BASE-FONT-FAMILY;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $NEUTRAL_50;
    }

    button {
      font-family: $BASE-FONT-FAMILY;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration-line: underline;
      color: $NEW-PRIMARY2-BASE;
      background-color: transparent;
      border: none;
    }

    // &.withFile {
    //   background-color: $PRIMARY_SURFACE;
    //   border-color: $PRIMARY_MAIN;
    // }

    p {
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }


  }

  .withPreview {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    >img {
      height: 125px;
      width: auto;
      object-fit: contain;
      border: 7.5px;
    }

    >div {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-width: calc(100% - 175px);

      >span {
        font-family: Poppins;

        &:first-child {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: $NEUTRAL_90;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        &:last-child {
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          color: $NEUTRAL_60;
        }
      }
    }

    >button {
      display: grid;
      place-items: center;
      border: none;
      background-color: transparent;
    }
  }





}