@import "../../../../theme/variables.scss";
.lottieLoad {
  width: 497px;
  height: 330px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 12px;
  border-radius: 12px;
  background: $NEUTRAL-10;
  > .lottie {
    height: 200px;
    width: 200px;
  }
  @media (max-width: 768px) {
    width: 90%;
    height: 30%;
    > .lottie {
      height: 150px;
      width: 150px;
    }
  }
}
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 78px);

  .expandedInfo {
    display: flex;
    flex-direction: column;

    .infoRow {
      display: flex;
      align-items: center;
      > * {
        padding: 4px 8px;

        &:first-child {
          width: 103px;
        }
      }

      > span {
        &:first-child {
          font-family: $BASE-FONT-FAMILY;
          color: $NEUTRAL-70;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
        }

        &:last-child {
          font-family: $BASE-FONT-FAMILY;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 133.333%;
        }
      }

      .instructor {
        font-size: 12px !important;
      }
    }
  }

  .wrapper {
    @include padding-block(24, 12, 12px);
    @include padding-inline(24, 16, 16px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .ellipsisTwoColumn {
      display: -webkit-box;
      display: -moz-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      cursor: pointer;
    }
    .ellipsisOneColumn {
      display: -webkit-box;
      display: -moz-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
    }
    .classSchedule {
      padding: 6px 10px;
      border-radius: 32px;
      text-align: center;
      width: 92px;
      background-color: $DANGER-10;
      color: $DANGER-60;
      font-size: 12px;
      @media (max-width: 768px) {
        padding: 4px 10px;
        font-size: 12px;
        width: max-content;
      }
    }
    .classSchedule[is-available="true"] {
      background-color: $SUCCESS-10;
      color: $SUCCESS-60;
      font-size: 14px;
    }
    .published {
      cursor: pointer;
      padding: 8px 16px;
      border-radius: 8px;
      border: 2px solid $NEW-PRIMARY2-BASE;
      text-align: center;
      color: $NEW-PRIMARY2-BASE;
      display: flex;
      gap: 6px;
      width: fit-content;
      transition: 0.2s;
      align-items: center;
      .iconUpload {
        height: 20px;
        width: 20px;
        flex-shrink: 0;
      }
      &:hover {
        border: 2px solid $NEW-PRIMARY2-60;
        color: $NEW-PRIMARY2-60;
      }
      @media (max-width: 768px) {
        padding: 4px 8px;
        font-size: 12px;
        .iconUpload {
          height: 16px;
          width: 16px;
        }
      }
    }
    .published[is-published="true"] {
      border: 2px solid $DANGER-30;
      color: $DANGER-50;
      &:hover {
        border: 2px solid $DANGER-40;
        color: $DANGER-60;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;

    .title {
      flex-grow: 1;
      color: $NEUTRAL-10;
      display: flex;
      flex-direction: column;
      gap: 8px;

      h3 {
        font-family: $BASE-FONT-FAMILY;
        @include font-size(24, 16, 16px);
        font-style: normal;
        font-weight: 400;
        line-height: 30px;

        > span {
          font-weight: 600;
        }
      }

      > span {
        font-family: $BASE-FONT-FAMILY;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 142.857%;
      }
    }

    > button {
      background-color: $NEUTRAL-10;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      padding: 8px 16px;
      color: $NEW-PRIMARY2-BASE;
      border-radius: 8px;

      > span {
        font-family: $BASE-FONT-FAMILY;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%;
      }
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .content {
    border-radius: 12px;
    box-shadow: -3px 4px 4px -2px rgba(24, 39, 75, 0.13),
      0px 2px 24px -2px rgba(24, 39, 75, 0.12);
    overflow: visible;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .contentTabs {
      border-bottom: 1px solid $NEUTRAL-40;
      display: flex;
      align-items: center;
    }

    .contentFilter {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
    }

    .table {
      min-height: 250px;

      &.grow {
        flex-grow: 1;
      }

      .instructor {
        display: flex;
        align-items: center;
        gap: 10px;
        @media (max-width: 768px) {
          gap: 6px;
        }
        > *:first-child {
          flex-shrink: 0;
        }
      }
    }

    // .contentTable {
    // }
  }
  .modalPublished {
    padding: 24px;
    border-radius: 12px;
    background-color: $NEUTRAL-10;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;
    max-width: 497px;
    width: auto;
    text-align: center;
    > h2 {
      color: $NEUTRAL-90;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
    }
    .blend {
      width: 112.851px;
      height: 111.633px;
      flex-shrink: 0;
    }
    .descModal {
      position: relative;
      top: -16px;
      color: #333;
      text-align: center;
      font-size: 14px;
      line-height: 20px;
    }
    .btnModal {
      display: flex;
      width: 100%;
      gap: 8px;
      > div {
        width: 100%;
        align-items: center;
        border: 1px;
        cursor: pointer;
        width: 100%;
        padding: 8px 12px;
        border-radius: 8px;
        background: linear-gradient(white, white) padding-box,
          linear-gradient(to right, #795ec6, #8cc5de) border-box;
        border: 2px solid transparent;
        font-size: 1rem;
        font-weight: 600;
        display: flex;
        justify-content: center;
        transition: 1s;
        &:hover {
          border-color: $NEW-PRIMARY2-BASE;
          > span {
            color: $NEW-PRIMARY2-BASE;
            background: unset;
            background-clip: unset;
            -webkit-background-clip: unset;
            -webkit-text-fill-color: unset;
          }
        }
        span {
          background: linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 1rem;
        }
      }
      .btnRight {
        width: 100%;
        font-weight: 600;
      }
    }
    @media (max-width: 768px) {
      width: 90%;
      > h2 {
        font-size: 16px;
      }
      .blend {
        width: 90.851px;
        height: 89.633px;
      }
      .btnModal {
        > div {
          font-size: 14px;
          padding: 6px 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .header {
      flex-direction: column;
      gap: 10px;

      > button {
        width: 100%;
      }
    }

    .contentFilter {
      flex-direction: column;
      gap: 8px !important;

      > * {
        width: 100%;
      }
    }
  }
}
