@import "../../../theme/variables.scss";
.counter {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  color: $NEUTRAL-10;
  padding-inline: 3%;
  padding-block: 32px;
  margin-left: 20px;
  position: relative;
  z-index: -1;
  .year,
  .course,
  .studies {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    h1 {
      font-size: 40px;
      font-weight: 500;
      line-height: 44px;
      animation: count-up 3s ease-out;
    }

    p {
      font-size: 14px;
      line-height: 1rem;
      font-weight: normal;
    }
  }
  .course {
    width: 100px;
  }

  span {
    display: flex;
    width: 1px;
    height: 60px;
    background-color: $NEW-PRIMARY2-30;
    margin-inline: 49px;
  }

  @keyframes count-up {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
@media (max-width: 768px) {
  .counter {
    display: flex;
    justify-content: center;
    padding-inline: 0 !important;
    margin-left: 0 !important;
    gap: 0 !important;
    .year,
    .course,
    .studies {
      display: flex;
      flex-direction: column;
      h1 {
        font-size: 20px !important;
        font-weight: 500;
        line-height: 34px;
        animation: count-up 3s ease-out;
      }

      p {
        font-size: 12px;
        line-height: 0.8rem;
        font-weight: normal;
      }
    }
    .studies {
      width: 100px;
    }
    span {
      margin-inline: 20px;
    }
  }
}
