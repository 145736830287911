@import "../../../theme/variables.scss";


.container {
  width: 234px;
  height: 315px;
  border: 1px solid $NEUTRAL-40;
  border-radius: 8px;
  display: grid;
  place-items: center;
  overflow: clip;

  >img {
    width: 234px;
    height: 315px;
    object-fit: cover;
    cursor: pointer;
  }


  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    >span {
      font-family: $BASE-FONT-FAMILY;
      color: $NEUTRAL-50;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 142.857%;
      padding-bottom: 34px;
    }
  }



}