@import "../../../../theme/variables.scss";

.container {
  margin-top: 32px;
  .journalHeader {
    background: linear-gradient(98deg, #8066ca 0%, #8cc5de 100.02%);
    padding: 24px;
    margin: 0 32px 0 25px;
    border-radius: 12px;
    display: flex;
    .leftSection {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .desc {
        & > :first-child {
          color: $NEUTRAL-10;
          font-size: 24px;
          font-weight: 500;
          line-height: 24px; /* 100% */
        }
        & > :last-child {
            margin-top: 8px;
          color: $NEUTRAL-10;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
    }
    .rightSection {
      > img {
        width: 184px;
        height: 198px;
      }
    }
  }
}
