@import "../../../theme/variables.scss";

.linear {
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;

  &.disabled {
    cursor: auto;
  }

  >div {
    height: 4px;
    border-radius: 10px;
    background: $NEUTRAL-50;
    position: relative;
    overflow: clip;
  }

  >p {
    display: flex;
    align-items: center;
    gap: 8px;

    >span {
      text-align: left;
      font-family: $BASE-FONT-FAMILY;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $NEUTRAL-50;
      white-space: nowrap;
    }
  }



  &.active,
  &.completed {

    >div {
      background: #9360a8;
    }

    >p {
      color: $NEW-PRIMARY2-BASE;

      >span {
        color: $NEW-PRIMARY2-BASE;
      }
    }



    &.active {

      >div {

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 4px;
          background-image: linear-gradient(to right, #9360a8, #8772bd, #7883ce, #6693da, #55a3e3, #55a3e3, #55a3e3, #55a3e3, #6693da, #7883ce, #8772bd, #9360a8);
          border-radius: inherit;
          animation: stepperGradient infinite 1.5s linear;
        }
      }
    }
  }
}

@keyframes stepperGradient {
  0% {
    transform: translateX(-50px);
    width: 20%;
    box-shadow: 0 2px 10px rgb(65, 211, 255);
  }

  90% {
    width: 50%;
  }

  100% {
    transform: translateX(150px);
    width: 100%;
  }
}