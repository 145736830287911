@import "../../../theme/variables.scss";

.container {
  padding: 16px 0;
  .wrapperCard {
    width: 100%;
    background: #ffff;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    // display: flex;
    // flex-wrap: wrap;
    // grid-template-columns: repeat(auto-fill, minmax(171px, 00px));
    row-gap: 32px;
    gap: 24px;
    // justify-content: center;
    .card {
      cursor: pointer;
      height: 100%;
      width: 100%;
      flex-shrink: 0;
      overflow: hidden;
      box-shadow: 8px 9px 16px 0px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      backdrop-filter: blur(6px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      background: radial-gradient(rgb(202, 84, 253) 1%, rgba(17, 5, 20, 0.848));
      backdrop-filter: blur(6px);

      &::before {
        background: url("../../../assets/Images/left-clay.png");
        content: "";
        position: absolute;
        bottom: -50px;
        width: 193.06px;
        height: 193.06px;
        transform: rotate(-30deg);
        background-size: contain;
        z-index: -1;
        filter: blur(8px);
        left: -80px;
      }

      &::after {
        background: url("../../../assets/Images/left-clay.png");
        content: "";
        position: absolute;
        bottom: -50px;
        width: 193.06px;
        height: 193.06px;
        background-size: contain;
        z-index: -1;
        filter: blur(16px);
        right: -120px;
      }

      .image {
        position: relative;
        width: 100%;
        div {
          opacity: 0.9;
          background: linear-gradient(
            90deg,
            #d7e677 25.66%,
            rgba(226, 237, 156, 0.73) 61.78%,
            rgba(255, 255, 255, 0) 95.76%
          );
          width: 100%;
          height: 28px;
          position: absolute;
          bottom: 0;
        }

        .imgText {
          display: flex;
          align-items: center;
          padding: 8px;

          span {
            color: #000;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }
        }

        display: flex;
        flex-direction: column;

        img {
          width: 100%;
          height: 250px;
          flex-shrink: 0;
          object-fit: cover;
        }
      }

      .description {
        padding: 0 16px 8px 16px;

        padding-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;

        .class {
          display: flex;
          gap: 8px;

          div:nth-child(1) {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $SUCCESS-30;
            border-radius: 4px;
            padding-inline: 8px;

            span {
              color: $NEUTRAL-100;
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
            }
          }

          div:nth-child(2) {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #d6d6d6;
            border-radius: 4px;
            padding-inline: 8px;
            padding-block: 3.5px;

            img {
              width: 55px;
              height: 17px;
            }
          }
        }

        .title {
          p:nth-child(1) {
            display: -webkit-box;
            display: -moz-box;
            display: -o-box;
            display: -ms-box;
            -webkit-box-orient: vertical;
            box-orient: vertical;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            overflow: hidden;
            color: $NEUTRAL-10;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
          }

          p:nth-child(2) {
            color: $NEUTRAL-20;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
          }
        }

        .price {
          p {
            color: $SUCCESS-30;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          }
        }
      }
    }
  }
  .headWrapper {
    padding: 0 32px;
    .headerSection {
      margin-top: 16px;
      display: flex;
      padding: 24px 24px 16px 24px;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background: var(
        --New-Primary-Gradient-Color-Gradient-2,
        linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%)
      );
      width: 100%;
      align-items: center;
      margin-bottom: 32px;
      .leftSection {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-self: stretch;
        justify-content: center;
        > :first-child {
          color: var(--Neutral-10, #fff);
          font-size: 24px;
          font-weight: 600;
          line-height: 30px; /* 125% */
        }
        > :last-child {
          color: var(--Neutral-10, #fff);
          font-size: 14px;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
      .rightSection {
        > img {
          width: 101.854px;
          height: 96px;
          flex-shrink: 0;
        }
      }
    }
  }
  .contentWrapper {
    display: grid;
    grid-template-columns: 30% auto;
    padding: 0px 32px;
    position: relative;
    gap: 70px;
    .filterSection {
      display: flex;
      flex-direction: column;
      gap: 16px;
      position: sticky;
      // top: 90px;
      overflow: auto;
      // height: max-content;
      .filterHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > p {
          color: #000;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
      }
      .filterCardWrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .filterCard {
          max-height: 330px;
          display: flex;
          flex-direction: column;
          gap: 12px;
          padding: 16px 16px 16px 12px;
          border-radius: 8px;
          border: 1px solid var(--Neutral-30, #ededed);
          background: var(--Neutral-10, #fff);
          box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
            0px 8px 16px -6px rgba(24, 39, 75, 0.08);
          .filterOptions {
            overflow: auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            .option {
              display: flex;
              align-items: center;
              gap: 8px;
              > p {
                color: var(--Neutral-100, #232323);
                font-size: 14px;
                font-weight: 400;
                line-height: 20px; /* 142.857% */
              }
            }
          }
          > p {
            color: var(--Neutral-100, #232323);
            font-size: 14px;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
          }
        }
      }
    }
  }
  .itemsWrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    .itemHead {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > :last-child {
        display: flex;
        > p {
          color: var(--Neutral-90, #404040);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding: 0 16px !important;
    .wrapperCard{
      grid-template-columns: 1fr;
    }
    .headerSection {
      margin-top: 8px;
      .rightSection {
        > img {
          width: 67px;
          height: 63.149px;
        }
      }
    }
    .contentWrapper {
      display: grid;
      grid-template-columns: 100%;
    }
    .filterSection {
      position: relative !important;
    }
  }
}
