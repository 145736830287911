@import "../../../../theme/variables.scss";

.container {
  padding: 24px 32px 24px 24px;
  .topCard {
    display: flex;
    justify-content: space-between;
    .leftSection {
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: white;
      .title {
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        > span {
          font-weight: 600;
        }
      }
      .desc {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
      .inputWrapper {
        width: 60%;
      }
    }
    .rightSection {
      > img {
        width: 115px;
        height: 100%;
      }
    }
  }
  .section {
    padding-top: 20px;
    .sectionTitle {
      margin-bottom: 12px;
      > p {
        color: #000;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
      }
    }
    .ratingSection {
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12),
        0px 8px 18px -6px rgba(24, 39, 75, 0.12);
      padding: 16px;
      & > :first-child {
        color: $NEUTRAL-90;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 8px;
      }
      .body {
        display: flex;
        gap: 24px;
        align-items: center;
        .leftSection {
          display: flex;
          align-items: center;
          gap: 7px;
          & > :first-child {
            color: #000;
            font-size: 52px;
            font-style: italic;
            font-weight: 600;
            line-height: 44px;
          }
        }
        .rightSection {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 100%;
          .rating {
            display: flex;
            gap: 8px;
            > p {
              color: $NEUTRAL-80;
              font-size: 12px;
              font-weight: 400;
              line-height: 14px;
            }
            .progress {
              position: relative;
              width: 100%;
              flex-grow: 1;
              .fill {
                height: 8px;
                background: #ffc531;
                border-radius: 4px;
                position: absolute;
                top: 0;
              }
              .base {
                height: 8px;
                border-radius: 4px;
                background: $NEUTRAL-30;
                width: 100%;
              }
            }
          }
        }
      }
    }
    .cardContainer {
      display: flex;
      gap: 16px;
      margin-top: 16px;
      flex-wrap: wrap;
      .card {
        flex: 1;
        border-radius: 12px;
        background: #fff;
        box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12),
          0px 8px 18px -6px rgba(24, 39, 75, 0.12);
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 12px;
        .cardIcon {
          border-radius: 50%;
          width: 60px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $NEUTRAL-10;
          box-shadow: 1px -1px 1.9px 0px rgba(0, 0, 0, 0.25) inset,
            0px -3px 8px 0px rgba(0, 0, 0, 0.25) inset,
            0px 4px 7.6px 0px rgba(0, 0, 0, 0.25);
        }
        .cardText {
          text-align: right;
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 4px;
          & > :first-child {
            color: $NEUTRAL-60;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
          }
          & > :last-child {
            color: $NEUTRAL-100;
            font-size: 32px;
            font-weight: 600;
            line-height: 40px;
          }
        }
      }
    }
    .classCardWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 12px;
    }
    .tabWrapper{
      display: flex;
      gap: 12px;
      margin-bottom: 16px;
    }
  }
}

@media screen and (max-width: 600px) {
  .container {
    padding-bottom: 60px;
    .topCard {
      .leftSection {
        .inputWrapper {
          width: 100% !important;
        }
      }
      .rightSection {
        display: none !important;
      }
    }
    .section {
      .classCardWrapper {
        grid-template-columns: 1fr;
      }
    }
  }
}
