@import "../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow: auto;
  height: 100%;
  .wrapper {
    @include padding-block(24, 12, 12px);
    @include padding-inline(24, 16, 16px);
    display: flex;
    flex-direction: column;
    gap: 16px;
    .header {
      display: flex;
      align-items: center;

      .title {
        flex-grow: 1;
        color: $NEUTRAL-10;
        display: flex;
        flex-direction: column;
        gap: 8px;

        h3 {
          font-family: $BASE-FONT-FAMILY;
          @include font-size(24, 16, 16px);
          font-style: normal;
          font-weight: 400;
          line-height: 30px;

          > span {
            font-weight: 600;
          }
        }

        > span {
          font-family: $BASE-FONT-FAMILY;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 142.857%;
        }
      }

      > button {
        background-color: $NEUTRAL-10;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        padding: 8px 16px;
        color: $NEW-PRIMARY2-BASE;
        border-radius: 8px;

        > span {
          font-family: $BASE-FONT-FAMILY;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 125%;
        }
      }
    }
    .tabWrapper {
      display: flex;
      gap: 12px;
      width: 100%;
      overflow: auto;
    }
    .searchBar {
      width: 280px;
    }
  }
}
