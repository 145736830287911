@import "../../.././theme/variables.scss";

.container {
  // display: flex;
  // align-items: center;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 20px;
  padding: 12px;
  border-radius: 8px;

  &.active {
    background-color: $NEUTRAL-10;
    box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12);

    button {
      color: $NEW-PRIMARY2-BASE;
      background: var(--New-Primary-Gradient-Color-Gradient-2, linear-gradient(98deg, #795EC6 0%, #8CC5DE 100.02%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &.inactive {
    background-color: $NEUTRAL-30;
    color: $NEUTRAL-60 !important;

    button {
      color: $NEUTRAL-60 !important;
    }
  }

  .logo {
    display: grid;
    place-items: center;

    >img {
      width: 33px;
      object-fit: contain;
    }

  }


  .details {
    display: flex;
    flex-direction: column;

    >span {
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 142.857%;
    }

    >p {
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 142.857%;
    }


  }

  .button {
    display: grid;
    place-items: center;

    >button {
      display: flex;
      align-items: center;
      gap: 6px;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 125%;
    }
  }
}