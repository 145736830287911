.container {
  display: flex;
  width: 984px;
  height: 531px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  background: #fff;
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    > :first-child {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
    > :last-child {
      cursor: pointer;
    }
  }
  .cardWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(221px, 221px));
    row-gap: 20px;
    column-gap: 16px;
    width: 100%;
    overflow: auto;
    .certificateCard {
      border-radius: 12px;
      background: #fdfdfd;
      box-shadow: 0px 2px 24px -2px rgba(24, 39, 75, 0.12),
        -3px 4px 4px -2px rgba(24, 39, 75, 0.13);
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 8px;
      cursor: pointer;
      > p {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .certificateCard:hover {
      border-radius: 12px;
      background: #faf7ff;
      box-shadow: 0px 2px 24px -2px rgba(24, 39, 75, 0.12),
        -3px 4px 4px -2px rgba(24, 39, 75, 0.13);
    }
  }
}
