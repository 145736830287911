@import "../../../../../theme/variables.scss";

.modalContainer {
  section {
    width: auto;
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 26px 24px;
    .headerModal {
      display: flex;
      justify-content: space-between;
      h1 {
        color: $NEUTRAL-90;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }
      .closeIcon {
        cursor: pointer;
      }
    }
    .main {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      width: 366px;
      .step {
        > p {
          color: var(--Neutral-70, #757575);
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px; /* 116.667% */
        }
      }
      .sectionWrapper{
        transition: 0.2s;
      }
      .sectionWrapper[is-disabled="true"] {
        transition: 0.2s;
        opacity: 0;
        z-index: -2;
        position: absolute;
        top: -1000px;
      }
      p {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        span {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }
      }
      .validateName {
        padding: 12px;
        display: flex;
        flex-direction: column;
        row-gap: 14px;
        border-radius: 12px;
        background: #fff;
        box-shadow: 0px 6px 24px 0px rgba(157, 179, 222, 0.25);
        & > :first-child {
          display: flex;
          flex-direction: column;
          gap: 4px;
          & > :first-child {
            color: $NEUTRAL-90;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 125% */
          }
          & > :last-child {
            color: var(--light-paragraph, #333);
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px; /* 116.667% */
          }
        }
        .validateInput{
          .errorMessage {
            color: #d1421a;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
          }
        }
        .validateInput[is-error="true"] {
          input {
            border-color: #d1421a;
            color: #d1421a;
          }
        }
      }
      .cardRating {
        padding: 12px;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        border-radius: 12px;
        background: #fff;
        box-shadow: 0px 6px 24px 0px rgba(157, 179, 222, 0.25);
        .rating {
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          h1 {
            color: var(--light-paragraph, #333);
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 125% */
          }
          .star {
            display: flex;
            justify-content: space-between;
            .iconStars {
              cursor: pointer;
            }
          }
        }
        .expression {
          display: flex;
          justify-content: space-between;
          color: #bababa;
          text-align: center;
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          span {
            width: 45px;
          }
        }
        .feedback {
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          h1 {
            color: var(--light-paragraph, #333);
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 125% */
          }
          textarea {
            height: 116px;
            resize: none;
            border-color: #e0e0e0;
            border-radius: 8px;
            padding: 12px;
            &:focus {
              outline-color: #9360a8;
            }
            &::placeholder {
              color: #c2c2c2;
            }
          }
        }
      }

      .review {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
      }

      .buttonModal {
        display: flex;
        gap: 12px;
        width: 100%;
        margin-top: 27px;
        div:nth-child(1) {
          cursor: pointer;
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 16px;
          border: 2px solid transparent;
          border-radius: 8px;
          background: linear-gradient(white, white) padding-box,
            linear-gradient(to right, #9360a8 35.02%, #46b7e9 125.87%)
              border-box;
          span {
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            background: var(
              --New-Primary-Gradient-Color-Gradient-2,
              linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%)
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        div:nth-child(2) {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;
          padding: 8px 16px;
          border-radius: 8px;
          background: var(
            --New-Primary-Gradient-Color-1,
            linear-gradient(137deg, #9360a8 35.02%, #46b7e9 125.87%)
          );
          span {
            color: white;
            font-weight: 600;
          }
        }
      }
    }
  }
}
