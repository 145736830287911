@import "../../../theme/variables.scss";

.container {
  display: flex;
  width: 379px;
  padding: 24px;
  flex-direction: column;
  gap: 16px;
  border-radius: 12px;
  background: #fff;
  & > :first-child {
    color: var(--Neutral-90, #404040);
    font-size: 16px;
    font-weight: 600;
    line-height: 20px; /* 125% */
  }
  .modalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    > img {
      width: 100px;
      height: 102px;
    }
    > p {
      color: var(--Neutral-90, #404040);
      text-align: center;

      font-size: 12px;

      font-weight: 400;
      line-height: 16px; /* 133.333% */
    }
  }
  .modalAction {
    width: 100%;
    > button {
      width: 100%;
    }
  }
}
