@import "../../../../theme/variables.scss";

.container {
  padding-block: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .section {
    .sectionTitle {
      border-bottom: 2px solid $NEUTRAL-30;
      padding-bottom: 9px;
      margin-bottom: 9px;
    }
    .aboutSection {
      display: flex;
      flex-direction: column;
      gap: 12px 80px;
      flex-wrap: wrap;
      height: 100px;
      align-content: flex-start;
      > div {
        display: flex;
        gap: 30px;
        width: max-content;
        & > :first-child {
          width: 130px;
        }
      }
    }
    .descSection {
      > p {
        white-space: pre-line;
        color: $NEUTRAL-90;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .aboutSection {
    height: auto !important;
  }
}
