@import "../../../../theme/variables.scss";

.container {
  padding: 32px 32px 32px 24px;
  & > :first-child {
    margin-left: -24px;
  }
  .titleSection {
    padding-bottom: 14px;
    border-bottom: 1px solid $NEUTRAL-40;
    margin-bottom: 29px;
    margin-top: 6px;
    > p {
      color: $NEUTRAL-100;
      font-size: 32px;
      font-weight: 600;
      line-height: 40px; /* 125% */
    }
  }
  .profileSection {
    margin-bottom: 22px;
    & > :first-child {
      color: $NEUTRAL-80;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
    & > :last-child {
      color: $NEUTRAL-100;
      font-size: 20px;
      font-weight: 400;
      line-height: 26px; /* 130% */
    }
  }
  .bodyWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .card {
      border-radius: 12px;
      background: #fff;
      box-shadow: 0px 5px 12px 0px rgba(128, 128, 128, 0.3);
      padding: 12px;
      .cardTitle {
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        > :last-child {
          cursor: pointer;
        }
        > p {
          color: $NEUTRAL-100;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px; /* 125% */
        }
      }
      .cardTitle[type="empty"] {
        > p {
          color: $NEUTRAL-60;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px; /* 125% */
        }
      }
      .cardBody {
        display: grid;
        grid-template-columns: 40% 1fr;
        gap: 12px;
        .leftSection {
          .contentWrapper {
            border-radius: 4px;
            background: $NEUTRAL-10;
            box-shadow: 0px 2px 4px 3px rgba(143, 143, 143, 0.25);
            padding: 0 8px 13px 8px;
            display: flex;
            flex-direction: column;
            gap: 26px;
            .file {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 12px;
              padding-top: 30px;
              & > :last-child {
                color: $NEUTRAL-80;
                font-size: 10px;
                font-weight: 400;
                line-height: 12px; /* 120% */
              }
              .icon[type="pdf"] {
                width: 93px;
                height: 114px;
              }
              .icon[type="excel"] {
                width: 134px;
                height: 123px;
              }
            }
            .buttonFile {
              width: 100%;
            }
          }
        }
        .rightSection {
          & > :first-child {
            > textarea {
              min-height: 166px;
            }
          }
          .cardAction {
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
            align-items: center;
            .score {
              display: flex;
              align-items: center;
              gap: 4px;
              & > :first-child {
                color: $NEUTRAL-80;
                font-size: 14px;
                font-weight: 500;
                line-height: 16px; /* 114.286% */
              }
              & > :last-child {
                width: 72px;
              }
            }
          }
        }
      }
      .cardBodyEmpty {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 11px;
        padding-bottom: 32px;
        & > :first-child {
          width: 185px;
          height: 185px;
          opacity: 0.5;
        }
        & > :last-child {
          color: $NEUTRAL-50;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .card {
      .cardBody {
        display: flex !important;
        flex-direction: column;
      }
    }
  }
}
