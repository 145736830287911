@import "../../../theme/variables.scss";

.container {
  background: #333639;
  height: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  box-shadow: 8px 0px 6px 0px rgba(0, 0, 0, 0.25) inset,
    -8px 0px 6px 0px rgba(0, 0, 0, 0.25) inset;
  padding-block: 26px;
  gap: 8px;
  user-select: none;

  .imageContainer {
    pointer-events: none;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 10px;
    overflow: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    > img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 15px;
    & > :first-child {
      width: 24px;
      visibility: hidden;
    }
    & > :last-child {
      cursor: pointer;
    }
    .page {
      display: flex;
      align-items: center;
      color: #ffff;
      gap: 10px;
      & > :first-child {
        cursor: pointer;
      }
      & > :last-child {
        cursor: pointer;
      }
      > p {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
      }
    }
  }
  .imageContainer::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  .loadingContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & > :first-child {
      width: 200px;
      height: 200px;
    }
    & > :last-child {
      color: $NEUTRAL-10;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }
}

.videoFullScreen {
  position: absolute !important;
  width: 100vw !important;
  height: 100vh !important;
  top: 0;
  left: 0;
  z-index: 1000;
  background: #000;
  overflow: hidden;
  > :first-child {
    width: 100vw;
    height: 100vh;
  }
}

.controlBar {
  position: absolute;
  display: none;
  z-index: 2;
  height: 100px;
  width: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  transition: 500ms;
  display: flex;
  justify-content: center;
  // align-items: center;
  padding-top: 22px;
  padding-inline: 15px;
  flex-direction: column;
  gap: 12px;
  .topControl {
    color: #ffff;
    display: flex;
    justify-content: space-between;
    .leftSection {
      display: flex;
      align-items: center;
      gap: 18px;
      > :first-child {
        cursor: pointer;
      }
      > p {
        font-size: 13.5px;
        user-select: none;
      }
    }
  }
  > input {
    // -webkit-appearance: none;
    // appearance: none;
    // background: #E0E0E0;
    width: 100%;
    cursor: pointer;
    height: 4px;
  }
}
.playIcon {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  cursor: pointer;
  > img {
    width: 82px;
    height: 82px;
  }
}

.videoBackground{
  background: rgba(0, 0, 0, 0.80);
}

.modalContainer {
  width: 100vw;
  height: 100vh;
  margin-bottom: 0;
  overflow: hidden;
}

@media screen and (max-width: 480px) {
  .container {
    padding-block: 8px !important;
  }
}
