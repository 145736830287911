@import "../../../theme/variables.scss";
@mixin headerTitleGradient() {
  color: $NEUTRAL-90;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  line-height: 50px;
  padding-top: 154px;
  padding-bottom: 44px;
  .span {
    color: $NEW-PRIMARY2-BASE;
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;
  }
}
@mixin headerTitleGradientR() {
  font-size: 20px !important;
  line-height: 26px;
  padding-top: 42px;
  .span {
    font-size: 20px;
    line-height: 26px;
  }
}
#benefit {
  scroll-margin: 70px;
}
.container {
  scroll-margin: 70px;
  background-color: $NEUTRAL-10;
}
.wrapperWheel {
  position: relative;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  background: linear-gradient(
    180deg,
    #c9c9e9 -8.32%,
    rgba(201, 201, 233, 0.51) 18.42%,
    rgba(201, 201, 233, 0.31) 63.98%,
    rgba(201, 201, 233, 0) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    @include headerTitleGradient();
  }
  .carouselWheel {
    width: auto;
    height: auto;
    display: flex;
    gap: 28px;
    justify-content: center;
    align-items: center;
    .leftIcon,
    .rightIcon {
      display: flex;
      align-items: center;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background-color: $NEUTRAL-10;
      padding: 10px;
      cursor: pointer;
      justify-content: center;
      .iconL,
      .iconR {
        width: 24px;
        height: 24px;
      }
      .iconL {
        transform: rotate(180deg);
      }
      &:hover {
        outline: 1px solid $NEW-PRIMARY2-BASE;
      }
    }
    p {
      width: 556px;
      color: $NEUTRAL-100;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      height: 60px;
    }
  }
  .wrapperImage {
    display: flex;
    align-items: flex-start;
    padding-top: 80px;
    justify-content: center;
    position: relative;
    height: 320px;
    overflow: hidden;
    img {
      object-fit: contain;
      width: 648px;
      height: 578px;
      cursor: pointer;
      transition: 0.5s ease-in-out;
      display: block;
      object-position: bottom;
    }
  }
}
@media (max-width: 768px) {
  .wrapperWheel {
    padding-inline: 10px !important;
    h1 {
      @include headerTitleGradientR();
    }
    p {
      font-size: 14px !important;
      max-width: 358px !important;
      width: auto !important;
      text-align: center !important;
      line-height: 20px !important;
    }
    .carouselWheel {
      gap: 12px;
      .leftIcon,
      .rightIcon {
        width: 24px;
        height: 24px;
        .iconL,
        .iconR {
          width: 16px;
          height: 16px;
          flex-shrink: 0;
        }
      }
    }
    .wrapperImage {
      height: 240px;
      img {
        width: 358px;
        height: 358px;
        padding: 10px !important;
        object-fit: contain !important;
      }
    }
  }
}
