@import "../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 78px);

  .expandedInfo {
    display: flex;
    flex-direction: column;
    margin-inline: auto;

    .infoRow {
      display: flex;
      align-items: center;

      > * {
        padding: 4px 8px;

        &:first-child {
          width: 103px;
        }
      }

      > span {
        &:first-child {
          font-family: $BASE-FONT-FAMILY;
          color: $NEUTRAL-70;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
        }

        &:last-child {
          font-family: $BASE-FONT-FAMILY;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 133.333%;
        }
      }
    }
  }

  .wrapper {
    @include padding-block(24, 12, 12px);
    @include padding-inline(24, 16, 16px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .wrapHeader {
      display: flex;
      flex-direction: column;
      .header {
        height: 85px;
        padding-inline: 0;
        position: relative;
      }
      .wrapperProfile {
        position: relative;
        top: -10px;
        width: 100%;
        min-height: 116px;
        height: auto;
        background-color: $NEUTRAL-10;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        box-shadow: 0px 2px 24px -2px rgba(24, 39, 75, 0.12),
          -3px 4px 4px -2px rgba(24, 39, 75, 0.13);
        padding: 16px;
        padding-left: 200px;
        .images {
          position: absolute;
          left: 24px;
          top: -50%;
          width: 160px;
          height: 160px;
          border-radius: 50%;
        }
        .containProfile {
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          > h1 {
            color: $NEUTRAL-90;
            font-size: 24px;
            font-weight: 600;
            line-height: 30px;
          }
          .descProfile {
            display: flex;
            .wrap {
              display: flex;
              flex-direction: column;
              > span {
                color: $NEUTRAL-60;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
              }
              > h2 {
                color: $NEUTRAL-90;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
              }
            }
            .divider {
              height: 44px;
              width: 0.5px;
              border: 0.9px dashed $NEUTRAL-60;
              margin-inline: 28px;
            }
          }
        }
      }
    }

    .table {
      border-radius: 12px;
      border: 1px solid $NEUTRAL-40;

      .student {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
    .backButton {
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;
      > span {
        font-weight: 600;
        background: $GRADIENT;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .icon {
        transform: rotate(180deg);
      }
    }
    .title {
      // height: 64px;
      display: -webkit-box;
      display: -moz-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
    }
    .wrapInstructor {
      display: flex;
      align-items: center;
      gap: 10px;
      .icon {
        flex-shrink: 0;
      }
      .names {
        h5 {
          overflow: hidden;
          color: $NEUTRAL-100;
          text-overflow: ellipsis;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
        span {
          overflow: hidden;
          color: $NEUTRAL-80;
          text-overflow: ellipsis;
          line-height: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .wrapper {
      .header {
        flex-direction: column;

        > button {
          width: 100%;
        }
      }
      .wrapHeader {
        .wrapperProfile {
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding-inline: 1rem;
          .images {
            position: absolute !important;
            top: -20%;
            left: 40%;
          }
          .containProfile {
            display: flex;
            flex-direction: column;
            padding-top: 26px;
            h1 {
              font-size: 20px;
            }
            .descProfile {
              display: flex;
              flex-direction: column;
              row-gap: 1rem;

              .divider {
                display: none;
              }
              .wrap {
                h2 {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}
