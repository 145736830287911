$NEUTRAL-10: #ffffff;
$NEUTRAL-20: #FAFAFA;
$NEUTRAL-30: #EDEDED;
$NEUTRAL-40: #E0E0E0;
$NEUTRAL-50: #C2C2C2;
$NEUTRAL-60: #9E9E9E;
$NEUTRAL-70: #757575;
$NEUTRAL-80: #616161;
$NEUTRAL-90: #404040;
$NEUTRAL-100: #0A0A0A;

$NEW-PRIMARY1-100: #05040B;
$NEW-PRIMARY1-90: #080712;
$NEW-PRIMARY1-80: #0F0F24;
$NEW-PRIMARY1-70: #171636;
$NEW-PRIMARY1-60: #1E1D49;
$NEW-PRIMARY1-BASE: #26255B;
$NEW-PRIMARY1-50: #3C3B91;
$NEW-PRIMARY1-40: #5D5CBD;
$NEW-PRIMARY1-30: #9392D3;
$NEW-PRIMARY1-20: #C9C9E9;
$NEW-PRIMARY1-10: #E6E6F5;

$NEW-PRIMARY2-100: #0E0910;
$NEW-PRIMARY2-90: #1D1221;
$NEW-PRIMARY2-80: #3C2645;
$NEW-PRIMARY2-70: #593866;
$NEW-PRIMARY2-60: #764A87;
$NEW-PRIMARY2-BASE: #9360A8;
$NEW-PRIMARY2-50: #A87EB9;
$NEW-PRIMARY2-40: #BE9FCB;
$NEW-PRIMARY2-30: #D5C0DD;
$NEW-PRIMARY2-20: #E9DEED;
$NEW-PRIMARY2-10: #F4EFF6;

$NEW-PRIMARY3-100: #03141B;
$NEW-PRIMARY3-90: #072837;
$NEW-PRIMARY3-80: #0D506D;
$NEW-PRIMARY3-70: #1479A4;
$NEW-PRIMARY3-60: #1AA1DB;
$NEW-PRIMARY3-BASE: #1AA1DB;
$NEW-PRIMARY3-50: #6DC7EE;
$NEW-PRIMARY3-40: #92D5F2;
$NEW-PRIMARY3-30: #B6E3F6;
$NEW-PRIMARY3-20: #DBF1FB;
$NEW-PRIMARY3-10: #EDF8FD;

$DANGER_MAIN: #ff3e13;

$DANGER-90: #64050B;
$DANGER-80: #790908;
$DANGER-70: #96160D;
$DANGER-60: #B22A12;
$DANGER-50: #D1421A;
$DANGER-40: #E3794F;
$DANGER-30: #F1A073;
$DANGER-20: #FAC8A3;
$DANGER-10: #FCE8D0;

$SUCCESS-90: #275F06;
$SUCCESS-80: #347109;
$SUCCESS-70: #498E0F;
$SUCCESS-60: #60AA15;
$SUCCESS-50: #7AC61C;
$SUCCESS-40: #A3DC4F;
$SUCCESS-30: #C1ED74;
$SUCCESS-20: #DDF9A4;
$SUCCESS-10: #F0FDD2;

$NEUTRAL-OVERLAY: #00000080;



$GRADIENT: linear-gradient(137deg, #9360A8 35.02%, #46B7E9 125.87%);
$COLOR-GRADIENT-2: linear-gradient(98deg, #795EC6 0%, #8CC5DE 100.02%);

$BASE-FONT-FAMILY: Poppins, sans-serif;

$BOX-SHADOW: 0px 6px 24px 0px rgba(158, 158, 158, 0.25);

@mixin width($max, $min, $minpx) {
  width: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin font-size($max, $min, $minpx) {
  font-size: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin line-height($max, $min, $minpx) {
  line-height: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin fluid-image-size($max, $min, $minpx) {
  width: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
  height: 100%;
}

@mixin top($max, $min, $minpx) {
  top: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin gap($max, $min, $minpx) {
  gap: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin padding($max, $min, $minpx) {
  padding: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin padding-top($max, $min, $minpx) {
  padding-top: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin padding-right($max, $min, $minpx) {
  padding-right: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin padding-bottom($max, $min, $minpx) {
  padding-bottom: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin padding-left($max, $min, $minpx) {
  padding-left: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin padding-block($max, $min, $minpx) {
  padding-block: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin padding-inline($max, $min, $minpx) {
  padding-inline: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}