@import "../../theme/variables.scss";

.container {
  padding: 16px 32px;
  .headWrapper {
    .headerSection {
      margin-top: 16px;
      display: flex;
      padding: 24px 24px 16px 24px;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background: var(
        --New-Primary-Gradient-Color-Gradient-2,
        linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%)
      );
      width: 100%;
      align-items: center;
      margin-bottom: 32px;
      .leftSection {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-self: stretch;
        justify-content: center;
        > :first-child {
          color: var(--Neutral-10, #fff);
          font-size: 24px;
          font-weight: 600;
          line-height: 30px; /* 125% */
        }
        > :last-child {
          color: var(--Neutral-10, #fff);
          font-size: 14px;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
      .rightSection {
        > img {
          width: 101.854px;
          height: 96px;
          flex-shrink: 0;
        }
      }
    }
  }
  .image {
    margin-top: 35px;
    > img {
      width: 100%;
    }
    width: 100%;
  }
  .body {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 76px;
    .section {
      display: flex;
      flex-direction: column;
      gap: 16px;
      > :first-child {
        color: var(--New-Primary-2-Primary-Base, #9360a8);
        font-size: 24px;
        font-weight: 600;
        line-height: 30px; /* 125% */
      }
      > :last-child {
        color: var(--Neutral-100, #232323);
        align-self: stretch;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        white-space: pre-line;
      }
    }
  }
}
