@import "../../../../../theme/variables.scss";

.container {
  .wrapper {
    @include padding-block(24, 12, 12px);
    @include padding-inline(24, 16, 16px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .wrapHeader {
      display: flex;
      flex-direction: column;
      .header {
        height: 85px;
        padding-inline: 0;
        position: relative;
      }
      .wrapperProfile {
        position: relative;
        top: -10px;
        width: 100%;
        min-height: 116px;
        height: auto;
        background-color: $NEUTRAL-10;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        box-shadow: 0px 2px 24px -2px rgba(24, 39, 75, 0.12),
          -3px 4px 4px -2px rgba(24, 39, 75, 0.13);
        padding: 16px;
        padding-left: 200px;
        .images {
          position: absolute;
          left: 24px;
          top: -50%;
          width: 160px;
          height: 160px;
          border-radius: 50%;
        }
        .containProfile {
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          > h1 {
            color: $NEUTRAL-90;
            font-size: 24px;
            font-weight: 600;
            line-height: 30px;
          }
          .descProfile {
            display: flex;
            .wrap {
              display: flex;
              flex-direction: column;
              > span {
                color: $NEUTRAL-60;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
              }
              > h2 {
                color: $NEUTRAL-90;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
              }
            }
            .divider {
              height: 44px;
              width: 0.5px;
              border: 0.9px dashed $NEUTRAL-60;
              margin-inline: 28px;
            }
          }
        }
      }
    }
    .backButton {
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;
      > span {
        font-weight: 600;
        background: $GRADIENT;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .icon {
        transform: rotate(180deg);
      }
    }
    .assignmentWrapper {
      display: flex;
      width: 100%;
      gap: 16px;
      flex-wrap: wrap;
      .section {
        border-radius: 12px;
        background: var(--Neutral-10, #fff);
        box-shadow: 0px 2px 24px -2px rgba(24, 39, 75, 0.12),
          -3px 4px 4px -2px rgba(24, 39, 75, 0.13);
        flex: 1;
        min-width: 300px;
        .sectionHeader {
          display: flex;
          align-items: center;
          padding: 20px 16px;
          gap: 12px;
          border-radius: 12px 12px 0px 0px;
          border-bottom: 1px solid var(--Neutral-30, #ededed);
          .iconWrapper {
            display: flex;
            padding: 8px;
            align-items: flex-start;
            gap: 10px;
            border-radius: 8px;
          }
          > :last-child {
            color: var(--Neutral-100, #232323);
            font-size: 16px;
            font-weight: 600;
            line-height: 24px; /* 150% */
          }
          .iconWrapper {
            display: flex;
            padding: 8px;
            align-items: flex-start;
            gap: 10px;
            border-radius: 8px;
          }
          .iconWrapper[type="tpm"] {
            border: 1px solid #d36a85;
            background: rgba(211, 106, 133, 0.2);
            color: #d36a85;
          }
          .iconWrapper[type="test"] {
            border: 1px solid var(--New-Primary-2-Primary-Base, #9360a8);
            background: var(--New-Primary-2-Primary-10, #f4eff6);
            color: #9360a8;
          }
          .iconWrapper[type="quiz"] {
            border-radius: 8px;
            border: 1px solid #3975ed;
            background: rgba(57, 117, 237, 0.2);
            color: #3975ed;
          }
        }
        .sectionBody {
          display: flex;
          padding: 11px 20px 20px 20px;
          flex-direction: column;
          gap: 12px;
          .assignment {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            > :first-child {
              color: var(--Neutral-100, #232323);
              font-size: 14px;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }
            > :last-child {
              color: var(--Neutral-100, #232323);
              font-size: 16px;
              font-weight: 500;
              line-height: 24px; /* 150% */
            }
          }
          .divider {
            height: 1px;
            width: 100%;
            border-bottom: 1px dashed #9e9e9e;
          }
        }
      }
    }
  }
}
