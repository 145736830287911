.containerMobile {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
}
.container{
  margin-top: 8px;

}
.single {
  display: flex;
  justify-content: flex-end;
}
.multiple {
  justify-content: space-between;
  display: flex;
}
