.background {
  height: 100vh;
  width: 100vw;
  z-index: 6;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

  >*{
    &:not(.outside) {
      z-index: 2;
    }
  }


}

.outside {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
}