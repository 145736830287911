@import "../../../theme/variables.scss";
.container {
  width: 100%;
  padding: 20px 32px;
  background-color: #171717;
  .copyrightMobile {
    display: none;
  }
}

.wrapperFooter {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
  color: $NEUTRAL-10;
  .skillsIcon {
    display: flex;
    align-items: center;
    gap: 5px;

    .iconGradient {
      width: 22.5px;
      height: 28px;
      object-fit: contain;
    }
  }

  .skillsId {
    font-size: 13.5px;
  }

  .copyright {
    color: $NEUTRAL-40;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
  }

  .socialMediaIcon {
    display: flex;
    gap: 23px;
  }
}

@media (max-width: 610px) {
  .container {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    .copyrightMobile {
      display: flex;
      justify-content: center;
      color: $NEUTRAL-40;
      font-size: 14px;
      text-align: center;
    }
  }
  .wrapperFooter {
    display: flex;
    justify-content: space-between;
  }

  .copyright {
    display: none !important;
  }
}