@import "../../../theme/variables.scss";

.container {
  width: 483px;
  max-width: 90vw;
  // height: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 16px;
  overflow: auto;
  text-align: center;

  >* {
    width: 100%;
  }

  .animation {
    width: 110px;
    aspect-ratio: 1;
    display: grid;
    place-items: center;
    overflow: visible;
    position: relative;

    .success {
      width: 200px;
      position: absolute;
      top: -40%;
      left: -40%;
    }

    >video {
      width: 110px;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: 8px;

    span {
      font-style: italic;
    }

    h4 {
      font-family: $BASE-FONT-FAMILY;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      color: $NEW-PRIMARY1-50;
    }

    p {
      font-family: $BASE-FONT-FAMILY;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 142.857%;
    }


  }
}