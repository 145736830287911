@import "../../../../theme/variables.scss";

.container {
    padding: 32px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .header {
        display: flex;
        flex-direction: column;
        gap: 8px;

        h3 {
            font-family: Poppins;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
        }

        span {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 114.286%;
        }
    }

    .divider {
        height: 1px;
        background-color: $NEUTRAL-40;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .section {
            display: flex;
            flex-direction: column;
            gap: 8px;

            >span {
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
            }

            .location {
                display: flex;
                align-items: center;
                gap: 12px;

                >img {
                    width: 72px;
                    object-fit: contain;
                }
            }

            .mapCard {
                padding: 12px;
                background-color: $NEUTRAL-10;
                box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
                display: flex;
                flex-direction: column;
                border-radius: 8px;
            }

            .schedules {
                display: flex;
                flex-direction: column;
                gap: 12px;
            }
        }
    }
}