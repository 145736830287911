.container {
  position: relative;
  .mapsInput {
    margin-bottom: 16px;
    position: absolute;
    z-index: 100;
    top: 10px;
    left: 10px;
    width: 250px;
  }
}
