.body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 78px);
  .container {
    display: flex;
    flex-direction: column;
    width: 350px;
    .topSection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo {
        display: flex;
        align-items: center;
        gap: 12px;
        > span {
          color: #26255b;
          font-size: 24px;
          font-weight: 400;
        }
        > img {
          width: 40px;
          height: 50px;
        }
      }
      .prakerjaLogo {
        width: 119px;
        height: 29px;
        flex-shrink: 0;
      }
    }
    .mainSection {
      display: flex;
      flex-direction: column;
      gap: 18px;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
      > img {
        width: 217px;
        height: 220px;
        flex-shrink: 0;
      }
      > p {
        color: var(--Neutral-90, #404040);
        font-size: 16px;
        font-weight: 600;
        line-height: 20px; /* 125% */
      }
    }
    .buttonSection {
      display: flex;
      justify-content: center;
    }
  }
}
