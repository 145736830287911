.container {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  > :first-child {
    cursor: pointer;
  }
}
.targetHovered {
  &:hover ~ .hovered {
    transform: scale(1);
  }
}
.hovered {
  background-color: #ffffff;
  position: absolute;
  transform: scale(0);
  flex-direction: column;
  display: flex;
  row-gap: 8px;
  width: 231px;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0px 2px 7px 0px #0000001f;
  z-index: 3;

  .headerToolTip {
    color: #484848;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
  }
  .description {
    font-size: 12px;
    color: #333333;
    line-height: 14px;
  }
}

.top {
  bottom: 180%;
  left: -170%;
  &::before {
    content: "";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 45%;
    border-width: 14px;
    border-style: solid;
    border-color: #ffffff transparent transparent transparent;
    filter: drop-shadow(-4px 0 3px rgba(217, 217, 217, 0.5));
  }
}
.left {
  left: 50px;
  &::before {
    content: "";
    position: absolute;
    right: 100%;
    top: 35%;
    border-width: 14px;
    border-style: solid;
    border-color: transparent #ffffff transparent transparent;
    filter: drop-shadow(-4px 0 3px rgba(217, 217, 217, 0.5));
  }
}
