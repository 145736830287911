@import "../../../../theme/variables.scss";

.container {
  padding: 22px 24px;
  .statusContainer {
    display: flex;
    justify-content: flex-end;
    .status {
      display: flex;
      padding: 2px 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 17px;
      width: max-content;
    }
    .success {
      background: var(--Success-Success-20, #ddf9a4);
      color: var(--Success-Success-70, #498e0f);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
    }
    .notSuccess {
      background: #ffcece;
      color: var(--Danger-Danger-60, #b22a12);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
    }
  }
  .headSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .headText {
    display: flex;
    margin-top: 16px;
    margin-bottom: 24px;
    > :first-child {
      color: #000;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px; /* 130% */
    }
    .bulletPoint {
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      > p {
        color: #000;
        font-size: 22px;
        font-weight: 600;
        line-height: 26px; /* 130% */
      }
    }
    > :last-child {
      color: $NEUTRAL-60;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px; /* 130% */
    }
  }
  .searchSection {
    margin-bottom: 16px;
  }
  .tableContainer {
    border-radius: 12px;
    box-shadow: 0px 2px 24px -2px rgba(24, 39, 75, 0.12),
      -3px 4px 4px -2px rgba(24, 39, 75, 0.13);
  }
}
