@import "../../../../../theme/variables.scss";

.container {
  .batchContainer {
    position: relative;
    .filterContainer {
      position: absolute;
      right: 0;
      // transform: translateX(-100%);
      gap: 13px;
      margin-bottom: 26px;
      margin-top: -62px;
      > :first-child {
        cursor: pointer;
        > p {
          color: var(--Danger-Danger-60, #b22a12);
          font-size: 16px;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }
      }

      .filterButton {
        display: flex;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        border: 1px solid var(--New-Primary-3-90, #072837);
        height: 34px;
        padding: 8px 16px;
        gap: 6px;
        width: max-content;
        cursor: pointer;
        position: relative;
        .filterCount {
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(-50%, -50%);
          display: flex;
          width: 20px;
          height: 20px;
          padding: 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 79px;
          background: var(--New-Primary-2-Primary-Base, #9360a8);
          > p {
            color: var(--Neutral-10, #fff);
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }
        }
        > p {
          color: var(--New-Primary-3-90, #072837);
          font-size: 14px;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
      .filterButton[is-active="false"] {
        .filterCount {
          opacity: 0;
        }
      }
      .filterButton[is-active="true"] {
        border: 1px solid var(--New-Primary-2-Primary-Base, #9360a8);
        > p {
          color: #9360a8;
        }
      }
    }
    .dropdownContainer[is-open="true"] {
      display: flex;
    }
    .dropdownContainer {
      display: none;
      width: 479px;
      padding: 24px;
      flex-direction: column;
      gap: 16px;
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translateY(calc(100% + 5px));
      border-radius: 12px;
      background: #fff;
      box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
        0px 18px 88px -4px rgba(24, 39, 75, 0.14);

      .dropdownHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > :last-child {
          color: var(--Danger-Danger-60, #b22a12);
          font-size: 16px;
          font-weight: 500;
          line-height: 24px; /* 150% */
          cursor: pointer;
        }
        > :first-child {
          color: var(--Neutral-90, #404040);
          font-size: 16px;
          font-weight: 600;
          line-height: 20px; /* 125% */
        }
      }
      .filterDateWrapper {
        > p {
          color: var(--Neutral-80, #616161);
          font-size: 14px;
          font-weight: 500;
          line-height: 16px; /* 114.286% */
          margin-bottom: 8px;
        }
        .filterDate {
          display: flex;
          align-items: center;
          gap: 8px;
          > div {
            flex: 1;
          }
        }
      }
      .filterAction {
        display: flex;
        gap: 12px;
        > button {
          flex: 1;
        }
      }
    }
    .batchWrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
  .topWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .mapLegendWrapper {
      display: flex;
      align-items: center;
      gap: 12px;
      .mapLegend {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        > p {
          color: #000;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }
      }
    }
  }
  .table {
    border-radius: 8px;
    overflow: visible;
  }
  .accordionHead {
    display: flex;
    align-items: center;
    gap: 8px;
    > :first-child {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      background: $NEW_PRIMARY2_BASE;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    > :last-child {
      > :first-child {
        color: #323232;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
      > :last-child {
        color: $NEUTRAL_70;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
  }
  .scheduleBody {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .scheduleCard {
      display: flex;
      padding: 12px 16px;
      justify-content: space-between;
      align-items: flex-start;
      border-radius: 8px;
      border: 1px solid $NEUTRAL_40;
      background: $NEUTRAL_10;
      .buttonWrapper {
        display: flex;
        align-items: center;
        gap: 12px;
      }
      .cardHead {
        > :first-child {
          color: $NEUTRAL_90;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
        > :last-child {
          color: $NEUTRAL_90;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }
  .tableContainer {
    overflow: visible;
    margin-top: 16px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 2px 24px -2px rgba(24, 39, 75, 0.12),
      -3px 4px 4px -2px rgba(24, 39, 75, 0.13);
  }
}

@media (max-width: 768px) {
  .container {
    .filterContainer {
      display: flex;
      justify-content: flex-end;
      position: relative !important;
      margin-top: 0 !important;
    }
  }
}
