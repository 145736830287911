.container {
  width: 100%;
  height: 100%;
  background: linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%);
  border-radius: 12px;
  padding: 24px;
  display: flex;
  border: 1px solid #e0e0e0;
  justify-content: space-between;
}
.wrapperLeft {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: flex-start;
  .buttonQuizIsLocked {
    display: flex;
    gap: 6px;
    background-color: transparent;
    border-radius: 8px;
    border: 2px solid var(--Neutral-50, #c2c2c2);
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    cursor: not-allowed;
    button {
      color: #c2c2c2;
      font-weight: 600;
      font-size: 1rem;
      cursor: not-allowed;
    }
  }
  .button[is-type="true"] {
    background-color: transparent;
    border: 2px solid #fff;
    padding: 8px 16px;
    height: 42px;
    color: #fff;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
  }
  .buttonIsFilled {
    cursor: not-allowed;
    background-color: transparent;
    border-radius: 8px;
    border: 2px solid var(--Neutral-50, #c2c2c2);
    color: #c2c2c2;
    padding: 8px 16px;
    font-weight: 600;
  }
  .buttonNormal {
    border-radius: 8px;
    border: 2px solid var(--Neutral-50, #ffffff);
    padding: 8px 16px;
    font-size: 1rem;
    font-weight: 600;
    color: #ffffff;
    margin-top: 20px;
  }
  .buttonQuiz {
    display: flex;
    padding: 8px 16px;
    border-radius: 8px;
    border: 2px solid var(--Neutral-10, #fff);
    button {
      font-weight: 600;
      font-style: 1rem;
      color: white;
    }
  }
}

.leftArea {
  width: 681px;
  height: 100%;
  display: flex;
  align-items: flex-start;
}

.header {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
}

.paragraph {
  line-height: 16px;
  text-align: start;
  color: #fff;
  font-size: 14px;
  font-weight: 400px;
}
.wrapperList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
}
.list {
  display: flex;
  align-items: flex-start;
  align-items: center;
}

.rightArea {
  display: flex;
  flex-direction: column;
  width: 214px;
  height: 214px;
  align-self: center;
  color: #fff;
  font-size: 1rem;
  align-items: center;
  row-gap: 5px;
  .imageBook {
    width: 214px;
    height: 214px;
  }
  .wrapScoreResult {
    display: flex;
    row-gap: 12px;
    flex-direction: column;
    align-items: center;
  }
}
.button {
  &:active {
    border-color: #d5d5d5 !important;
    color: #d5d5d5 !important;
  }
}
.descList {
  color: white !important;
  font-size: 14px;
  line-height: 16px;
}
@media (max-width: 645px) {
  .buttonQuiz {
    width: 100%;
    justify-content: center;
    border-radius: 8px;
    background: var(
      --New-Primary-Gradient-Color-1,
      linear-gradient(137deg, #9360a8 35.02%, #46b7e9 125.87%)
    );
  }
  .buttonNormal {
    width: 100%;
    border-radius: 8px;
    background: var(
      --New-Primary-Gradient-Color-1,
      linear-gradient(137deg, #9360a8 35.02%, #46b7e9 125.87%)
    );
  }
  .buttonQuizIsLocked {
    width: 100%;
    background-color: #ededed !important;
    border-color: #e0e0e0 !important;
  }
  .button[is-type="true"],
  .buttonIsFilled {
    width: 100% !important;
    border-radius: 8px;
    background: #e0e0e0 !important;
    color: #9e9e9e !important;
    font-weight: 600;
    border-color: transparent !important;
  }
  .container {
    display: flex;
    flex-direction: column;
    color: black;
    background: linear-gradient(98deg, #f4eff6 0%, #eaf5f9 100.02%);
    row-gap: 1rem;
  }
  .rightArea {
    order: 1;
    p {
      color: black;
    }
  }
  .leftArea {
    order: 2;
    width: 100%;
  }
  .button {
    width: 100%;
  }
  .list {
    line-height: 0;
  }
  .modalContainer {
    font-size: 12px;
    width: 320px !important;
    padding: 0 10px;
  }
  .buttonModal {
    gap: 12px !important;
  }
  .paragraph {
    p {
      color: black;
    }
  }
  .header {
    color: black;
  }
  .wrapperList {
    .arrowRight {
      color: #9360a8 !important;
    }
    .descList {
      color: black !important;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
.modalContainer {
  width: 436px;
  height: auto;
  border-radius: 12px;
  padding: 24px;
  background-color: #ffffff;

  .mainModal {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;
  }
  .modalImage {
    object-fit: contain;
    width: 280px;
    height: 182px;
  }
  .description h1 {
    font-size: 1rem;
    font-weight: 600;
    color: #404040;
    text-align: center;
  }
  p {
    font-size: 12px;
    line-height: 17px;
    text-align: center;
  }
  .buttonModal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    button {
      cursor: pointer;
      width: 188px;
      padding-inline: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 8px;
      background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #795ec6, #8cc5de) border-box;
      border: 2px solid transparent;
      font-size: 1rem;
      font-weight: 600;
    }
    .textButton {
      background: linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 1rem;
    }
    .buttonStart {
      background: linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%);
      border: none;
      color: white;
      .textButton {
        -webkit-text-fill-color: white;
        font-size: 1rem;
      }
    }
  }
}

.scoreText {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: linear-gradient(98deg, #9360a8 0%, #6d8bc8 100.02%);
  position: relative;
  &::after {
    content: attr(score);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: #a39bcb;
    position: absolute;
    bottom: 23px;
    right: 25px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.319);
    font-size: 3.3rem;
    font-weight: 800;
    box-shadow: 0px -1.592px 6.208px 0px rgba(0, 0, 0, 0.25) inset;
    filter: drop-shadow(0px 5.571px 5.571px rgba(0, 0, 0, 0.15));
    backdrop-filter: blur(1.591675043106079px);
  }
}
