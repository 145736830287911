@import "../../../theme/variables.scss";
.container {
  display: flex;
  justify-content: center;
  background-color: $NEUTRAL-10;
  padding-bottom: 42px;
}
.wrapperFaq {
  display: flex;
  gap: 34px;
  align-items: center;
  justify-content: center;
  padding-top: 125px;
  background-color: $NEUTRAL-10;
  padding: 0;
  width: 858px;
  scroll-margin: 70px;
  .textFaq {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    align-items: center;
    h1 {
      color: $NEUTRAL-100;
      font-size: 40px;
      font-weight: 600;
      line-height: 50px;
      span {
        color: $NEW-PRIMARY2-BASE;
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        line-height: 50px;
      }
    }
    p {
      color: $NEUTRAL-100;
      text-align: center;

      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      span {
        color: $NEW-PRIMARY2-BASE;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
  .images img {
    width: 151px;
    height: 211px;
  }
  .images img[alt="left"] {
    width: 93px;
    height: 181px;
  }
  .textFaqMobile {
    display: none;
  }
}
@media (max-width: 860px) {
  .wrapperFaq {
    padding-inline: 10px;
    .textFaq {
      width: 100%;
      display: flex;
      row-gap: 8px;
      h1 {
        font-size: 20px !important;
        span {
          font-size: 20px;
        }
      }
      p {
        font-size: 14px;
      }
    }
  }
}
@media (max-width: 600px) {
  .wrapperFaq {
    display: block !important;
    text-align: center;
    // padding-top: 0;
  }
  .images {
    display: inline-block !important;
    margin: auto;
    padding-inline: 8px;
    img[alt="left"] {
      width: 36px !important;
      height: 71px !important;
    }
    img {
      width: 58px !important;
      height: 81px !important;
    }
  }
  .textFaq {
    display: none !important;
  }
  .textFaqMobile {
    display: block !important;
    width: 100%;
    h1 {
      color: $NEUTRAL-100;
      font-size: 20px !important;
      font-weight: 600;
      line-height: 26px;
      padding-bottom: 8px;
      span {
        color: $NEW-PRIMARY2-BASE;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px; /* 125% */
      }
    }
    p {
      color: $NEUTRAL-100;
      text-align: center;

      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      span {
        color: $NEW-PRIMARY2-BASE;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
}
