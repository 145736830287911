.container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.layout {
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  .doodle {
    width: 100%;
    position: relative;
    .topCard {
      // padding: 24px 24px 16px 24px;
      &::after {
        content: "";
        background-image: url("../../../../assets/Images/clayCardDoodle.png");
        background-repeat: no-repeat;
        background-size: contain;
        width: 227px;
        height: 227px;
        flex-shrink: 0;
        position: absolute;
        right: 0;
        top: 80px;
      }
      .leftSection {
        color: white;
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        position: relative;
        .title {
          color: var(--Neutral-10, #fff);
          font-family: Poppins;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px;
        }
        .desc {
          color: var(--Neutral-10, #fff);
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
      .rightSection {
        img {
          position: absolute;
          right: 0;
          top: -16px;
        }
      }
    }
  }
  .tabsHeader {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    .notFound {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      // padding-top: 56px;
    }
    .wrapperCard {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      .card {
        // width: auto;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        height: auto;
        width: 307px;
        height: auto;
        border: 1px solid #ededed;
        .images {
          width: 306px;
          height: 250px;
          object-fit: cover;
          flex-shrink: 0;
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
        }
        .describeClass {
          padding: 0;
          display: flex;
          width: 100%;
          height: auto;
          .percentage {
            display: flex;
            flex-direction: column;
            width: 91px;
            height: 150px;
            border-radius: 0px 0px 0px 8px;
            background: var(--New-Primary-2-Primary-Base, #9360a8);
            align-items: center;
            justify-content: center;
            padding: 8px;
            row-gap: 8px;
            .indicator {
              width: 53px;
              height: 53px;
              flex-shrink: 0;
              position: relative;
              &::before {
                content: attr(percentage) "%";
                // content: "80";
                // width: 33px;
                // height: 33px;
                top: 50%;
                left: 53%;
                transform: translate(-50%, -50%);
                color: white;
                // color: white;
                position: absolute;
                font-size: 12px;
                // background-color: salmon;
                display: flex;
                align-items: center;
                font-weight: 600;
              }
            }
            .indicator[isLocked="true"] {
              &::before {
                left: 65%;
              }
            }
            div:nth-child(2) {
              display: flex;
              flex-direction: column;
              align-items: center;
              // background-color: salmon;
              justify-content: center;
              text-align: center;
              p {
                color: #fff;
                font-size: 10px;
                font-weight: 400;
                line-height: 12px;
                width: 75px;
              }
            }
          }

          .classDescription {
            padding: 8px 12px 12px 12px;
            display: flex;
            row-gap: 1rem;
            flex-direction: column;
            background-color: rgb(255, 255, 255);
            border-radius: 0px 0px 8px 0px;
            background: var(--Neutral-10, #fff);
            // height: 100px;
            justify-content: space-between;
            width: 100%;
            .text {
              display: flex;
              flex-direction: column;
              row-gap: 8px;
              .typeClass {
                display: flex;
                gap: 4px;
                align-items: center;
                div:nth-child(1) {
                  display: flex;
                  border-radius: 4px;
                  padding: 4px 8px;
                  background-color: purple;
                  align-items: center;
                  justify-content: center;
                  span {
                    color: var(--Neutral-10, #fff);
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 12px;
                  }
                }
                div:nth-child(2) {
                  display: flex;
                  border-radius: 4px;
                  padding: 4px 8px;
                  background-color: #c1ed74;
                  align-items: center;
                  justify-content: center;
                  span {
                    color: var(--Neutral-100, #232323);
                    font-family: Poppins;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 12px;
                  }
                }
              }
              .textEllipsis {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* Jumlah baris yang ingin Anda tampilkan */
                overflow: hidden;
                color: #000;
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
              }
              > p {
                color: var(--Neutral-60, #9e9e9e);
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px; /* 116.667% */
              }
            }
            .buttonCard {
              cursor: pointer;
              display: flex;
              padding: 8px 16px;
              border-radius: 8px;
              align-items: center;
              justify-content: center;
              color: white;
              background: var(
                --New-Primary-Gradient-Color-Gradient-2,
                linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%)
              );
              span {
                color: #fff;
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
              }
            }
            .buttonCard[isLocked="true"] {
              // display: flex;
              // gap: 6px;
              // background: #e0e0e0;
              // color: #9e9e9e;
              // cursor: not-allowed;
              .lockedClass {
                display: flex;
                gap: 6px;
                align-items: center;
                // span {
                //   color: #9e9e9e;
                // }
              }
            }
          }
        }
      }
    }
  }
  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    & > :first-child {
      width: 200px;
      height: 200px;
    }
    & > :last-child {
      color: var(--New-Primary-2-Primary-Base, #9360a8);
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 100% */
    }
  }
}

@media (max-width: 768px) {
  .doodle {
    .topCard {
      &::after {
        display: none;
      }
      .leftSection {
        .title {
          color: var(--Neutral-10, #fff);
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
        .desc {
          color: var(--Neutral-10, #fff);
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
      }
      .rightSection {
        display: none;
      }
    }
  }
}
