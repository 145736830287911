.select::-ms-expand {
  display: none;
}
.select {
  transition: 0.6s;
  cursor: pointer;
  &:hover {
    outline: 0.5px solid #a83bd3;
    transition: 1s;
  }
}
.mailPhone {
  .datePicker {
    row-gap: 10px !important;
  }
}
.arrowDropdown {
  position: absolute;
  right: 10px;
  bottom: 10px;
  transform: rotate(90deg);
}
.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Tambahan gaya lainnya */
}

.layout {
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.wrapper {
  width: 100%;
  height: 260px;
  position: relative;
  justify-content: center;
  align-items: center;
  .doodle {
    width: 100%;
    .topCard {
      display: flex;
      justify-content: space-between;
      .leftSection {
        color: white;
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        .title {
          color: var(--Neutral-10, #fff);
          font-size: 24px;
          font-weight: 600;
          line-height: 30px;
          padding: 32px 16px;
        }
      }
      .rightSection {
        img {
          width: 93px;
          height: 98px;
          object-fit: contain;
        }
      }
    }
  }
  .profile {
    width: 191px;
    height: 191px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    img {
      border-radius: 50%;
      object-fit: cover;
      width: 191px;
      height: 191px;
      flex-shrink: 0;
      cursor: pointer;
      box-shadow: 0 10px 10px #d9d9d99c;
    }
  }
}
.editProfile {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  .mailPhone {
    display: flex;
    gap: 18px;
    width: 100%;
    div {
      width: 100%;
      label {
        color: #616161;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        row-gap: 6px;
        .select {
          display: flex;
          width: 100%;
          border-radius: 8px;
          height: 44px;
          border-color: #e0e0e0;
          padding: 8px;
          color: #c2c2c2;
          font-size: 1rem;
          &:focus {
            outline: 0.6px solid #9360a8;
          }
        }
        .select[isChoose="true"] {
          color: black !important;
        }
      }
    }
    div:nth-child(2) {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      label {
        color: var(--Neutral-80, #616161);
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }
  .buttonProfile {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    button {
      cursor: pointer;
      border-radius: 8px;
      display: inline-flex;
      height: 42px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      flex-shrink: 0;
      border-radius: 8px;
      background: var(
        --New-Primary-Gradient-Color-Gradient-2,
        linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%)
      );
      span {
        color: white;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
      &:hover {
        background: #9360a8;
      }
    }
    button[isDisabled="true"] {
      cursor: not-allowed;
      background: var(--Neutral-40, #e0e0e0);
      span {
        color: var(--Neutral-60, #9e9e9e);
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
}
// MODAL
.wrapperModal {
  width: 100%;
  .wrapperPhoto {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    .headerClose {
      justify-content: space-between;
      h1 {
        font-size: 1rem;
        font-weight: 600;
        line-height: 20px;
      }
      .iconClose {
        cursor: pointer;
      }
      display: flex;
      gap: 16px;
    }
    div:nth-child(2) {
      img {
        object-fit: cover;
        width: 100%;
        height: 350px;
        border-radius: 8px;
      }
    }
  }
  padding: 24px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 374px;
  .desc {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    h1 {
      color: var(--Neutral-90, #404040);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 125% */
    }
    p {
      color: var(--light-paragraph, #333);
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px; /* 141.667% */
      letter-spacing: 0.24px;
    }
  }
  .buttonModal {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    button:nth-child(1) {
      border-radius: 8px;
      border: 2px solid var(--New-Primary-Gradient-Color-Gradient-2, #795ec6);
      display: flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      flex: 1 0 0;
      background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #795ec6, #8cc5de) border-box;
      border: 2px solid transparent;
      span {
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 125% */
        background: var(
          --New-Primary-Gradient-Color-Gradient-2,
          linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    button:nth-child(2) {
      display: flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      border-radius: 8px;
      background: var(
        --New-Primary-Gradient-Color-Gradient-2,
        linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%)
      );
      width: 157px;
      span {
        color: #fff;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 125% */
      }
    }
  }
}
.wrapperModal[isEdit="true"] {
  width: 460px;
  .buttonModal {
    button:nth-child(2) {
      width: auto;
    }
  }
}
@media (max-width: 840px) {
  .wrapper {
    .profile {
      width: 141px;
      height: 141px;
      img {
        width: 141px;
        height: 141px;
      }
    }
    .doodle {
      .topCard {
        .leftSection {
          .title {
            font-size: 20px;
            padding: 8px 12px;
          }
        }
        .rightSection {
          width: 93px;
          height: 98px;
          img {
            width: 43px;
            height: 58px;
            object-fit: contain;
          }
        }
      }
    }
  }
}
