@import "../../../theme/variables.scss";

.container {
  width: 100%;
  .labelWrapper {
    margin-bottom: 8px;
  }
  .inputContainer {
    position: relative;
    .selectContainer {
      position: relative;
      .select {
        border-radius: 8px 8px 0px 0px;
        border: 1px solid $NEUTRAL-40;
        border-bottom: 0;
        padding: 12px;
        background: #fff;
        box-shadow: 0px 1px 4px 0px rgba(121, 121, 121, 0.12);
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        & > :first-child {
          color: $NEUTRAL-100;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
        }
        .dropdownIcon {
          color: $NEUTRAL-50;
          transition: 0.5s;
        }
        .dropdownIcon[is-rotate="true"] {
          rotate: 180deg;
        }
      }
      .selectItem {
        position: absolute;
        width: 100%;
        border-radius: 0px 0px 8px 8px;
        background: #fff;
        box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08),
          0px 6px 12px -6px rgba(24, 39, 75, 0.12);
        padding: 12px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        gap: 8px;
        z-index: 2;
        border-top: 1px solid $NEUTRAL-40;
        .item {
          border-bottom: 1px solid #e0e0e0;
          padding-bottom: 8px;
          .itemText {
            color: $NEUTRAL-100;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            &:hover {
              color: $NEW-PRIMARY2-BASE;
            }
          }
        }
        .item:last-child {
          border: none;
          padding-bottom: 0;
        }
      }
    }
    .inputContainer {
      width: 100%;
      > .input {
        width: 100%;
        resize: vertical;
        padding: 8px 12px;
        border: 1px solid $NEUTRAL-40;
        border-radius: 0px 0px 8px 8px;
        min-height: 77px;
        font-size: 16px;
        &:focus {
          outline: none;
          border: 1px solid #9360a8;
          // border-color: #9360a8;
        }
      }
    }
    .deleteIcon {
      position: absolute;
      cursor: pointer;
      top: 25px;
      right: -24px;
      transform: translate(0, -50%);
    }
  }
}
