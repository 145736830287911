.container {
  display: flex;
  width: 389px;
  padding: 24px;
  flex-direction: column;
  gap: 16px;
  border-radius: 12px;
  background: #fff;
  > :first-child {
    color: var(--Neutral-90, #404040);
    font-size: 16px;
    font-weight: 600;
    line-height: 20px; /* 125% */
    text-align: left;
  }
  .modalBody {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    > :first-child {
      width: 150px;
      height: 150px;
    }
    > :last-child {
      color: var(--Neutral-90, #404040);
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      > span {
        font-style: italic;
      }
    }
  }
}
