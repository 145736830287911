.container {
  border-radius: 12px;
  background: #fff;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 436px;
  > img {
    width: 152px;
    height: 152px;
    flex-shrink: 0;
  }
  .modalBody {
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 16px;
    > :first-child {
      color: var(--Neutral-90, #404040);
      font-size: 16px;
      font-weight: 600;
      line-height: 20px; /* 125% */
    }
    > :last-child {
      color: var(--light-paragraph, #333);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      > span {
        color: var(--Danger-Danger-60, #b22a12);
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }
  .modalAction {
    width: 100%;
    .checkboxWrapper {
      display: flex;
      align-items: center;
      gap: 4px;
      > p {
        color: var(--light-paragraph, #333);
        font-size: 12px;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
      }
    }
    .buttonWrapper {
      width: 100%;
      display: flex;
      gap: 12px;
      margin-top: 18px;
      > button {
        flex: 1 1;
      }
    }
  }
}
