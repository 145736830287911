@import "../../../theme/variables.scss";

.container {
  @include padding-block(16, 12, 12px);
  @include padding-inline(24, 16, 16px);
  border: 1px solid $NEUTRAL-40;
  border-radius: 8px;
  max-height: 56px;
  transition: max-Øheight .3s ease;

  &.expand {
    max-height: 300vh;
  }

  .header {
    display: flex;
    align-items: center;
    gap: 16px;

    >h4 {
      font-family: $BASE-FONT-FAMILY;
      // font-size: 16px;
      @include font-size(16, 14, 14px);
      font-style: normal;
      font-weight: 600;
      line-height: 125%;
      color: $NEUTRAL-90;
      flex-grow: 1;
    }
  }

  .content {

    .section {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-block: 20px;

      &:not(:nth-last-child(2)){
        border-bottom: 1px solid $NEUTRAL-40;
      }

      >h3 {
        font-family: $BASE-FONT-FAMILY;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }

      
    }

    .closeWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
}