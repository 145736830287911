@import "../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 78px);

  .expandedInfo {
    display: flex;
    flex-direction: column;
    margin-inline: auto;

    .infoRow {
      display: flex;
      align-items: center;

      >* {
        padding: 4px 8px;

        &:first-child {
          width: 103px;
        }
      }


      >span {
        &:first-child {
          font-family: $BASE-FONT-FAMILY;
          color: $NEUTRAL-70;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          flex-shrink: 0;
        }

        &:last-child {
          font-family: $BASE-FONT-FAMILY;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height:auto;

          overflow: hidden;
          display: -webkit-box;
          line-clamp: 1;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
      


        }
      }


    }
  }

  .wrapper {
    @include padding-block(24, 12, 12px);
    @include padding-inline(24, 16, 16px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .header {
      display: flex;
      align-items: center;
  
      .title {
        flex-grow: 1;
        color: $NEUTRAL-10;
        display: flex;
        flex-direction: column;
        gap: 8px;
  
        h3 {
          font-family: $BASE-FONT-FAMILY;
          @include font-size(24, 16, 16px);
          font-style: normal;
          font-weight: 400;
          line-height: 30px;
  
          >span {
            font-weight: 600;
          }
        }
  
        >span {
          font-family: $BASE-FONT-FAMILY;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 142.857%;
        }
      }
  
      .img {
        width: 93px;
        object-fit: contain;
      }
    }

    .tabs {
      display: flex;
      align-items: center;
      gap: 12px;
      overflow-x: auto;
      flex-shrink: 0;
    }

    .table {
      border-radius: 12px;
      border: 1px solid $NEUTRAL-40;

      .instructor {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .invoiceNumber {
        cursor: pointer;

        &:hover {
          color: $NEW-PRIMARY2-BASE;
        }
      }
    }
  }


}

@media screen and (max-width: 768px) {

  .container {
    .wrapper {

      .header {
        flex-direction: column;
        flex-shrink: 0;
  
      }
    }
  }
}