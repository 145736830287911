.xsRegular {
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
}

.xsMedium {
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
}

.xsSemibold {
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
}

.xsBold {
  font-size: 10px;
  line-height: 14px;
  font-weight: 700;
}

.sRegular {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.sMedium {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}

.sSemibold {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
}

.sBold {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
}

.mRegular {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.mMedium {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.mSemibold {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

.mBold {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

.lRegular {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.lMedium {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.lSemibold {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.lBold {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.h4Regular {
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
}

.h4Medium {
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
}

.h4Semibold {
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
}

.h4Bold {
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
}

.h3Regular {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
}

.h3Medium {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
}

.h3Semibold {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
}

.h3Bold {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

.h2Regular {
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;
}

.h2Medium {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
}

.h2Semibold {
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
}

.h2Bold {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
}

.h1Regular {
  font-size: 40px;
  line-height: 50px;
  font-weight: 400;
}

.h1Medium {
  font-size: 40px;
  line-height: 50px;
  font-weight: 500;
}

.h1Semibold {
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
}

.h1Bold {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
}
