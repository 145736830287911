@import "../../../theme/variables.scss";
@mixin textQuestion() {
  color: $NEW-PRIMARY2-BASE;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
}
@mixin textAnswer() {
  color: $NEUTRAL-90;
  font-size: 14px;
  line-height: 24px;
}
.wrapperFaqPageLayout {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  .buttonBack {
    .link {
      width: max-content;
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;
      .arrowLeft {
        transform: rotate(180deg);
      }
      span {
        background: $COLOR-GRADIENT-2;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
  .wrapperText {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div:nth-child(1) {
      color: $NEUTRAL-10;
      h1 {
        font-size: 32px;
        font-weight: 500;
      }
      p {
        font-size: 14px;
      }
    }
    div:nth-child(2) {
      img {
        width: 96px;
        height: 96px;
        flex-shrink: 0;
      }
    }
  }
  .faqsText {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    h2 {
      @include textQuestion();
    }
    p {
      @include textAnswer();
    }
    ol,
    ul {
      padding-left: 1rem;
      @include textAnswer();
    }
  }
  
  .wrapperAccordion {
    padding: 24px;
    max-width: 1440px;
    width: 100%;
    border-radius: 16px;
    background: $NEUTRAL-10;
    box-shadow: 0px 14px 64px -4px rgba(24, 39, 75, 0.12),
      0px 8px 22px -6px rgba(24, 39, 75, 0.12);
    main {
      .contain {
        .isClickIndicator {
          display: grid;
          grid-template-columns: auto auto;
          gap: 1rem;
          justify-content: space-between;
          align-items: start;
          cursor: pointer;
        }
        p {
          color: $NEUTRAL-90;
          text-overflow: ellipsis;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          transition: 0.2s;
        }
        p[active="true"] {
          color: $NEW-PRIMARY2-BASE;
        }
        img {
          width: 20px;
          height: 20px;
        }
        img[active="true"] {
          transform: rotate(-180deg);
          transition: 0.3s;
          color: $NEW-PRIMARY2-BASE;
        }
        img[active="false"] {
          transform: rotate(0);
          transition: 0.3s;
        }
        .answer {
          color: $NEUTRAL-90;
          cursor: text;
          font-size: 14px;
          font-weight: normal;
          // white-space: pre-wrap;
          animation: fadeIn 1.6s;
          line-height: 24px;
          ol,
          ul {
            padding-inline: 1rem;
          }
        }
      }

      .lineSeperator {
        width: 100%;
        border: 1px dashed $NEW-PRIMARY2-BASE;
        margin-block: 1rem;
      }
    }
  }
}
@media (max-width: 768px) {
  .wrapperFaqPageLayout {
    padding: 1rem 1rem;
    .wrapperText {
      div:nth-child(1) {
        color: $NEUTRAL-10;
        h1 {
          font-size: 22px !important;
          font-weight: 500;
        }
        p {
          font-size: 12px;
        }
      }
      div:nth-child(2) {
        img {
          width: 66px;
          height: 66px;
          flex-shrink: 0;
        }
      }
    }
    .wrapperAccordion {
      main {
        .contain {
          gap: 10px;
          p {
            font-size: 14px;
            line-height: 20px;
          }
          img {
            height: 16px;
            width: 16px;
          }
          .answer {
            font-size: 12px;
            line-height: 1rem;
          }
        }
        .lineSeperator {
          margin-block: 14px;
        }
      }
    }
  }
  .faqsText {
    h2 {
      font-size: 1.2rem !important;
      line-height: 24px !important;
    }
  }
}
