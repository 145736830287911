@import "../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  text-align: left;
  @include gap(32, 24, 24px);
  // z-index: 10;

  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    > h3 {
      font-family: $BASE-FONT-FAMILY;
      @include font-size(24, 20, 20px);
      font-style: normal;
      font-weight: 600;
    }

    > span {
      font-family: $BASE-FONT-FAMILY;
      @include font-size(16, 14, 14px);
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      color: $NEUTRAL-70;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    .each {
      display: flex;
      flex-direction: column;
      @include padding-block(32, 24, 24px);
      @include gap(20, 16, 16px);
      border-top: 1px solid $NEUTRAL-40;

      .eachHeader {
        display: flex;
        flex-direction: column;
        gap: 8px;

        > h4 {
          font-family: $BASE-FONT-FAMILY;
          @include font-size(16, 14, 14px);
          font-style: normal;
          font-weight: 600;
          line-height: 125%;
        }

        > span {
          font-family: $BASE-FONT-FAMILY;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: $NEUTRAL-70;
        }
      }

      .grid {
        display: grid;
        // grid-auto-flow: column;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
      }
      .inputWithCheckbox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        > :first-child {
          flex: 1;
        }
        > :last-child {
          display: flex;
          align-items: center;
          gap: 7px;
          margin-top: 23px;
        }
      }

      .schedulesWrapper,
      .infoWrapper,
      .competencyWrapper,
      .targetWrapper,
      .configurationWrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .scheduleCard {
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 32px;
          border-radius: 12px;
          border: 1px solid $NEUTRAL-40;

          .scHeader {
            display: flex;
            align-items: center;
            gap: 16px;
            > h4 {
              flex-grow: 1;
              font-family: $BASE-FONT-FAMILY;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 150%;
            }
          }
        }

        .competencyUnitsWrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .cUnit {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 12px;
          }
        }

        .targetParticipantsWrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
      }

      .sessionsWrapper {
        border: 1px solid $NEUTRAL-40;
        position: relative;
        margin-top: 38px;
        padding-bottom: 32px;
        @include padding-inline(32, 16, 16px);
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-radius: 0 0 12px 12px;

        > *:first-child {
          background-color: $NEUTRAL-10;
          position: sticky;
          top: 0;
          padding-top: 32px;
          bottom: 100%;
          width: calc(100% + 66px);
          left: 0;
          margin-top: -70px;
          margin-left: -33px;
          z-index: 1;

          .sideActions {
            position: absolute;
            top: 156px;
            left: calc(100% + 16px);
          }
        }

        > h4 {
          font-family: $BASE-FONT-FAMILY;
          // font-size: 20px;
          @include font-size(20, 16, 16px);
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
        }

        .sessionHeader {
          display: flex;
          align-items: center;
          gap: 16px;
          position: relative;

          .autoScroll {
            display: hidden;
            position: absolute;
            top: 0;
          }

          > h4 {
            font-family: $BASE-FONT-FAMILY;
            // font-size: 20px;
            @include font-size(20, 16, 16px);
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            flex-grow: 1;
          }
        }

        .sessionFields {
          display: flex;
          flex-direction: column;

          .section {
            padding-block: 30px;
            display: flex;
            flex-direction: column;
            gap: 8px;

            .sectionHeader {
              display: flex;
              align-items: center;
              gap: 16px;

              > *:first-child {
                flex-grow: 1;
              }

              > button {
                display: grid;
                place-items: center;
              }
            }

            &:first-child {
              padding-top: 0;
            }

            &:last-child {
              padding-bottom: 0;
            }

            &:not(:last-child) {
              border-bottom: 1px solid $NEUTRAL-40;
            }

            &.subject {
              gap: 20px;
            }

            .sectionTitle {
              color: #000;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 125%;

              > span {
                color: $DANGER_MAIN;
              }
            }

            .sectionFields {
              display: flex;
              flex-direction: column;
              gap: 16px;

              > * {
                width: 100%;
              }
            }
          }
        }
      }

      .popupQuiz {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .content {
      .grid {
        grid-template-columns: 1fr !important;
      }

      .sideActions {
        left: auto !important;
        right: 0;
        background-color: $NEUTRAL-10;
      }

      .each {
        .schedulesWrapper {
          .scheduleCard {
            padding: 0;
            border: none;
          }
        }
      }
    }
  }
}
