@import "../../../theme/variables.scss";

.container {
  position: relative;
  width: 100%;
  height: 100%;
  .Header {
    display: flex;
    justify-content: space-between;
    > :first-child {
      margin-block: 24px;
      display: flex;
      gap: 16px;
      flex-direction: column;
      .titleHead {
        color: $NEUTRAL-90;
        font-size: 24px;
        font-weight: 500;
        line-height: 24px; /* 100% */
      }
      .desc {
        color: $NEUTRAL-90;
        font-size: 16px;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
      }
    }
    > :last-child {
      > img {
        height: 102px;
      }
    }
  }
  .content {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2.5rem;
    position: absolute;
    z-index: 99999;
  }

  .button {
    border-radius: 9999px;
    padding: 0.75rem 1rem;
    background-color: var(--primary);
    color: var(--white);
  }

  .button:hover {
    background-color: var(--primary-dark);
  }

  .certificateWrapper {
    font-family: Helvetica;
    width: calc(3408px / 3);
    height: calc(2380px / 3);
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
  }

  .certificateText {
    font-family: Helvetica;
    position: absolute;
    text-align: center;
    z-index: 2;
  }
  .courseName {
    width: 680px;
    left: 300px;
    font-size: 20px;
    font-weight: 700;
  }
  .courseName[predicate="true"] {
    top: 350px;
  }
  .courseName[predicate="false"] {
    top: 360px;
  }
  .certificateName {
    width: 680px;
    left: 300px;
    font-size: 32px;
    font-style: italic;
  }
  .certificateName[predicate="true"] {
    top: 400px;
  }
  .certificateName[predicate="false"] {
    top: 450px;
  }
  .certificateDate {
    position: absolute;
    top: 530px;
    left: 300px;
    width: 680px;
    text-align: center;
  }

  .imageContainer {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .qrContainer {
    position: absolute;
    top: 550px;
    left: 200px;
    width: 150px;
    z-index: 2;
  }
  .customButton {
    border-radius: 9999px;
    background-color: $NEUTRAL-40;
    color: $NEUTRAL-100;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .customButton:hover {
    background-color: $NEUTRAL-50;
  }
  .scoreTextContainer {
    position: relative;
    z-index: 2;
    top: 200px;
    display: flex;
    justify-content: center;
    .competenceTitle {
      color: $NEUTRAL-100;
      font-size: 20px;
      width: 800px;
      white-space: pre-wrap;
      text-align: center;
    }
  }
}
.competenceUnitContainer {
  position: relative;
  z-index: 2;
  top: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .competence {
    display: flex;
    width: 800px;
    gap: 20px;
    > p {
      text-align: left;
      font-size: 20px;
      color: $NEUTRAL-100;
      > span {
        font-weight: 700;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .container {
    .content {
      width: auto !important;
    }
  }
}
