@import "../../../../../theme/variables.scss";

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  & > :first-child {
    width: 200px;
    height: 200px;
  }
  & > :last-child {
    color: var(--New-Primary-2-Primary-Base, #9360a8);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 100% */
  }
}
.container {
  @include padding-block(24, 16, 16px);
  @include padding-inline(24, 16, 16px);
  display: flex;
  flex-direction: column;
  @include gap(20, 16, 16px);

  .back {
    display: flex;
    justify-content: space-between;
    .status {
      border-radius: 32px;
      padding: 4px 12px;
      color: var(--Danger-Danger-60, #b22a12);
      background: var(--Danger-Danger-10, #fce8d0);
      > p {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
    }
    .status[status="PAID-OFF"] {
      background: var(--Success-Success-10, #f0fdd2);
      color: var(--Success-Success-90, #275f06);
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      .left {
        display: flex;
        align-items: center;
        gap: 8px;

        > span {
          font-family: $BASE-FONT-FAMILY;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 142.857%;

          &:first-child {
            font-size: 16px;
            color: $NEW-PRIMARY2-BASE;
          }

          &:nth-child(2) {
            color: $NEUTRAL-80;
          }
        }
      }

      .right {
        display: flex;
        align-items: center;
        gap: 8px;

        > img {
          height: 20px;
          object-fit: contain;
        }

        > span {
          font-family: $BASE-FONT-FAMILY;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 142.857%;
          color: #003399;
        }
      }
    }
  }

  .divider {
    height: 1px;
    background-color: $NEUTRAL-40;
  }

  .content {
    display: grid;
    grid-template-columns: 7fr 5fr;
    @include gap(20, 12, 12px);

    > * {
      border-radius: 8px;
      box-shadow: $BOX-SHADOW;
      @include padding(24, 16, 16px);
      display: flex;
      flex-direction: column;
      gap: 16px;

      &.leftCard {
        max-width: 5fr;
      }

      .section {
        display: flex;
        flex-direction: column;
        gap: 12px;

        > h4 {
          font-family: $BASE-FONT-FAMILY;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
        }

        .details {
          display: flex;
          flex-direction: column;
          gap: 12px;

          .row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;

            > p {
              font-family: $BASE-FONT-FAMILY;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
              color: $NEUTRAL-90;

              .green {
                color: $SUCCESS-60;
              }
            }

            > span {
              font-family: $BASE-FONT-FAMILY;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              color: $NEUTRAL-90;

              &.red {
                color: $DANGER-60;
              }
            }
          }
        }

        .proof {
          display: flex;
          align-items: center;
          gap: 10px;

          > img {
            height: 105px;
            object-fit: contain;
            cursor: pointer;
          }

          > span {
            flex-grow: 1;
            width: 100px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            overflow: hidden;
            color: #000;
            line-clamp: 2;
            text-wrap: pretty;
          }
        }
      }

      .prakerjaMethod {
        display: flex;
        flex-direction: column;
        gap: 2px;
        > p {
          color: $NEUTRAL-60;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
        .codeWrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          > :first-child {
            color: $NEUTRAL-90;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px; /* 150% */
          }
          > img {
            height: 23.952px;
          }
        }
      }
    }
  }

  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include gap(20, 8, 8px);
  }
}

@media screen and (max-width: 768px) {
  .container {
    .header {
      .title {
        flex-direction: column;

        > * {
          width: 100%;
        }
      }
    }

    .content {
      grid-template-columns: 1fr;
    }

    .actions {
      grid-template-columns: 1fr;
    }
  }
}
