@import "../../../theme/variables.scss";

.container {
  width: 100%;
  height: 6px;
  border-radius: 2px;

  .filler {
    height: 100%;
    border-radius: inherit;
    text-align: right;
    // transition: width 1s ease-in-out;


    .label {
      padding: 5px;
      color: $NEUTRAL-10;
      font-weight: 'bold'
    }
  }
}