@import "../../../../theme/variables.scss";

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  & > :first-child {
    width: 200px;
    height: 200px;
  }
  & > :last-child {
    color: var(--New-Primary-2-Primary-Base, #9360a8);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 100% */
  }
}
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 16px;
  > :first-child {
    flex: 1;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 38px;
    padding-bottom: 16px;
    .status {
      border-radius: 32px;
      padding: 4px 12px;
      color: var(--Danger-Danger-60, #b22a12);
      background: var(--Danger-Danger-10, #fce8d0);
      > p {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
    }
    .status[status="PAID-OFF"] {
      background: var(--Success-Success-10, #f0fdd2);
      color: var(--Success-Success-90, #275f06);
    }
  }
  .topSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 28px;
    margin-right: 38px;
    border-bottom: 1px solid $NEUTRAL-40;
    margin-bottom: 22px;
    padding-bottom: 20px;
    > :first-child {
      display: flex;
      align-items: center;
      gap: 8px;
      > :first-child {
        color: #9360a8;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }
      > :nth-child(2) {
        color: #616161;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
      > :last-child {
        color: $NEUTRAL-100;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
    .right {
      display: flex;
      align-items: center;
      gap: 8px;

      > img {
        height: 20px;
        object-fit: contain;
      }

      > span {
        font-family: $BASE-FONT-FAMILY;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 142.857%;
        color: #003399;
      }
    }
  }
  .mainSection {
    display: grid;
    grid-template-columns: 55% 1fr;
    gap: 20px;
    padding-left: 28px;
    padding-right: 38px;
    .card {
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
        0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    }
    .discount {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > :first-child {
        color: $NEUTRAL-90;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px; /* 150% */
        > span {
          color: var(--Success-Success-60, #60aa15);
        }
      }
      > :last-child {
        color: var(--Danger-Danger-60, #b22a12);
        text-align: right;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
    }
    .divider {
      height: 1px;
      background: #e0e0e0;
      width: 100%;
    }
    .left {
      .card {
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .cardContent {
          display: flex;
          flex-direction: column;
          gap: 12px;

          > :first-child {
            color: $NEUTRAL-100;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px; /* 150% */
          }
          .label {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            > :first-child {
              color: $NEUTRAL-90;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px; /* 150% */
            }
            > :last-child {
              color: $NEUTRAL-90;
              text-align: right;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px; /* 150% */
            }
          }
        }
      }
    }
    .right {
      .card {
        padding: 16px;
        display: flex;
        gap: 12px;
        flex-direction: column;
        > :first-child {
          color: $NEUTRAL-100;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
        .cardContent {
          display: flex;
          flex-direction: column;
          gap: 8px;
          .label {
            display: flex;
            justify-content: space-between;
            > :first-child {
              color: $NEUTRAL-90;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px; /* 150% */
              > span {
                color: $NEUTRAL-60;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
              }
            }
          }
        }
        .paidTotal {
          display: flex;
          justify-content: space-between;
          > :first-child {
            color: $NEUTRAL-90;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px; /* 150% */
          }
          > :last-child {
            color: $NEUTRAL-100;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px; /* 150% */
          }
        }
        .prakerjaMethod {
          display: flex;
          flex-direction: column;
          gap: 2px;
          > p {
            color: $NEUTRAL-60;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }
          .codeWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            > :first-child {
              color: $NEUTRAL-90;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px; /* 150% */
            }
            > img {
              height: 23.952px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    .mainSection {
      grid-template-columns: 1fr !important;
      gap: 16px;
    }
    .topSection {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      // margin-left: 28px;
      // margin-right: 38px;
      // border-bottom: 1px solid $NEUTRAL-40;
      // margin-bottom: 22px;
      // padding-bottom: 20px;
      > :first-child {
        display: flex;
        align-items: flex-start !important;
        gap: 2px;
        flex-direction: column;
        > :nth-child(2) {
          display: none !important;
        }
      }
    }
  }
}
