@import "../../../theme/variables.scss";

#testimonials {
  scroll-margin: 70px;
}
.background {
  scroll-margin: 70px;
  background: $NEUTRAL-10;
  box-shadow: 0px -15px 20px 0px rgba(116, 105, 105, 0.25);
  .wrapperTestimonials {
    padding-top: 125px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 125px;
    justify-content: center;
    h1 {
      color: $NEUTRAL-100;
      font-size: 40px;
      font-weight: 600;
      line-height: 50px;
      padding-bottom: 58px;
      span {
        color: $NEW-PRIMARY2-BASE;
        font-size: 40px;
        font-weight: 600;
        line-height: 50px;
      }
    }

    .wrapperProfile {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      padding-inline: 10px;
      .profile {
        cursor: pointer;
        display: flex;
        justify-content: center;
        .left {
          gap: 24px;
          display: flex;
          flex-direction: column;
          text-align: center;

          .activeProfile {
            width: 100%;
            height: 100px;
            justify-content: center;
            align-items: center;
            display: flex;
            .profileName {
              display: flex;
              flex-direction: column;
              row-gap: 4px;
              text-align: center;
              h1 {
                font-size: 1rem;
                line-height: 24px;
                color: $NEW-PRIMARY2-BASE;
                font-weight: 600;
              }
              p {
                color: $NEUTRAL-60;
                font-size: 14px;
              }
            }
          }
          img {
            border-radius: 50%;
            width: 98px;
            height: 98px;
            flex-shrink: 0;
            object-fit: cover;
            opacity: 0.4;
          }
          img[isactive="true"] {
            outline: 2px solid $NEW-PRIMARY2-BASE;
            opacity: 1;
          }
        }
        .center {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          row-gap: 16px;
          max-width: 160px;
          width: auto;
          img {
            border-radius: 50%;
            width: 98px;
            height: 98px;
            flex-shrink: 0;
            object-fit: cover;
            outline: 2px solid;
            outline-color: $NEW-PRIMARY2-BASE;
          }
        }
        .right {
          gap: 24px;
          display: flex;
          img {
            border-radius: 50%;
            width: 98px;
            height: 98px;
            flex-shrink: 0;
            object-fit: cover;
            opacity: 0.6;
          }
        }
      }
      .indicatorActive {
        padding-top: 32px;
        display: flex;
        gap: 8px;
        justify-content: center;
        div {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $NEUTRAL-40;
        }
        div[active="true"] {
          background-color: $NEW-PRIMARY2-BASE;
        }
      }
    }
    .lineSeperator {
      width: 70%;
      height: 2px;
      margin-block: 45px;
      background-color: $NEUTRAL-40;
      position: relative;
      &::before {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        background-color: $NEUTRAL-40;
        top: -5px;
        transform: rotate(52deg);
      }
      &::after {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        background-color: $NEUTRAL-40;
        top: -5px;
        transform: rotate(42deg);
        right: 0;
      }
    }
    .description {
      height: auto;
      max-width: 550px;
      animation: fadeIn 1s;
      p {
        color: $NEUTRAL-100;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}

@media (max-width: 768px) {
  .background {
    .wrapperTestimonials {
      padding-top: 42px;
      padding-bottom: 42px;
      h1 {
        color: $NEUTRAL-100;
        text-align: center;
        font-size: 20px !important;
        font-weight: 600;
        line-height: 26px;
        padding-top: 0;
        padding-bottom: 36px;
        span {
          color: $NEW-PRIMARY2-BASE;
          font-size: 20px;
          font-weight: 600;
          line-height: 26px;
        }
      }
      .wrapperProfile {
        .profile {
          gap: 12px !important;
          .left,
          .right,
          .center {
            img {
              width: 48px;
              height: 48px;
              flex-shrink: 0;
            }
          }
        }
      }
    }
    .description {
      height: auto;
      padding-inline: 20px;
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}
.profileReview {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;

  .nameAndJobs {
    display: flex;
    flex-direction: column;
    row-gap: -20px;
    p:nth-child(1) {
      color: $NEW-PRIMARY2-BASE;
      font-weight: 600;
    }
    p:nth-child(2) {
      color: $NEUTRAL-60;
    }
  }
}
