@mixin checkbox($color) {
  height: 20px;
  width: 20px;
  border: 1px solid $color;
  border-radius: 20%;
  cursor: pointer;
}
.checkboxOutline {
  @include checkbox(#c2c2c2);
}
.checkboxFilled {
  @include checkbox(#9360a8);
  background-image: linear-gradient(98deg, #795EC6 0%, #8CC5DE 100.02%);
  background: linear-gradient(to right, #795ec6, #8cc5de) border-box;
  border-radius: 20%;
  border: 1px solid transparent;
  display: flex;
}
