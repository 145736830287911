.wrapperEditQuiz {
  // width: 532px;
  height: auto;
  padding: 24px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  border-radius: 8px;
  border: 1px solid var(--Neutral-40, #e0e0e0);
  z-index: inherit;
  position: relative;

  .headerEditQuiz {
    align-items: center;
    display: flex;
    gap: 24px;
    justify-content: space-between;
    width: 100%;

    .selectOption {
      padding: 6px 18px;
      border-radius: 8px;
      border: 1px solid var(--Neutral-40, #e0e0e0);
      background: #fff;
      //   background-color: salmon;
      display: flex;
      width: 258px;
      height: 44px;
      padding: 6px 8px;
      align-items: center;
      gap: 10px;
      justify-content: space-between;
      cursor: pointer;

      select {
        border: none;
        width: 100%;
        font-size: 1rem;

        &:focus {
          outline: none;
        }
      }
    }

    .spaceBetween {
      display: flex;
      align-items: center;
      gap: 24px;

      &> :nth-child(2) {
        cursor: pointer;
      }
    }
  }

  .textEdit {
    height: auto;
  }

  .editQuestion {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  select {
    width: 90px;
    height: 30px;
    padding-right: 10px;
  }
}