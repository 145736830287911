@import "../../../theme/variables.scss";
a[islinktext="true"] {
  text-decoration: underline;

  span {
    color: $NEW-PRIMARY2-BASE;
  }
}
#faq{
  scroll-margin: 70px;
  
}
.container {
  scroll-margin: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $NEUTRAL-10;
  padding-inline: 10px;
  flex-direction: column;
  align-items: center;
  row-gap: 32px;
  padding-bottom: 108px;
  .wrapperAccordion {
    padding: 24px;
    max-width: 1010px;
    width: 100%;
    border-radius: 16px;
    background: $NEUTRAL-10;
    box-shadow: 0px 14px 64px -4px rgba(24, 39, 75, 0.12),
      0px 8px 22px -6px rgba(24, 39, 75, 0.12);
    main {
      .contain {
        .isClickIndicator {
          display: grid;
          grid-template-columns: auto auto;
          gap: 1rem;
          justify-content: space-between;
          align-items: start;
          cursor: pointer;
        }
        p {
          color: $NEUTRAL-90;
          text-overflow: ellipsis;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          transition: 0.2s;
        }
        p[active="true"] {
          color: $NEW-PRIMARY2-BASE;
        }
        img {
          width: 20px;
          height: 20px;
        }
        img[active="true"] {
          transform: rotate(-180deg);
          transition: 0.3s;
          color: $NEW-PRIMARY2-BASE;
        }
        img[active="false"] {
          transform: rotate(0);
          transition: 0.3s;
        }
        .answer {
          color: $NEUTRAL-90;
          cursor: text;
          font-size: 14px;
          font-weight: normal;
          // white-space: pre-wrap;
          animation: fadeIn 1.6s;
          line-height: 24px;
          ol,
          ul {
            padding-inline: 1rem;
          }
        }
      }

      .lineSeperator {
        width: 100%;
        border: 1px dashed $NEW-PRIMARY2-BASE;
        margin-block: 1rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    .wrapperAccordion {
      main {
        .contain {
          gap: 10px;
          p {
            font-size: 14px;
            line-height: 20px;
          }
          img {
            height: 16px;
            width: 16px;
          }
          .answer {
            font-size: 12px;
            line-height: 1rem;
          }
        }
        .lineSeperator {
          margin-block: 14px;
        }
      }
    }
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}
