@import "../../../theme/variables.scss";

.noScheduleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 16px;
  > :first-child {
    color: var(--light-paragraph, #333);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  > :last-child {
    color: var(--Neutral-60, #9e9e9e);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    > span {
      color: #4640ff;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.container {
  min-height: calc(100vh - 78px);
  display: flex;
  flex-direction: column;
  .scheduleWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .header {
    height: 408px;
    border-radius: 0px !important;
    // padding: 42px 32px;
    @include padding-inline(32, 18, 18px);

    display: flex;
    align-items: center;
    padding-bottom: 0;
    position: relative;
    @include gap(32, 16, 16px);

    .clay {
      position: absolute;
      right: 0;
      z-index: -1;
      transform: rotate(-25deg);
      top: 38%;
      left: 76%;
    }

    .preview {
      height: 360px;
      position: relative;
      // width: 500px;

      > img {
        height: 100%;
        object-fit: contain;
        box-shadow: $BOX-SHADOW;
      }

      .playButton {
        position: absolute;
        background-color: $NEUTRAL-20;
        border-radius: 50%;
        // width: 50px;
        @include width(50, 39, 39px);
        aspect-ratio: 1;
        left: 44%;
        top: 30%;
        z-index: 2;
        display: grid;
        place-items: center;
        color: $NEW-PRIMARY1-BASE;
        border: 5px solid $NEW-PRIMARY1-10;
        box-shadow: $BOX-SHADOW;

        &.move {
          -webkit-animation: up-and-down 3s linear infinite;
          -moz-animation: up-and-down 3s linear infinite;
          animation: up-and-down 3s linear infinite;
        }
      }

      .box {
        position: absolute;
        top: 4%;
        left: 3.5%;
        // z-index: 100;
        width: 93%;
        aspect-ratio: 2/1.15;
        overflow: clip;
        background-color: $NEUTRAL-100;

        > img,
        > video {
          width: 100%;
          object-fit: cover;
          height: -webkit-fill-available;
        }
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      gap: 16px;
      color: $NEUTRAL-10;
      flex-grow: 1;

      > h3 {
        font-family: $BASE-FONT-FAMILY;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%;
      }

      .label {
        display: flex;
        align-items: center;
        gap: 5px;

        > div {
          padding: 4px 6px;
          display: flex;
          align-items: center;
          gap: 4px;

          > span {
            font-family: $BASE-FONT-FAMILY;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 142.857%;
          }
        }
      }

      > span {
        font-family: $BASE-FONT-FAMILY;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%;
      }

      .actions {
        display: flex;
        align-items: flex-end;
        gap: 24px;

        .headerButton {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 6px;
          color: $NEW-PRIMARY2-BASE;
          background-color: $NEUTRAL-10;
          height: 44px;
          padding-inline: 16px;
          border-radius: 8px;

          font-family: $BASE-FONT-FAMILY;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 125%;
          white-space: nowrap;
          text-align: center;
          transition: 0.3s;
          &.v2 {
            height: 45px;
            padding-inline: 26px;
            // border-bottom: 3px solid $NEUTRAL-100;
            box-shadow: 0 5px 5px $NEUTRAL-100;
            transform: translateY(-2px);
          }
          &:active {
            transform: translateY(0);
            box-shadow: none;
          }
        }

        .voucherBox {
          flex-grow: 1;
          display: flex;
          align-items: flex-end;
          gap: 8px;

          > :first-child {
            flex-grow: 1;
          }
          .inputBox[is-error="true"] {
            input {
              border-color: #d1421a;
              color: #d1421a;
            }
          }
          .inputBox {
            position: relative;
            .errorMessage {
              position: absolute;
              color: #d1421a;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
              bottom: -20px;
            }
          }
        }
      }
    }
  }

  .content {
    display: grid;
    grid-template-columns: 289px 1fr;
    gap: 35px;
    @include padding-inline(32, 18, 18px);
    flex-grow: 1;
    padding-bottom: 36px;

    .left {
      padding-top: 35px;

      .card {
        position: sticky;
        top: 110px;
        display: flex;
        flex-direction: column;

        > h5 {
          display: block;
          font-family: $BASE-FONT-FAMILY;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 16px;
        }

        .list {
          display: flex;
          flex-direction: column;

          .linkTo {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 10px 16px;
            cursor: pointer;

            &.active {
              background-color: $NEW-PRIMARY2-10;
            }

            span {
              font-family: $BASE-FONT-FAMILY;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
              color: $NEUTRAL-90;
            }
          }
        }

        .button {
          padding: 16px;

          > * {
            width: 100%;
          }
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      // gap: 36px;

      .section {
        display: flex;
        flex-direction: column;
        gap: 16px;
        scroll-margin: 70px;

        // padding-top: 36px;
        @include padding-top(36, 24, 24px);

        .sectionTitle {
          display: flex;
          align-items: center;
          gap: 8px;

          h4 {
            font-family: $BASE-FONT-FAMILY;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;
            color: $NEW-PRIMARY2-BASE;
          }
        }

        .sectionContent {
          padding-left: 16px;
        }
      }

      .allAspects {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .perAspect {
          display: flex;
          flex-direction: column;
          gap: 6px;

          .cTitle {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 8px;

            font-family: $BASE-FONT-FAMILY;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }

          .cList {
            display: flex;
            flex-direction: column;
            gap: 6px;
          }
        }
      }

      .syllabus {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      .instructor {
        display: flex;
        // align-items: center;
        gap: 16px;

        .iImg {
          display: grid;
          place-items: center;
          // display: flex;
          // align-items: flex-end;
          // justify-content: center;
          width: 244px;
          // height: 269.5px;
          height: 330px;
          position: relative;

          .picture {
            width: 100%;
            height: 100%;
            object-fit: cover;
            margin-bottom: 10px;
          }

          > * {
            z-index: 1;
          }

          .bg {
            position: absolute;
            z-index: 0;
            left: 0;
            bottom: 0;
            width: 100%;
          }
        }

        .iDesc {
          flex-grow: 1;

          > span {
            font-family: $BASE-FONT-FAMILY;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 116.667%;
            color: $NEW-PRIMARY2-BASE;
          }

          > h5 {
            font-family: $BASE-FONT-FAMILY;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            color: $NEUTRAL-90;
          }
        }
      }
    }

    .text {
      font-family: $BASE-FONT-FAMILY;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 142.857%;
      white-space: pre-line;

      &.v2 {
        color: $NEUTRAL-90;
      }
    }
  }
  .modalSuccessContainer {
    border-radius: 12px;
    background: #fff;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 312px;
    text-align: center;
    > img {
      width: 148px;
      height: 148px;
    }
    .modalTitle {
      color: $NEUTRAL-90;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px; /* 125% */
    }
    .modalDesc {
      color: $NEUTRAL-60;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
    }
  }
  .modalSelectBatchContainer {
    border-radius: 12px;
    background: #fff;
    display: flex;
    padding: 24px 12px;
    flex-direction: column;
    gap: 16px;
    width: 400px;
    .batchList {
      display: flex;
      flex-direction: column;
      gap: 12px;
      max-height: 70vh;
      .batchesList {
        display: flex;
        flex-direction: column;
        gap: 12px;
        overflow: auto;
      }
      > :first-child {
        color: $NEUTRAL-90;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px; /* 125% */
      }
      .batchWrapper {
        display: flex;
        gap: 10px;
        border-radius: 8px;
        border: 1px solid $NEUTRAL-40;
        align-items: center;
        padding: 13px 14px;
        > p {
          color: $NEUTRAL-90;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px; /* 125% */
        }
      }
    }
    .selectBatchModalAction {
      display: flex;
      gap: 12px;
      > button {
        flex: 1;
      }
    }
  }
}

.listText {
  display: flex;
  align-items: center;
  gap: 4px;
}

.bullet {
  width: 10px;
  height: 10px;
  background: $COLOR-GRADIENT-2;
  border-radius: 50%;
}

@media screen and (max-width: 1080px) {
  .container {
    .header {
      padding-bottom: 18px;

      // .preview {

      //   .box {
      //     width: 93%;
      //   }
      // }

      .title {
        .actions {
          flex-direction: column-reverse;

          > * {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .header {
      flex-direction: column;
      align-items: center;
      height: auto;
      // padding: 32px;
      padding-bottom: 18px;

      > * {
        width: 100%;
      }

      .preview {
        width: 100%;
        height: 100%;
        overflow: clip;

        > img {
          width: 100%;
          height: auto;
        }

        .playButton {
          left: 45%;
        }
      }
    }

    .content {
      grid-template-columns: 1fr;

      .instructor {
        flex-direction: column;
        align-items: center;

        > * {
          width: 100%;
        }

        .iImg {
          // width: 100%!important;
          height: auto;
        }
      }
    }
    .modalSelectBatchContainer {
      width: 312px !important;
    }
  }
}

@keyframes up-and-down {
  0%,
  100% {
    top: 29%;
  }

  50% {
    top: 33%;
  }
}
