@import "../../../theme/variables.scss";

.container {
  height: 100%;
  width: 100%;
  background-color: $NEUTRAL_10;
  border-radius: 0 0 8px 8px;
  display: flex;
  align-items: center;
  padding: 16px;
}

.leftSide {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  > span {
    color: var(--Neutral-100, #232323);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
}

.rightSide {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.content {
  padding-right: 16px;
  font-size: 12;
  font-weight: 400;
  color: $NEUTRAL_100;
}

.contentBold {
  font-size: 12;
  font-weight: 600;
  color: $NEUTRAL_100;
}

.pageIndicator {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.arrow {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  // background-color: $NEUTRAL_30;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.arrowWrapper:first-child {
  padding-right: 10px;
}

.arrowWrapper:last-child {
  padding-left: 10px;
}

.page {
  padding: 10px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: $NEUTRAL-60;
}

.selectedPage {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  // background-color: $PRIMARY_MAIN;
  color: $NEUTRAL_100;
}

.disabled {
  color: $NEUTRAL_70 !important;
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
    gap: 8px;

    .leftSide {
      width: 100%;
      justify-content: center;
    }

    .rightSide {
      width: 100%;
      justify-content: center;
    }
  }
}
