@import "../../../theme/variables.scss";
.describePrakerja {
  padding: 185px 0;
  padding-left: 123px;
  position: relative;
  box-shadow: 0 6px 5px #a9a9a940;
  display: flex;
  align-items: center;
  gap: 83px;
  justify-content: center;

  img {
    width: 375px;
    height: 647px;
    flex-shrink: 0;
    z-index: -1;
  }
  .wrapperText {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    h1 {
      color: $NEUTRAL-90;
      font-size: 40px;
      font-weight: 600;
      line-height: 50px;
      padding-bottom: 8px;
      span {
        color: $NEW-PRIMARY2-BASE;
      }
    }
    .text {
      width: 100%;
      display: flex;
      flex-direction: column;
      p {
        color: $NEUTRAL-100;
        font-size: 16px;
        line-height: 24px;
        width: 574px;
      }
      div {
        .linkTo {
          width: max-content;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .describePrakerja {
    padding: 1rem;
    img {
      width: 284px;
      height: 247px;
      flex-shrink: 0;
      top: 20px;
    }
    .wrapperText {
      row-gap: 1rem;
      h1 {
        line-height: 1rem;
        font-size: 1rem !important;
        padding-bottom: 200px;
      }
    }
    .text {
      p {
        font-size: 14px !important;
        width: auto !important;
        line-height: 20px !important;
      }
    }
  }
}
