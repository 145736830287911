@import "../../../theme/variables.scss";

.tabContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  .tab {
    padding: 12px 24px;
    border-radius: 60px;
    border: 2px solid $NEUTRAL-30;
    background: $NEUTRAL-10;
    cursor: pointer;
    transition: 0.2s;
    white-space: nowrap;
  }
  .tab:hover {
    box-shadow: 0px 3px 6.8px 0px rgba(0, 0, 0, 0.12);
  }

  .tab[is-selected="true"] {
    border: 2px solid $NEW-PRIMARY2-BASE;
    color: $NEW-PRIMARY2-BASE;
    box-shadow: 0px 3px 6.8px 0px rgba(0, 0, 0, 0.12);
  }
}
@media (max-width: 768px) {
  .tabContainer {
    gap: 4px;
    .tab {
      width: max-content;
      padding: 8px 16px;

      p {
        font-size: 14px;
        text-align: center;
      }
    }
  }
}
