@import "../../../../theme/variables.scss";

.container {
  padding: 24px 32px 24px 24px;
  .topCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .leftSection {
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: white;
      .title {
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        > span {
          font-weight: 600;
        }
      }
      .desc {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
      .inputWrapper {
        width: 60%;
      }
    }
    // .rightSection {

    // }
  }
  .classCardWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
    margin-top: 15px;
  }
  .notFound {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-top: 56px;
  }
}

@media screen and (max-width: 600px) {
  .container {
    .topCard {
      flex-direction: column !important;
      gap: 10px;
      .leftSection {
        .inputWrapper {
          width: 100% !important;
        }
      }
      .rightSection {
        width: 100%;
        .bannerAction {
          width: 100%;
        }
      }
    }
    .section {
      .classCardWrapper {
        grid-template-columns: 1fr;
      }
    }
  }
}
