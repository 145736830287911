.modalContainer {
  display: flex;
  width: 803px;
  height: 681px;
  max-height: 100vh;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 12px;
  background: #fff;
  .modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    & > :last-child {
      cursor: pointer;
    }
  }
  .modalInput {
    width: 100%;
  }
  .modalAction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 16px;
    margin-top: auto;
    & > * {
      flex: 1;
    }
  }
}
