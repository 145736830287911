@import "../../../theme/variables.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid $NEUTRAL-40;
    border-radius: 8px;
    padding-left: 40px;
    font-family: $BASE-FONT-FAMILY;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 142.857%;

    &::placeholder {
      color: $NEUTRAL-60;
    }

    &:focus {
      outline: none;
      border: 1px solid $NEW-PRIMARY2-BASE;
    }
  }

  .icon {
    position: absolute;
    left: 12px;
  }
}