@import "../../../theme/variables.scss";

.container {
  padding: 12px 5px;
  border: 1px solid $NEUTRAL-40;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  >button {
    display: grid;
    place-items: center;
    position: relative;
    padding: 3px;
    aspect-ratio: 1;
    border-radius: 50%;
    transition: all 0.3s;
    color: $NEUTRAL-70;

    >span {
      display: inline-block;
      padding: 4px;
      border-radius: 4px;
      visibility: hidden;
      position: absolute;
      left: calc(100% + 12px);
      white-space: nowrap;
      background-color: $NEW-PRIMARY2-BASE;
      color: $NEUTRAL-10;
      font-family: $BASE-FONT-FAMILY;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

    &.active {
      color: $NEW-PRIMARY2-BASE;
      background-color: $NEW-PRIMARY2-10;

      >span {
        visibility: visible;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    button {
      span {
        left: auto;
        right: calc(100% + 12px);
      }
    }
  }
}