@import "../../../theme/variables.scss";

.container {
  display: flex;
  align-items: flex-end;
  border-bottom: 4px solid $NEW-PRIMARY2-BASE;
  overflow: auto;

  >button {
    padding: 7px 16px;
    background-color: $NEUTRAL-30;
    color: $NEUTRAL-50;
    border-radius: 12px 12px 0 0;
    box-shadow: $BOX-SHADOW;
    font-family: $BASE-FONT-FAMILY;
    font-size: 16px;
    font-style: normal;
    line-height: 120%;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    gap: 4px;
    white-space: nowrap;

    &.active {
      background-color: $NEW-PRIMARY2-BASE;
      color: $NEUTRAL-10;
      font-size: 20px;
      font-weight: 500;

    }

    &.add {
      font-size: 12px;
      color: $NEW-PRIMARY2-BASE;
    }
  }
}