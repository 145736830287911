@import "../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 78px);

  .wrapper {
    @include padding-block(24, 12, 12px);
    @include padding-inline(24, 16, 16px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;

  }

  .header {
    display: flex;
    align-items: center;

    .title {
      flex-grow: 1;
      color: $NEUTRAL-10;
      display: flex;
      flex-direction: column;
      gap: 8px;

      h3 {
        font-family: $BASE-FONT-FAMILY;
        @include font-size(24, 16, 16px);
        font-style: normal;
        font-weight: 400;
        line-height: 30px;

        >span {
          font-weight: 600;
        }
      }

      >span {
        font-family: $BASE-FONT-FAMILY;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 142.857%;
      }
    }

    .img {
      width: 93px;
      object-fit: contain;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    @include gap(24, 32, 32px);

    .each {
      display: flex;
      flex-direction: column;
      gap: 20px;

      >span {
        font-family: $BASE-FONT-FAMILY;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }

      .grid {
        display: grid;
        grid-auto-flow: column;
        gap: 20px;
      }

      .informationSection {
        display: flex;
        align-items: center;
        gap: 20px;

        .infoFields {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 315px;


          // gap: ;
        }


      }

      .bankAccountSection {
        display: flex;
        flex-direction: column;
        gap: 14px;
        @include padding-bottom(42, 24, 24px);
      }

      .submit {
        display: flex;
        justify-content: flex-end;
      }
    }


  }
}

@media screen and (max-width:768px) {
  .container {

    .wrapper {

      .content {

        .grid {
          grid-auto-flow: row;
          grid-template-columns: 1fr;
          gap: 16px;
        }

        .informationSection {
          flex-direction: column;
          gap: 16px;

          .infoFields {
            height: auto;
            width: 100%;
            gap: 16px;
          }
        }
      }
    }
  }
}