@use "../../../theme/variables.scss" as *;
#categories {
  scroll-margin: 70px;
}
.container {
  margin: 0;
  padding: 100px 89px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .headerClassCategory {
    scroll-margin: 100px;

    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    padding-block: 60px;
    text-align: center;
    h1 {
      color: $NEUTRAL-100;
      font-size: 40px;
      font-weight: 600;
      line-height: 50px;
      span {
        color: $NEW-PRIMARY2-BASE;
      }
    }
  }
  .carouselCategory {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .leftIcon,
    .rightIcon {
      background-color: $NEUTRAL-10;
      border-radius: 50%;
      padding: 10px;
      box-shadow: 0 0 10px $NEUTRAL-50;
      display: flex;
      align-items: center;
      width: 44px;
      height: 44px;
      cursor: pointer;
      &:hover {
        outline: 1px solid $NEW-PRIMARY2-BASE;
      }
    }
    .iconL,
    .iconR {
      width: 24px;
      height: 24px;
    }
    .iconL {
      transform: rotate(180deg);
    }
    .relativeButton {
      display: flex;
      width: 1102px;
      gap: 2rem;
      overflow: scroll;
      padding: 1rem;
      scroll-padding-inline: 100px;
      scroll-behavior: smooth;
      .cardCategory {
        display: flex;
        width: 186px;
        height: 228px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        background-color: $NEUTRAL-10;
        border-radius: 12px;
        box-shadow: 2px 6px 10px $NEUTRAL-50;
        cursor: pointer;
        .imageCarousel {
          width: 186px;
          height: 186px;
          flex-shrink: 0;
        }
        .title {
          color: $NEUTRAL-100;
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          padding: 4px 2px;
        }
      }
    }
    .relativeButton {
      overflow: auto;
    }
    .relativeButton::-webkit-scrollbar {
      display: none;
    }
    .relativeButton {
      scrollbar-width: thin;
      scrollbar-color: $NEUTRAL-50 transparent;
    }
    .relativeButton::-webkit-scrollbar-track {
      background-color: transparent;
    }
    .relativeButton::-webkit-scrollbar-thumb {
      background-color: $NEUTRAL-30;
    }
  }
}
@media (max-width: 768px) {
  .container {
    padding: 0;
    .headerClassCategory {
      padding: 24px;
      width: 100% !important;
      h1 {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
      }
    }
    .carouselCategory {
      width: 380px;
      gap: 0;
      padding-inline: 0.5rem;
      .relativeButton {
        padding-inline: 4px;
        gap: 10px;
        .cardCategory {
          display: flex;
          width: 70px;
          height: 100px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          .imageCarousel {
            width: 72px;
            height: 72px;
            flex-shrink: 0;
          }
          .title {
            color: $NEUTRAL-100;
            font-size: 10px;
            font-weight: 500;
          }
        }
      }
      .leftIcon,
      .rightIcon {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        &:hover {
          outline: 1px solid $NEW-PRIMARY2-BASE;
        }
      }
      .iconL,
      .iconR {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
      }
    }
  }
}
