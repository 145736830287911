@import "../../../theme/variables.scss";
.wrapperContactMe {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 16px;
  border-radius: 39px;
  background-color: #15cd3d;
  width: max-content;
  color: $NEUTRAL-10;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  span {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .wrapperContactMe {
    border-radius: 10px;
    span {
      display: none;
    }
  }
}
