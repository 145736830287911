@import "../../../theme/variables.scss";
@mixin profileFooter() {
  color: #232323;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
@mixin childTabs() {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  list-style-type: none;
}
@mixin tabsParent() {
  color: #232323;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
@mixin headerTabs() {
  display: flex;
  gap: 100px;
}
@mixin moleculeStylingTabs() {
  text-align: start;
  ul {
    @include childTabs();
    @include profileFooter();

    h1 {
      @include tabsParent();
    }
    li {
      cursor: pointer;
    }
  }
}
@mixin textLogo() {
  .contact {
    display: flex;
    gap: 4px;
    align-items: center;
    .iconContact {
      width: 20px;
      height: 20px;
    }
    p {
      @include profileFooter();
    }
  }
}
@mixin profileFooterMobile() {
  color: #232323;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
}
@mixin headerTabsMobile() {
  display: flex;
  gap: 8px;
}
@mixin tabsParentMobile() {
  color: #232323;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
@mixin textLogoMobile() {
  .contact {
    display: flex;
    gap: 4px;
    align-items: center;
    .iconContact {
      width: 16px;
      height: 16px;
    }
    p {
      @include profileFooterMobile();
    }
  }
}
.footer {
  background-color: $NEUTRAL-10;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .wrapper {
    padding-block: 20px;
    width: 1440px;
    max-width: 100vw;

    .mainFooter {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 18px;
      padding-inline: 26px;
      .skillsInformation {
        height: auto;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        .logo {
          display: flex;
          align-items: center;
          gap: 10px;
          padding-bottom: 2px;
          img {
            width: 40px;
            height: 50px;
            flex-shrink: 0;
          }
          p {
            color: #232323;
            font-size: 24px;
            font-weight: 400;
          }
        }
        h1 {
          @include tabsParent();
        }
        p {
          @include profileFooter();
        }
        @include textLogo();
      }
      .tabs {
        @include headerTabs();
        .otherMenu {
          a li {
            color: #232323;
          }
          @include moleculeStylingTabs();
        }
        .categoryClass {
          @include childTabs();
          h1 {
            @include tabsParent();
          }
          .wrapperText {
            display: flex;
            gap: 40px;
            div {
              @include moleculeStylingTabs();
            }
          }
        }
        .socialMedia {
          @include childTabs();
          h1 {
            @include tabsParent();
          }
          @include textLogo();
        }
      }
    }
    .lineSeperator {
      margin-top: 36px;
      background: $NEUTRAL-40;
      height: 1px;
      width: 100%;
    }
    .copyright {
      padding-top: 24px;
      display: flex;
      justify-content: center;
      padding-bottom: 12px;
      p {
        color: #b5b5b5;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        padding-inline: 10px;
        text-align: center;
      }
    }
  }
}
@media (max-width: 900px) {
  .footer {
    .wrapper {
      .mainFooter {
        display: grid;
        grid-template-columns: auto;
        gap: 12px;
        padding: 20px 1rem;
        .tabs {
          display: grid;
          grid-template-columns: auto auto;
          gap: 12px;

          .otherMenu {
            ul {
              row-gap: 4px;
              h1 {
                padding-bottom: 8px;
              }
            }
          }
          .categoryClass {
            row-gap: 10px;
            .wrapperText {
              gap: 2rem;
              div {
                ul {
                  gap: 4px;
                }
              }
            }
          }
          .socialMedia {
            row-gap: 8px;
          }
        }
      }
    }
  }
}
@media (max-width: 468px) {
  .footer {
    .wrapper {
      .mainFooter {
        .skillsInformation {
          .logo {
            img {
              width: 24px;
              height: 30px;
            }
            p {
              font-size: 20px;
            }
          }
          row-gap: 4px;
          h1 {
            @include tabsParentMobile();
          }
          p {
            @include profileFooterMobile();
          }
          @include textLogoMobile();
        }
        .tabs {
          .otherMenu {
            ul {
              font-size: 10px;
              h1 {
                font-size: 14px;
              }
            }
          }
          .socialMedia {
            h1 {
              font-size: 14px;
            }
            @include textLogoMobile();
          }
          .categoryClass {
            h1 {
              font-size: 14px;
            }
          }
          .categoryClass {
            .wrapperText {
              div {
                ul {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
      .copyright {
        p {
          font-size: 10px;
        }
      }
    }
  }
}
