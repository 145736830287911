@import "../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-40;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;

  .ball {
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: $NEUTRAL-10;
    position: absolute;
    transition: all 0.5s;
    box-shadow: 1px 3px 5px 0px rgba(154, 154, 154, 0.25), 0px 0px 2px 0px rgba(0, 0, 0, 0.25) inset;  }

  &.checked {
    background: $COLOR-GRADIENT-2;

    // .ball {
    //   // left: 30px;
    //   right: 2px;
    // }
  }
}