@import "../../../theme/variables.scss";

.container {
  .showButton {
    position: fixed;
    transform: translate(50%, -50%);
    top: 42px;
    z-index: 4;
    left: 15px;
  }
  *[is-rotate="true"] {
    rotate: 180deg;
  }
  .sidebarContainer {
    height: 100vh;
    background: #fff;
    box-shadow: 0px 6px 24px 0px rgba(157, 160, 186, 0.25);
    width: 280px;
    z-index: 5;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    // overflow: auto;
    transition: 0.5s;
    left: 0;

    .logoSection {
      display: flex;
      justify-content: space-between;
      padding: 12px 32px;

      padding-right: 16px;
    }

    .logoWrapper {
      display: flex;
      align-items: center;
      gap: 12px;
      cursor: pointer;
      > img {
        width: 40px;
        height: 50px;
        flex-shrink: 0;
      }
      > span {
        color: #26255b;
        font-size: 24px;
        font-weight: 400;
        line-height: normal;
      }
    }
    .progressSection {
      padding: 24px 32px;

      .progressWrapper {
        display: flex;
        gap: 8px;
        align-items: center;
        .progressComponent {
          position: relative;
          width: 100%;
          .progressBase {
            height: 8px;
            border-radius: 100px;
            background: $NEUTRAL-40;
            width: 100%;
          }
          .progress {
            position: absolute;
            background: $GRADIENT;
            height: 8px;
            border-radius: 100px;
            top: 0;
          }
        }
        .progressText {
          color: #000;
          font-size: 12px;
          font-weight: 600;
          line-height: 14px; /* 116.667% */
        }
      }
      .progressDesc {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 8px;
        & > :last-child {
          color: $NEUTRAL-70;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px; /* 116.667% */
        }
      }
    }
    .mainSection {
      overflow: auto;
      height: 100%;
      .mainWrapper {
        border-bottom: 1px solid #d9d9d9;
        padding: 12px 24px 12px 32px;

        .childWrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-top: 8px;
          .child {
            color: $NEUTRAL-70;
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 10px 12px;
            border-radius: 8px;

            // .sessionsTitle {
            // }

            & > p {
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-size: 14px;
              font-weight: 500;
              line-height: 16px; /* 114.286% */
            }
            & > .tooltip {
              margin-left: auto;
            }
          }
          .child[is-selected="true"] {
            color: $NEW-PRIMARY2-BASE;
            background: $NEUTRAL-20;
          }
          .child[is-active="true"] {
            cursor: pointer;
          }
          .child[is-active="false"] {
            color: $NEUTRAL-50;
          }
          .subChildWrapper {
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding-left: 16px;
            margin-left: 12px;
            border-left: 1px solid #e0e0e0;
            margin-top: 12px;
            overflow: hidden;
            transition: 0.2s max-height;
            .subChild {
              border-radius: 8px;
              display: flex;
              padding: 8px 12px;
              align-items: center;
              gap: 8px;
              cursor: pointer;
              color: #757575;
              > :first-child {
                flex-shrink: 0;
              }

              .subChildTitle {
                flex: 1;
                & > :first-child {
                  color: $NEUTRAL-70;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 14px; /* 116.667% */
                  width: 100%;
                }
              }
            }
            .subChild[is-active="true"] {
              background: $NEUTRAL-20;
              color: #000;
              .subChildTitle {
                & > :first-child {
                  color: $NEW-PRIMARY2-BASE;
                }
              }
            }
          }
          .subChildWrapper[is-showned="false"] {
            max-height: 0px;
          }
          .subChildWrapper[is-showned="true"] {
            max-height: 5000px;
          }
        }
      }
      & > :last-child {
        border-bottom: none;
      }
    }
    .chevron {
      position: absolute;
      right: 0;
      transform: translateX(100%);
      top: 53px;
      border-radius: 0px 16px 16px 0px;
      background: #fff;
      display: flex;
      height: 60px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding-inline: 4px;
      box-shadow: 16px 16px 24px 0px rgba(157, 160, 186, 0.25);

      .chevronIcon {
        transition: 0.3s;
      }
    }
  }
  .sidebarContainer[is-showed="false"] {
    transform: translateX(-280px);
  }

  .margin {
    width: 280px;
    // margin-left: 24px;
    transition: 0.5s;
  }
  .margin[is-showed="false"] {
    width: 1px;
  }
}
