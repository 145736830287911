@import "../../../theme/variables.scss";

.containerScore {
  background-color: $NEUTRAL-10;
  width: 412px;
  height: auto;
  padding: 24px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
  > h1 {
    color: $NEUTRAL-90;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
  }
  > .score {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 198.959px;
    height: 198.959px;
    flex-shrink: 0;
    background: $GRADIENT;
    border-radius: 50%;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 146.405px;
      height: 146.405px;
      flex-shrink: 0;
      background-color: #a87eb9;
      box-shadow: 0px -1.592px 6.208px 0px rgba(0, 0, 0, 0.25) inset;
      filter: drop-shadow(0px 5.571px 5.571px rgba(0, 0, 0, 0.15));
      backdrop-filter: blur(1.591675043106079px);
      > span {
        color: $NEUTRAL-10;
        font-family: Poppins;
        font-size: 60px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }
    }
  }
  .score[is-less-than="true"] {
    background: $DANGER-40 !important;
    > div {
      background-color: $DANGER-30;
    }
  }
  > p {
    color: #333;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  > .button {
    cursor: pointer;
    width: 100%;
    padding: 8px 12px;
    border-radius: 8px;
    background: linear-gradient(white, white) padding-box,
      linear-gradient(to right, #795ec6, #8cc5de) border-box;
    border: 2px solid transparent;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    transition: 1s;
    &:hover {
      background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #795ec6, #795ec6) border-box;
    }
    span {
      background: linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 1rem;
    }
  }
}
