.wrapper {
  display: flex;
  width: 483px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  background: #fff;
  row-gap: 1rem;
  .modalClose {
    cursor: pointer;
  }
  .modalHeader {
    width: 100%;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }
  .modalImage {
    align-self: center;
    width: 276px;
    height: 239px;
    object-fit: contain;
  }
  .buttonModal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    button {
      cursor: pointer;
      width: 211px;
      padding: 4px 16px;
      border-radius: 8px;
      background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #795ec6, #8cc5de) border-box;
      border: 2px solid transparent;
      font-size: 1rem;
      font-weight: 600;
    }
    .textButtonBack {
      background: var(
        --New-Primary-Gradient-Color-Gradient-2,
        linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .textButtonStart {
      background: linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%);
      border: none;
      padding: 0;
      color: white;
    }
  }
}
@media (max-width: 600px) {
  .wrapper {
    width: 300px;
    padding-block: 1rem !important;
  }
  .buttonModal {
    gap: 10px;
  }
  button {
    font-size: 14px !important;
  }
  .modalDescription {
    text-align: center;
  }
  .modalHeader {
    text-align: center;
  }
  .modalImage {
    align-self: center;
    width: 200px !important;
    height: 200px !important;
    object-fit: contain;
  }
}
