@import "../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;

  .scoreSection {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .wrapScoreResult {
      display: flex;
      row-gap: 12px;
      flex-direction: column;
      align-items: center;
    }
    .sectionTitle {
      border-bottom: 2px solid #ededed;
      padding-bottom: 12px;
      > p {
        color: var(--Neutral-90, #404040);
        font-size: 20px;
        font-weight: 500;
        line-height: 24px; /* 120% */
      }
    }
    .sectionBody {
      display: flex;
      align-items: flex-start;
      gap: 30px;
      > :last-child {
        flex: 1;
        > :first-child {
          color: var(--Neutral-90, #404040);
          font-size: 16px;
          font-weight: 600;
          line-height: 20px; /* 125% */
        }
      }
    }
    .scoreText {
      width: 180px;
      height: 180px;
      border-radius: 50%;
      background: linear-gradient(98deg, #9360a8 0%, #6d8bc8 100.02%);
      position: relative;
      color: #ffffff;
      &::after {
        content: attr(score);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 130px;
        height: 130px;
        border-radius: 50%;
        background-color: #a39bcb;
        position: absolute;
        bottom: 23px;
        right: 25px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.319);
        font-size: 3.3rem;
        font-weight: 800;
        box-shadow: 0px -1.592px 6.208px 0px rgba(0, 0, 0, 0.25) inset;
        filter: drop-shadow(0px 5.571px 5.571px rgba(0, 0, 0, 0.15));
        backdrop-filter: blur(1.591675043106079px);
      }
    }
  }

  .notes {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 16px 40px;
    background-color: #fdf4d8;
    position: sticky;
    top: 0;

    > *:first-child {
      flex-shrink: 0;
    }

    .text {
      flex-grow: 1;

      > * {
        max-width: 601px;
      }

      > span {
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 125% */
        color: $NEUTRAL-90;
      }

      > p {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        /* 114.286% */
      }
    }

    .timeLeft {
      display: grid;
      grid-area: 1fr;

      > span {
        white-space: nowrap;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        /* 114.286% */
        color: $NEUTRAL-80;
      }

      > p {
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
        /* 125% */
        color: $NEW-PRIMARY2-70;
      }
    }
  }

  .contentWrapper {
    padding: 32px 32px 24px 24px;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .titleWrapper {
    > p {
      color: #000;
      font-size: 32px;
      font-weight: 400;
      line-height: 120%;
    }
  }

  .sectionWrapper {
    .titleSection {
      > p {
        color: $NEUTRAL-90;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        /* 120% */
      }

      margin-bottom: 12px;
      padding-bottom: 12px;
      border-bottom: 2px solid #ededed;
    }
    .desc {
      color: $NEUTRAL-90;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      white-space: pre-wrap;
    }

    .listWrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .list {
        display: flex;
        align-items: center;
        gap: 3px;
      }
    }
  }
  .filePreviewSection {
    height: 554px;
  }
}

@media screen and (max-width: 480px) {
  .container {
    gap: 16px !important;

    .filePreviewSection {
      height: 224px !important;
    }
  }
}
