.container {
  max-width: 513px;
  width: 100%;
  padding-top: 84px;
  margin-left: 45px;
  position: relative;
  // z-index: 99;
  .wrapperHeaderTitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 12px;
    h1 {
      color: #fff;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 57px;
    }
    p {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
  }
  .lineSeperator {
    width: 100%;
    height: 1px;
    background: #d5c0dd;
    margin-block: 20px;
  }
}
@media (max-width: 900px) {
  .container {
    padding-top: 20px;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    padding-inline: 3%;
    width: auto;
    .wrapperHeaderTitle {
      width: 100%;
      height: auto;
      row-gap: 8px;
      h1 {
        font-size: calc(32px - 6px) !important;
        line-height: calc(40px - 8px) !important;
      }
      p {
        line-height: 24px !important;
      }
      .lineSeperator {
        display: none;
      }
    }
  }
}
