@import "../../../theme/variables.scss";

.container {
  margin-top: 20px;
  .label {
    margin-bottom: 8px;
    color: $NEUTRAL-80;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
    > span {
      color: #d1421a;
    }
  }
  .fileWrapper {
    display: flex;
    border-radius: 12px;
    border: 1px solid $NEUTRAL-40;
    padding: 8px;
    align-items: center;
    gap: 8px;
    >:first-child{
      width: 40px;
      height: 40px;
    }
    & > :last-child {
      cursor: pointer;
    }
    .fileText {
      color: $NEUTRAL-90;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px; /* 125% */
      flex: 1;
    }
    .placeholder {
      color: $NEUTRAL-50;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px; /* 125% */
      flex: 1;
    }
  }
}
