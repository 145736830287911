@import "../../../../theme/variables.scss";
.disabledOpenSession {
  display: flex;
  max-width: 497px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  background: #fff;
  .modalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    text-align: center;
    > img {
      width: 153px;
      height: 131px;
    }
    > :first-child {
      color: var(--Neutral-90, #404040);
      font-size: 20px;
      font-weight: 600;
      line-height: 26px; /* 130% */
    }
    > :last-child {
      color: var(--light-paragraph, #333);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
  .modalAction {
    width: 100%;
    > button {
      width: 100%;
    }
  }
}

.scoreSection {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .wrapScoreResult {
    display: flex;
    row-gap: 12px;
    flex-direction: column;
    align-items: center;
  }
  .sectionTitle {
    border-bottom: 2px solid #ededed;
    padding-bottom: 12px;
    > p {
      color: var(--Neutral-90, #404040);
      font-size: 20px;
      font-weight: 500;
      line-height: 24px; /* 120% */
    }
  }
  .sectionBody {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    > :last-child {
      flex: 1;
      > :first-child {
        color: var(--Neutral-90, #404040);
        font-size: 16px;
        font-weight: 600;
        line-height: 20px; /* 125% */
      }
    }
  }
  .scoreText {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background: linear-gradient(98deg, #9360a8 0%, #6d8bc8 100.02%);
    position: relative;
    color: #ffffff;
    &::after {
      content: attr(score);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 130px;
      height: 130px;
      border-radius: 50%;
      background-color: #a39bcb;
      position: absolute;
      bottom: 23px;
      right: 25px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.319);
      font-size: 3.3rem;
      font-weight: 800;
      box-shadow: 0px -1.592px 6.208px 0px rgba(0, 0, 0, 0.25) inset;
      filter: drop-shadow(0px 5.571px 5.571px rgba(0, 0, 0, 0.15));
      backdrop-filter: blur(1.591675043106079px);
    }
  }
}

.container {
  padding: 32px 32px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  // position: relative;
  .titleWrapper {
    > p {
      color: #000;
      font-size: 32px;
      font-weight: 400;
      line-height: 120%;
    }
  }
  .filePreviewSection {
    height: 554px;
  }
  .meetUrl {
    border-radius: 8px;
    border: 1px solid var(--New-Primary-2-Primary-30, #d5c0dd);
    background: linear-gradient(184deg, #f4eff6 3.18%, #fcf3ff 96.82%);
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    width: 100%;
    > :first-child {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
    > :last-child {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;
      > :first-child {
        width: 40px;
        height: 40px;
      }
      .link {
        color: var(--New-Primary-3-60, #1aa1db);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px; /* 150% */
        text-decoration-line: underline;
        cursor: pointer;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        // width: 200px;
      }
      > :last-child {
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    gap: 16px !important;

    .filePreviewSection {
      height: 224px !important;
    }
  }
}
