@mixin radio($color) {
  height: 20px;
  width: 20px;
  border: 1px solid $color;
  border-radius: 50%;
  cursor: pointer;
}
.radioOutline {
  @include radio(#c2c2c2);
  &:active {
    border-color: #508ba1;
  }
}
.radioFilled {
  @include radio(#9360a8);
  position: relative;
  &::before {
    content: "";
    width: 60%;
    height: 60%;
    background-color: #9360a8;
    position: absolute;
    border-radius: 50%;
    left: 20%;
    top: 20%;
  }
  &:active {
    background-color: white;
    border-color: #508ba1;
    &:active::before {
      background-color: #508ba1;
    }
  }
}
