@import "../../../theme/variables.scss";
.container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: flex-start;

  .labelText {
    color: #616161;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
  }
  .required {
    color: $DANGER_MAIN;
  }
  .inputText {
    padding: 0 12px;
    font-size: 1rem;
    display: flex;
    width: 100%;
    height: 44px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    transition: 0.3s;

    &::placeholder {
      color: #c2c2c2;
      font-size: 1rem;
      line-height: 20px;
    }
    &:focus {
      outline: 1px solid $NEW-PRIMARY2-BASE;
      border-color: $NEW-PRIMARY2-BASE;
    }
    &:hover {
      border-color: $NEW-PRIMARY2-BASE;
    }
  }
  .inputText[is-error="true"] {
    border-color: $DANGER_MAIN;
  }
  .inputText[is-error="false"] {
    border-color: #e0e0e0;
  }
  .wrapperTypeMoney {
    width: 100%;
    height: 44px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    display: flex;
    align-items: center;
  }
  .wrapperTypeMoney[isFocus="true"] {
    outline: 2px solid $NEW-PRIMARY2-BASE;
  }
  .inputTextMoney {
    padding: 0 12px;
    font-size: 1rem;
    display: flex;
    width: 100%;
    height: 40px;
    border-color: transparent;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    &::placeholder {
      color: #c2c2c2;
      font-size: 1rem;
      line-height: 20px;
    }
    &:focus {
      outline: none;
    }
  }
  .idrCurrency {
    display: flex;
    align-items: center;
    width: 45px;
    height: 100%;
    background-color: #e0e0e0;
    color: #757575;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    justify-content: center;
  }
}
