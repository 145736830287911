.container[isLockedClass="true"] {
  width: auto;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    border-radius: 12px;
  }
  .lockedClass {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    color: white;
    width: 469px;
    height: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    .iconLockedClass {
      width: 60px;
      height: 60px;
      background-color: #686868;
      border-radius: 50%;
      position: relative;
      &::before {
        content: "";
        width: 80%;
        height: 80%;
        border-radius: inherit;
        background-color: #959595;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .description {
        font-size: 14px;
        line-height: 24px;
      }
    }
    .button {
      margin-top: 20px;
      display: flex;
      gap: 6px;
      align-items: center;
      cursor: pointer;
      span {
        font-size: 1rem;
        font-weight: 600;
        background: linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
.container {
  width: auto;
  height: 532px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  .playVideo {
    cursor: pointer;
    border-radius: 12px;
    width: 62px;
    height: 62px;
  }
}
@media (max-width: 600px) {
  .container[isLockedClass="true"] {
    &::before {
      height: 201px !important;
    }
    .lockedClass {
      padding: 24px 14px;
      .description {
        font-size: 12px !important;
      }
      .button {
        order: 3;
        span {
          font-size: 14px !important;
        }
      }
      .iconLockedClass {
        order: 2;
      }
    }
  }
  .container {
    height: 201px !important;
    width: auto;
    background-size: cover;
    .playVideo {
      width: 43px;
      height: 43px;
    }
  }
}
