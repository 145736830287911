@import "../../../../theme/variables.scss";
.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .doodleCard {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    h2 {
      color: $NEUTRAL-10;
      font-size: 24px;
      font-weight: 400;
      line-height: 30px;
      .span {
        font-weight: 600;
      }
    }
    p {
      color: $NEUTRAL-10;
      font-size: 14px;
      line-height: 20px;
      .span {
        font-style: italic;
      }
    }
  }
  .controlSection {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    overflow: auto;
    .tabWrapper {
      display: flex;
      gap: 12px;
    }
    .dateWrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      > p {
        color: $NEUTRAL-100;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px; /* 125% */
      }
    }
  }
  .bodySection {
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 2px 24px -2px rgba(24, 39, 75, 0.12),
      -3px 4px 4px -2px rgba(24, 39, 75, 0.13);
    .buttonWrapper {
      padding: 16px;
      display: flex;
      gap: 12px;
      > :last-child {
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }
    }
    .userProfile {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .labelWrapper {
      > :first-child {
        color: var(--Neutral-100, #232323);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
      > :last-child {
        color: var(--Neutral-80, #718198);
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 140% */
      }
    }
    .statusLabel {
      padding: 2px 8px;
      border-radius: 17px;
      > span {
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
      }
    }
    .statusLabel[status="false"] {
      > span {
        background: #ffcece;
        color: var(--Danger-Danger-60, #b22a12);
      }
    }
    .statusLabel[status="true"] {
      > span {
        background: var(--Success-Success-20, #ddf9a4);

        color: var(--Success-Success-70, #498e0f);
      }
    }
  }
}
