@import "../../../theme/variables.scss";

.container {
  border-radius: 8px;
  background: #fff;
  display: inline-flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  .modalHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    > :first-child {
      color: var(--Neutral-100, #232323);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }
    >:last-child{
      cursor: pointer;
    }
  }
  .modalBody {
    width: 531px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    > :first-child {
      color: var(--Neutral-100, #232323);
      font-size: 16px;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
    .inputArea {
      display: flex;
      width: 100%;
      height: 194px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      border-radius: 10px;
      border: 1px dashed var(--Neutral-50, #c2c2c2);
      .textInput {
        color: var(--New-Primary-2-Primary-Base, #9360a8);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
    .fileWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px;
      border-radius: 12px;
border: 1px solid var(--Neutral-40, #E0E0E0);
      .leftSection {
        display: flex;
        align-items: center;
        gap: 16px;
        > img {
          width: 54px;
          height: 54px;
        }

      }
      .rightSection{
        display: flex;
        gap: 24px;
        >svg {
          cursor: pointer;
        }
      }
    }
  }
  .modalAction {
    width: 100%;

    > button {
      width: 100%;
    }
  }
}
