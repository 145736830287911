@import "../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 78px);

  .loadingContainer {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    > :first-child {
      width: 307px;
    }
  }

  .wrapper {
    @include padding-block(24, 12, 12px);
    @include padding-inline(24, 16, 16px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .header {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;

      .title {
        flex-grow: 1;
        color: $NEUTRAL-10;
        display: flex;
        flex-direction: column;
        gap: 8px;

        h3 {
          font-family: $BASE-FONT-FAMILY;
          @include font-size(24, 16, 16px);
          font-style: normal;
          font-weight: 400;
          line-height: 125%;

          > span {
            font-weight: 600;
          }
        }

        > span {
          font-family: $BASE-FONT-FAMILY;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 142.857%;
        }
      }

      > button {
        background-color: $NEUTRAL-10;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        padding: 8px 16px;
        color: $NEW-PRIMARY2-BASE;
        border-radius: 8px;

        > span {
          font-family: $BASE-FONT-FAMILY;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 125%;
        }
      }
    }
    .emptyHandleContainer {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 12px;
      > p {
        color: var(--Neutral-50, #c2c2c2);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
      > img {
        width: 307px;
        height: 233px;
      }
    }
    .cardContainer {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      // display: flex;
      // grid-template-columns: repeat(auto-fit, minmax(221px, 1fr));
      gap: 20px;
      .certficateCard {
        border-radius: 12px;
        background: #fdfdfd;
        box-shadow: 0px 2px 24px -2px rgba(24, 39, 75, 0.12),
          -3px 4px 4px -2px rgba(24, 39, 75, 0.13);
        display: flex;
        padding: 8px;
        flex-direction: column;
        gap: 10px;
        .cardHead {
          display: flex;
          justify-content: space-between;
          align-items: center;
          > :first-child {
            color: #000;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
          }
          > :last-child {
            display: flex;
            gap: 6px;
            & > * {
              cursor: pointer;
            }
          }
        }
        > :last-child {
          height: 100%;
          width: 100%;
          > img {
            border-radius: 6px;
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
  .modalContainer {
    border-radius: 12px;
    background: #fff;
    display: flex;
    width: 479px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    .modalHeader {
      color: var(--Neutral-90, #404040);
      font-size: 16px;
      font-weight: 600;
      line-height: 20px; /* 125% */
    }
    .modalBody {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      .imagePreview {
        width: 100%;
        height: 100%;
        .imageWrapper {
          width: 100%;
          height: 100%;
          position: relative;
          > img {
            width: 100%;
            height: 100%;
          }
        }
        > :first-child {
          color: var(--Neutral-80, #616161);
          font-size: 14px;
          font-weight: 500;
          line-height: 16px; /* 114.286% */
          margin-bottom: 8px;
          > span {
            color: #d1421a;
          }
        }
      }
      .imageOverlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        opacity: 0;
        transition: 0.3s;
        display: flex;
        gap: 12px;
        justify-content: center;
        align-items: center;
        background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
        > * {
          cursor: pointer;
        }
      }
      .imageOverlay[is-hovered="true"] {
        opacity: 1;
      }
    }
  }
  .modalAction {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 12px;
    > button {
      flex: 1;
    }
  }
  .modalConfirm {
    display: flex;
    width: 497px;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    background: #fff;
    .headText {
      color: var(--Neutral-90, #404040);
      font-size: 20px;
      font-weight: 600;
      line-height: 26px; /* 130% */
    }
    .descText {
      color: var(--light-paragraph, #333);
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
    > img {
      width: 150px;
      height: 150px;
    }
  }
  .errorModalContainer {
    color: #333;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    > ul {
      margin-left: 21px;
    }
  }
}

@media screen and (max-width: 1080px) {
  .container {
    .cardContainer {
      grid-template-columns: 1fr 1fr 1fr !important;
    }
  }
}
@media screen and (max-width: 768px) {
  .container {
    .cardContainer {
      grid-template-columns: 1fr !important;
    }
    .wrapper {
      .header {
        flex-direction: column;

        > button {
          width: 100%;
        }
      }
    }
  }
}
