.container {
  display: flex;
  max-width: 497px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  background: #fff;
  .modalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    > img {
      width: 153px;
      height: 131px;
    }
    > :first-child {
      text-align: center;
      color: var(--Neutral-90, #404040);
      font-size: 20px;
      font-weight: 600;
      line-height: 26px; /* 130% */
    }
    > :last-child {
      color: var(--light-paragraph, #333);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
  .modalAction {
    width: 100%;
    > button {
      width: 100%;
    }
  }
}
