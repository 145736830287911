@import "../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .imageWrapper {
    height: 250px;
    width: 100%;
    position: relative;
    > img {
      border-radius: 8px 8px 0px 0px;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .draftOverlay {
      position: absolute;
      top: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(233, 233, 233, 0.5);
      .overlay {
        display: flex;
        width: 60px;
        height: 60px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 50%;
        background: var(--Neutral-10, #fff);
        > img {
          width: 24px;
          height: 24px;
          flex-shrink: 0;
        }
        > p {
          background: var(
            --New-Primary-Gradient-Color-Gradient-2,
            linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%)
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 14px;
          font-weight: 500;
          line-height: 16px; /* 114.286% */
        }
      }
    }
  }
  .bodyWrapper {
    border-radius: 0px 0px 8px 8px;
    border: 1px solid $NEUTRAL-30;
    background: $NEUTRAL-10;
    border-top: none;
    padding: 8px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    .cardBody {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .cardTag {
        display: flex;
        gap: 4px;
        align-items: center;
        .prakerjaLogo {
          width: 44px;
          height: 14px;
          margin-left: auto;
        }
        .tag {
          padding: 4px 8px;
          border-radius: 4px;
          > p {
            font-size: 10px;
            font-weight: 400;
            line-height: 12px; /* 120% */
          }
        }
        .tag[type="level"] {
          background-color: $NEW-PRIMARY1-BASE;
          color: $NEUTRAL-10;
        }
        .tag[type="type"] {
          color: $NEUTRAL-100;
          background-color: #c1ed74;
        }
      }
      .cardTitle {
        > p {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          overflow: hidden;
          color: #000;
          line-clamp: 2;
          font-size: 14px;
          font-weight: 500;
          line-height: 114.286%;
          text-wrap: pretty;
        }
      }
      .cardFooter {
        display: flex;
        gap: 8px;
        align-items: center;
        .cardDesc {
          display: flex;
          align-items: center;
          gap: 2px;
          > p {
            color: $NEUTRAL-70;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
          }
        }
      }
    }
    .cardAction {
      margin-top: 16px;
      .cardButton {
        width: 100%;
      }
    }
  }
}
