@import "../../../theme/variables.scss";

.container {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  .headSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    width: 100%;
    cursor: pointer;
    >:last-child{
        transition: 0.2s ;
    }
  }
  .headSection[is-collapse="true"] {
    >:last-child{
        transform: rotate(-180deg);
    }
  }
  .bodySection{
    transition: 0.5s max-height;
    overflow: auto;
    width: 100%;
  }
  .bodySection[is-collapse="false"]{
    max-height: 0px;
    overflow: hidden;
  }
  .bodySection[is-collapse="true"]{
    max-height: 500px;
  }
}
