@import "../../../theme/variables.scss";

.container {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: $NEW-PRIMARY2-10;
  padding: 10px;
  border-radius: 4px;

  >span {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
  }

  >button {
    display: grid;
    place-items: center;
  }
}