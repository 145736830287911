@import "../../../../theme/variables.scss";

.container {
  padding: 32px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    h3 {
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
    }

    span {
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 114.286%;
    }
  }

  .divider {
    height: 1px;
    background-color: $NEUTRAL-40;
  }
  .content{
    >p{
        white-space: pre-line;
    }
  }
}
