@import "../../../theme/variables.scss";
.voucher {
  display: flex;
  flex-direction: column;
  max-width: calc(386px + 6%);
  row-gap: 12px;
  padding-inline: 3%;
  z-index: 1 !important;
  position: relative;
  h1 {
    color: $NEUTRAL-10;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  p {
    color: $NEUTRAL-10;
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
  }
  .enterVoucherPrakerja {
    display: flex;
    flex-direction: column;
    height: 46px;
    input {
      padding: 10px 11px;
      background-color: transparent;
      width: 272px;
      border-radius: 8px;
      border: 1px solid $NEUTRAL-10;
      background: rgba(162, 166, 180, 0.25);
      box-shadow: 0px 6px 24px 4px rgba(162, 166, 180, 0.184);
      backdrop-filter: blur(5px);
      color: $NEUTRAL-10;
      font-size: 1rem;
      backdrop-filter: blur(5.199999809265137px);
      &::placeholder {
        font-size: 1rem;
        line-height: 20px;
        color: $NEUTRAL-10;
      }
      &:focus {
        border-color: $NEUTRAL-10;
        outline: none;
      }
    }
    input[is-error="true"] {
      border-color: $DANGER-50;
      color: $DANGER-50;
    }
    .buttonVoucherPrakerja {
      display: flex;
      max-width: 99px;
      width: 100%;
      height: 44px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      flex-shrink: 0;
      border-radius: 8px;
      background: $NEUTRAL-10;
      color: $NEW-PRIMARY2-BASE;
      font-weight: 600;
      font-size: 1rem;
      box-shadow: 0 6px 2px #2b1c31;
      transition: 0.3s;
      &:active {
        transform: translateY(2px);
        box-shadow: none;
      }
    }
    .inputAndButton {
      display: flex;
      gap: 15px;
    }
  }
  .modalSuccessContainer {
    border-radius: 12px;
    background: $NEUTRAL-10;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 312px;
    text-align: center;
    > img {
      width: 148px;
      height: 148px;
    }
    .modalTitle {
      color: $NEUTRAL-90;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }
    .modalDesc {
      color: $NEUTRAL-60;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
  }
  .errorMessage {
    color: $NEUTRAL-10;
    font-size: 14px;
    text-align: left;
  }
}
@media (max-width: 768px) {
  .voucher {
    p {
      line-height: 20px;
    }
    input {
      width: 100% !important;
      &::placeholder {
        font-size: calc(14px - 2px) !important;
      }
    }
    flex: 1;
    // .enterVoucherPrakerja {
    //   width: calc(100% - 30px);
    // }
  }
}

