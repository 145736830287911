@import "../../../theme/variables.scss";

.container {
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.25);
  padding: 12px;
  border-radius: 8px;
  width: 230px;
  display: flex;
  flex-direction: column;

  >* {
    padding-block: 16px;

    &:not(:last-child) {
      border-bottom: 1px solid $NEUTRAL-40;
    }

    &.top {
      display: flex;
      align-items: center;
      gap: 8px;
      padding-top: 0;

      >span {
        font-family: $BASE-FONT-FAMILY;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 114.286%;
        color: $NEUTRAL-100;
      }
    }

    &.middle,
    &.bottom {
      display: flex;
      flex-direction: column;
      gap: 16px;

      >* {
        padding: 0;
        align-self: flex-start;
      }

    }
  }
}