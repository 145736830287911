@import "../../../theme/variables.scss";

.button {
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 8px;
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  // transition: all 0.3s ease;

  >span {
    white-space: nowrap;
  }
}

.button[iconbutton="true"] {
  padding: 13px;
}

.button[variant="contained"] {
  background: linear-gradient(320deg, #46b7e9 7.68%, transparent 70.42%) #9360a8;
  color: white;
  border: none;
  padding: 10px 18px;

  // border-top: 2px solid transparent;
  // border-bottom: 2px solid transparent;

  //   transition: background-color 1s;
  &:hover {
    background: $NEW-PRIMARY2-BASE;
  }

  &:active {
    background: $NEW-PRIMARY2-70;
  }

  &:disabled {
    background: $NEUTRAL-40;
    color: $NEUTRAL-60;
  }
}

.button[variant="outlined"] {
  background: linear-gradient(white, white) padding-box, $GRADIENT border-box;
  transition: background-color 1s;
  border-radius: 8px;
  border: 2px solid transparent;

  &>span {
    background: $GRADIENT;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &>.icon {
    color: $NEW-PRIMARY2-60;
  }

  &:hover {
    background: linear-gradient(white, white) padding-box,
      $NEW-PRIMARY2-BASE border-box;

    &>* {
      background: $NEW-PRIMARY2-BASE;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    //   background-position: 100px;
    //   animation-name: grandient;
    //   animation-duration: 2s;
  }

  &:active {
    background: linear-gradient(white, white) padding-box,
      $NEW-PRIMARY2-70 border-box;
  }

  &:disabled {
    background: linear-gradient(white, white) padding-box,
      $NEUTRAL-50 border-box;

    &>span {
      background: $NEUTRAL-50;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &>.icon {
      color: $NEUTRAL-50;
    }
  }
}

.button[variant="outlined-danger"] {
  background: linear-gradient(white, white) padding-box, $GRADIENT border-box;
  transition: background-color 1s;
  border-radius: 8px;
  border: 2px solid $DANGER-60;

  &>span {
    background: $DANGER-60;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &>.icon {
    color: $DANGER-60;
  }

  &:hover {
    background: linear-gradient(white, white) padding-box,
      $DANGER-50 border-box;

    &>* {
      background: $DANGER-60;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    //   background-position: 100px;
    //   animation-name: grandient;
    //   animation-duration: 2s;
  }

  &:active {
    background: linear-gradient(white, white) padding-box,
      $NEW-PRIMARY2-70 border-box;
  }

  &:disabled {
    background: linear-gradient(white, white) padding-box,
      $NEUTRAL-50 border-box;

    &>span {
      background: $NEUTRAL-50;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &>.icon {
      color: $NEUTRAL-50;
    }
  }
}

.button[variant="text"] {
  transition: background-color 1s;
  border-radius: 8px;
  border: 2px solid transparent;
  background: transparent;

  &>* {
    background: $GRADIENT;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &>.icon {
    color: $NEW-PRIMARY2-60;
  }

  &:hover {
    &>* {
      background: $NEW-PRIMARY2-BASE;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &:disabled {
    &>.icon {
      color: $NEUTRAL-60;
    }

    &>* {
      background: $NEUTRAL-60;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.button[variant="neutral-contained"] {
  background: $NEUTRAL-10;
  color: $NEW-PRIMARY2-BASE;
  border: none;

  //   transition: background-color 1s;
  &:hover {
    background: $NEUTRAL-30;
  }

  &:disabled {
    background: $NEUTRAL-50;
  }
}

.button[variant="neutral-outlined"] {
  border: 2px solid $NEUTRAL-10;
  color: $NEUTRAL-10;
  background-color: transparent;

  &:hover {
    border: 2px solid $NEUTRAL-30;
  }

  &:disabled {
    border: 2px solid $NEUTRAL-50;
    color: $NEUTRAL-50;

  }
}
.button[variant="neutral-outlined-2"] {
  border: 1px solid $NEUTRAL-40;
  color: $NEUTRAL-60;
  background-color: transparent;
}

.button[variant="neutral-text"] {
  border: none;
  color: $NEUTRAL-10;
  background-color: transparent;

  &:hover {
    color: $NEUTRAL-30;
  }

  &:disabled {
    color: $NEUTRAL-50;

  }
}

.button[variant="top-nav"] {
  transition: background-color 1s;
  border-radius: 8px;
  border: 2px solid transparent;
  background: transparent;

  &>* {
    color: $NEUTRAL-60
  }

  &>.icon {
    color: $NEW-PRIMARY2-60;
  }

  &:hover {
    &>* {
      background: $GRADIENT;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &:disabled {
    &>.icon {
      color: $NEUTRAL-60;
    }

    &>* {
      background: $NEUTRAL-60;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.button[variant="tab"] {
  transition: background-color 1s;
  border-radius: 60px;
  border: 1px solid $NEUTRAL-30;
  background: transparent;
  color: $NEUTRAL-100;
  padding: 12px 24px;

  &.active {
    border: 2px solid $NEW-PRIMARY2-BASE;
    color: $NEW-PRIMARY2-BASE;
  }
}

.button[variant="table-tab"] {
  transition: background-color 1s;
  border: none;
  background: transparent;
  padding: 12px 24px;
  border-radius: 0;
  font-family: $BASE-FONT-FAMILY;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: $NEUTRAL-60;

  .label {
    background-color: $NEUTRAL-40;
    border-radius: 2px;
    font-family: $BASE-FONT-FAMILY;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 116.667%;
    color: $NEUTRAL-60;
    padding: 2px;
  }

  &.active {
    color: $NEW-PRIMARY2-BASE;
    border-bottom: 1px solid $NEUTRAL-100;

    .label {
      background-color: $NEW-PRIMARY2-BASE;
      color: $NEUTRAL-10;
    }
  }
}