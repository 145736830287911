.container {
  .bodyWrapper {
    margin: 32px 32px 8px 24px;
  }
  .buttonNext {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    
  }
}
