@import "../../../../../theme/variables.scss";

.container {
  .tableWrapper {
    margin-top: 16px;
    border-radius: 12px;
    background: #fff;

    /* 100 */
    box-shadow: 0px 2px 24px -2px rgba(24, 39, 75, 0.12),
      -3px 4px 4px -2px rgba(24, 39, 75, 0.13);
  }
  .modalContainer {
    border-radius: 12px;
    background: #fff;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 600px;
    max-height: 80vh;
    .modalHead {
      display: flex;
      justify-content: space-between;
      width: 100%;
      > p {
        color: var(--Neutral-90, #404040);
        font-size: 16px;
        font-weight: 600;
        line-height: 20px; /* 125% */
      }
    }
    .divider {
      height: 1px;
      width: 100%;
      background: #ededed;
    }
    .modalBody {
      display: flex;
      flex-direction: column;
      gap: 16px;
      overflow: auto;
      .surveyWrapper {
        display: flex;
        gap: 8px;
        > :first-child {
          color: var(--Neutral-100, #232323);
          font-size: 14px;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
        .textWrapper {
          > :first-child {
            color: var(--Neutral-100, #232323);
            font-size: 14px;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            margin-bottom: 6px;
          }
          > :last-child {
            color: var(--Neutral-80, #616161);
            font-size: 16px;
            font-weight: 400;
            line-height: 24px; /* 150% */
          }
        }
      }
    }
  }
}
