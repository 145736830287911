@import "../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 78px);

  .expandedInfo {
    display: flex;
    flex-direction: column;
    margin-inline: auto;

    .infoRow {
      display: flex;
      align-items: center;

      >* {
        padding: 4px 8px;

        &:first-child {
          width: 103px;
        }
      }


      >span {
        &:first-child {
          font-family: $BASE-FONT-FAMILY;
          color: $NEUTRAL-70;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
        }

        &:last-child {
          font-family: $BASE-FONT-FAMILY;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 133.333%;
        }
      }


    }
  }

  .wrapper {
    @include padding-block(24, 12, 12px);
    @include padding-inline(24, 16, 16px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .header {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;

      .title {
        flex-grow: 1;
        color: $NEUTRAL-10;
        display: flex;
        flex-direction: column;
        gap: 8px;

        h3 {
          font-family: $BASE-FONT-FAMILY;
          @include font-size(24, 16, 16px);
          font-style: normal;
          font-weight: 400;
          line-height: 125%;

          >span {
            font-weight: 600;
          }
        }

        >span {
          font-family: $BASE-FONT-FAMILY;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 142.857%;
        }
      }

      >button {
        background-color: $NEUTRAL-10;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        padding: 8px 16px;
        color: $NEW-PRIMARY2-BASE;
        border-radius: 8px;

        >span {
          font-family: $BASE-FONT-FAMILY;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 125%;
        }
      }
    }

    .table {
      border-radius: 12px;
      border: 1px solid $NEUTRAL-40;

      .instructor {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }


}

@media screen and (max-width: 768px) {

  .container {
    .wrapper {

      .header {
        flex-direction: column;

        >button {
          width: 100%;
        }
  
      }
    }
  }
}