@import "../../../theme/variables.scss";
.wrapperConditionPrakerja {
  padding-block: 147px;
  padding-inline: 111px 157px;
  .wrapperText {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    h1 {
      color: $NEUTRAL-100;
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;

      span {
        color: $NEW-PRIMARY2-BASE;
      }
    }
    p {
      color: $NEUTRAL-100;
      font-size: 16px;
      line-height: 24px;
      padding: 0 1rem;
    }
  }
}
@media (max-width: 768px) {
  .wrapperConditionPrakerja {
    padding: 1rem;
    padding-top: 70px;
    .wrapperText {
      row-gap: 1rem;
      h1 {
        font-size: 1rem !important;
        line-height: 1.5rem;
        text-align: center;
      }
      p {
        font-size: 14px;
        line-height: 1.4rem;
      }
    }
  }
}
