@import "../../../../../theme/variables.scss";

.container {
  .table {
    border-radius: 8px;
  }
  .tableWrapper {
    margin-top: 16px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 2px 24px -2px rgba(24, 39, 75, 0.12),
      -3px 4px 4px -2px rgba(24, 39, 75, 0.13);
    .nameAndEmail {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 12px;
      line-height: 16px;
      font-family: $BASE-FONT-FAMILY;
      cursor: pointer;
      > span:nth-child(1) {
        color: $NEUTRAL-100;
      }
      > span {
        color: $NEUTRAL-60;
      }
    }
    .withBadge,
    .infoRowValue {
      background-color: $NEUTRAL-60;
      padding: 2px 8px;
      border-radius: 20px;
      color: $NEUTRAL-10;
      text-align: center;
      font-weight: 500;
      font-size: 12px;
      width: 48px;
      height: 24px;
    }
    .withBadge[is-done="true"],
    .infoRowValue[is-done="true"] {
      background-color: $SUCCESS-60;
    }
    .withBadge[is-done="false"],
    .infoRowValue[is-done="false"] {
      background-color: #d42801;
    }
    .expandedInfo {
      display: flex;
      flex-direction: column;
      margin-inline: auto;
      row-gap: 8px;

      .infoRow {
        display: flex;
        align-items: center;

        > * {
          padding: 4px 8px;

          &:first-child {
            width: 103px;
          }
        }

        > span {
          &:first-child {
            font-family: $BASE-FONT-FAMILY;
            color: $NEUTRAL-70;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
          }

          &:last-child {
            font-family: $BASE-FONT-FAMILY;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 133.333%;
          }
        }
      }
    }
  }
}
