@import "../../../../theme/variables.scss";

.container {
  padding: 22px 24px;
  .headText {
    display: flex;
    margin-top: 16px;
    margin-bottom: 24px;
    > :first-child {
      color: #000;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px; /* 130% */
    }
    .bulletPoint {
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      > p {
        color: #000;
        font-size: 22px;
        font-weight: 600;
        line-height: 26px; /* 130% */
      }
    }
    > :last-child {
      color: $NEUTRAL-60;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px; /* 130% */
    }
  }
  .searchSection {
    margin-bottom: 16px;
  }
  .tableContainer {
    border-radius: 12px;
    box-shadow: 0px 2px 24px -2px rgba(24, 39, 75, 0.12),
      -3px 4px 4px -2px rgba(24, 39, 75, 0.13);
    .tableHead {
      display: flex;
      padding: 12px 16px;
      align-items: center;
      gap: 4px;
      background: $NEW_PRIMARY2_BASE;
      border-radius: 12px 12px 0 0;
      color: $NEUTRAL-10;
    }
    .tableBody {
      border-radius: 0px 0px 12px 12px;
      border: 1px solid $NEUTRAL-20;
      display: flex;
      flex-direction: column;
      .itemWrapper {
        border-bottom: 1px solid $NEUTRAL-20;
        .item {
          display: flex;
          padding: 12px 16px;
          justify-content: space-between;
          align-items: center;
          > :first-child {
            color: $NEUTRAL-100;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }
        }
      }
      .tableStatus {
        border-radius: 8px;
        border: 1px solid #498e0f;
        padding: 8px 16px;
        > p {
          color: var(--Success-Success-70, #498e0f);
          font-size: 16px;
          font-weight: 600;
          line-height: 20px; /* 125% */
        }
      }
      .tableButton {
        border-radius: 8px;
        border: 1px solid var(--Success-Success-70, #498e0f);
        display: flex;
        height: 42px;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        background: var(--Success-Success-70, #498e0f);
        cursor: pointer;
        > p {
          color: var(--Neutral-10, #fff);
          font-size: 16px;
          font-weight: 600;
          line-height: 20px; /* 125% */
        }
      }
      > :last-child {
        border-bottom: 0px;
      }
    }
  }
}
