#prakerja {
  scroll-margin: 70px;
}
.wrapperImage {
  padding-top: 220px;
  padding-left: calc(122px - 40px);
  display: flex;
  gap: 54px;
  width: 100%;
  height: 100%;
  max-width: calc(1011px + 80px);
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  padding-bottom: 230px;

  .imageGallery {
    width: auto;
    height: 100%;
    img {
      object-fit: cover;
      max-width: 375px;
      max-height: 250px;
      width: auto;
    }
  }
  .description {
    color: white;
    scroll-margin: 100px;

    h1 {
      font-size: 40px;
      font-weight: 600;
      line-height: 50px;
    }
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    p {
      color: var(--Neutral-10, #fff);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    .buttonGallery {
      display: flex;
      gap: 6px;
      align-items: center;
      outline: 2px solid white;
      border-radius: 8px;
      width: 215px;
      padding: 8px 16px;
      margin-top: 1rem;
      height: 42px;
      box-sizing: border-box;
      cursor: pointer;
      color: white;
      transition: 0.4s;
      &:hover {
        color: #9360a8;
        outline-color: #9360a8;
      }
      &:active {
        color: #48175c;
        outline-color: #48175c;
      }
      div {
        font-size: 1rem;
        font-weight: 600;
        line-height: 20px;
      }
    }
    .linkTo {
      width: max-content;
      width: max-content;
    }
  }
}

@media (max-width: 900px) {
  .wrapperImage {
    flex-direction: column;
    padding-inline: 5% !important;
    gap: 0;
    padding-top: 120px !important;
    margin-left: 0 !important;
    .imageGallery {
      display: flex;
      row-gap: 0;
      img {
        padding-inline: 1.4rem;
        max-width: max-content !important;
        width: 100% !important;
        height: 100%;
      }
    }
    .description {
      row-gap: 0;
      text-align: center;
      align-items: center;
      scroll-margin: 200px;

      h1 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}
