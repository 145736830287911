.container {
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  z-index: -2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: 48px;
  gap: 100px;
  .logo {
    display: flex;
    align-items: center;
    gap: 12px;
    > img {
      width: 34px;
      height: 42px;
    }
    & > :last-child {
      color: #26255b;
      font-size: 20px;
      font-weight: 400;
      line-height: normal;
    }
  }
}


@media screen and (max-width: 480px) {
    .container {
      gap: 24px !important;
      padding: 48px 16px 24px 16px;
  
      .filePreviewSection {
        height: 224px !important;
      }
    }
  }
