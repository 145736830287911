@import "../../../theme/variables.scss";

.container {
  display: flex;
  align-items: center;
  gap: 32px;

  .option {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    .radio {
      width: 20px;
      aspect-ratio: 1;
      display: grid;
      place-items: center;
      border-radius: 50%;
      border: 1px solid $NEUTRAL-40;
      color: $NEUTRAL-10;

      &.positive {
        background-color: $NEW-PRIMARY2-BASE;
      }

      &.negative {
        background-color: #B22A12;
      }
    }

    >span {
      font-family: $BASE-FONT-FAMILY;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
    }
  }
}