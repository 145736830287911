.container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 16px;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  max-width: 414px;
  .informationWrapper {
    > :first-child {
      display: flex;
      gap: 8px;
      > img {
        width: 16px;
        height: 16px;
      }
      .infoText {
        color: var(--light-paragraph, #333);
        font-size: 12px;
        font-weight: 400;
        line-height: 14px; /* 116.667% */
      }
      .prakerjaLink {
        margin-top: 8px;
        cursor: pointer;
        transition: 0.5s ease-in;
        color: #9360a8;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        text-decoration-line: underline;
        display: block;
      }
      .prakerjaLink[hide="true"] {
        opacity: 0;
        margin-top: -16px;
      }
    }
  }
  .isError {
    color: var(--Danger-Danger-60, #b22a12);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    align-self: stretch;
    margin-top: 4px;
  }
  .headerCard {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    > h1 {
      color: var(--Neutral-90, #404040);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    > p {
      color: var(--light-paragraph, #333);
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
  }
  .mainCard {
    width: 100%;
  }
  .buttonCard {
    height: 36px;
    display: flex;
    width: 100%;
    gap: 12px;
    justify-content: space-between;
    & > * {
      flex: 1;
    }
    .buttonBack {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #795ec6, #8cc5de) border-box;
      border: 2px solid transparent;
      cursor: pointer;
      span {
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 125% */
        background: var(
          --New-Primary-Gradient-Color-Gradient-2,
          linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
@media (max-width: 460px) {
  .container {
    width: 320px;
  }
}
