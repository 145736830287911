@import "../../../../theme/variables.scss";

.container {
  padding: 32px 24px 24px 24px;
  .title {
    color: #000;
    font-size: 32px;
    font-weight: 400;
    line-height: 120%; /* 38.4px */
  }
  .contentHeader {
    margin-top: 16px;
    padding: 40px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    border-radius: 12px;
    border: 1px solid $NEUTRAL-40;
    background: linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%);
    .contentTitle {
      color: $NEUTRAL-10;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px; /* 100% */
    }
    .contentDesc {
      color: $NEUTRAL-10;
      font-size: 16px;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
    }
  }
}
