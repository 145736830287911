@import "../../../theme/variables.scss";

.card {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .body {
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    gap: 16px;
    height: 100%;
    .leftSection {
      display: flex;
      flex-direction: column;
      height: 100%;
      .imageWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex: 1;
        > img {
          max-height: 315px;
          max-width: 300px;
          flex-shrink: 0;
        }
      }
    }
    .rightSection {
      // .avatarWrapper {
      //   width: 280px;
      //   height: 377px;
      // }
      .colorPickerContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
        gap: 10px;
        .colorPicker {
          cursor: pointer;
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
        .colorPickWrapper[is-selected="true"] {
          border: 3px solid #9360a8;
          border-radius: 50%;
          padding: 1px;
        }
      }
    }
    .divider {
      width: 1px;
      background: #ededed;
      height: auto;
    }
  }

  .sectionTitle {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 16px;
    > :first-child {
      color: #000;
      font-size: 14px;
      font-style: italic;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
    > :last-child {
      color: $NEUTRAL-70;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
    }
  }

  .imgBox {
    position: relative;
    border-radius: 8px;
    width: 280px;
    height: 377px;
    overflow: clip;

    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
    }
  }

  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    padding-top: 16px;
  }
}
